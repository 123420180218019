/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ASYNC_STATE, PLAN_CODE, PLAN_STATE } from '~/constants'
import { useAppState } from '~/context'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'

import { DefaultLayout, DefaultTitle } from '../Layout/DefaultLayout'
import { Details, UpgradeCTA } from './modules'
import { CanNotUpgradeAutomatically } from './modules/CanNotUpgradeAutomatically'
import { ReactivateYourSubscription } from './modules/ReactivateYourSubscription'

function Plans() {
  const {
    currentSubscription: { plan, status, reload },
  } = useAppState()
  const location = useLocation()
  const freePlanData = useLoadPlanByCode(PLAN_CODE.FREE)
  const planCodeUpgrade = plan?.plan?.can_upgrade_to_plans?.[0]
  const canUpgrade = plan?.can_upgrade
  const isCanceled = plan.status === PLAN_STATE.CANCELED
  const mustSelectResources = freePlanData.data && freePlanData.data?.must_select_resources

  const loading =
    status === ASYNC_STATE.PENDING ||
    status === ASYNC_STATE.IDLE ||
    freePlanData.status === ASYNC_STATE.PENDING ||
    freePlanData.status === ASYNC_STATE.IDLE

  useEffect(() => {
    if (location?.state?.reloadPlan) typeof reload === 'function' && reload()
  }, [location, reload])

  return (
    <DefaultLayout>
      <DefaultTitle>Planos</DefaultTitle>
      <Grid container justifyContent="flex-start">
        <Grid item md={4}>
          {plan && (
            <Details
              loading={loading}
              subscription={plan}
              mustSelectResources={mustSelectResources}
            />
          )}
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={4}>
          {!loading && canUpgrade && !isCanceled && plan && planCodeUpgrade && (
            <UpgradeCTA planCode={planCodeUpgrade} />
          )}
          {!loading && canUpgrade && isCanceled && <ReactivateYourSubscription />}
          {!loading && !canUpgrade && <CanNotUpgradeAutomatically />}
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}

export default Plans
