import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ASYNC_STATE, PLAN_CODE } from '~/constants'
import { useAppState } from '~/context'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'
import * as localStorage from '~/utils/localStorage'

import DialogContent from '../../components/DialogContent'
import { closeUpgradeModal, useUpgradeModal } from '../../context'
import { useStyles } from '../../styles'

function useIsOpen() {
  const [open, setOpen] = React.useState(false)

  function toggle() {
    setOpen(!open)
  }

  return { open, toggle }
}

function UpgradeModal() {
  const [{ open }, dispatch] = useUpgradeModal()
  const history = useHistory()
  const classes = useStyles()
  const { currentSubscription } = useAppState()
  const {
    plan: { plan },
  } = currentSubscription
  const nextPlan = plan?.can_upgrade_to_plans?.[0]
  const { data, error, status } = useLoadPlanByCode(nextPlan)
  const { name, features, code, default_offer_index, offers } = data || {}
  const { offer_code } = default_offer_index !== undefined && offers?.[default_offer_index]

  const redirectToUpgradePage = () => {
    const token = localStorage.get('@makeFunnels/token')

    if (plan.code === PLAN_CODE.FREE) {
      return (window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}${offer_code}?token=${token}`)
    }
    history.push(`/upgrade/${code}`)
    closeUpgradeModal(dispatch)
  }

  if (error || status === ASYNC_STATE.REJECTED) return null

  if (status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE) return null

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        className={classes.dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        onClose={() => closeUpgradeModal(dispatch)}
      >
        <DialogContent features={features} name={name} handleClick={redirectToUpgradePage} />
      </Dialog>
    </>
  )
}

UpgradeModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
}

// function UpgradeModalWithContext() {
//   return (
//     <UpgradeModalProvider>
//       <UpgradeModal />
//     </UpgradeModalProvider>
//   )
// }

export { UpgradeModal }
