import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import * as React from 'react'
import InputMask from 'react-input-mask'

import CreditCardIcon from '../../../assets/card.svg'
import { InputBase } from '../InputBase'

type InputCardProps = {
  name: string
  placeholder?: string
  handleChange: (props: any) => void
  type?: string
  mask: string
  placeholderMask?: string
  value: string
  required?: boolean
  fullWidth?: boolean
}

export default function InputCard({
  name,
  placeholder,
  handleChange,
  type,
  mask,
  placeholderMask,
  value,
  ...rest
}: InputCardProps) {
  return (
    <Box width={'100%'}>
      <InputMask
        mask={mask}
        onChange={handleChange}
        maskPlaceholder={placeholderMask ? placeholderMask : null}
        value={value}
      >
        <InputBase
          {...rest}
          name={name}
          placeholder={placeholder}
          type={type}
          disableUnderline
          required
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <img src={CreditCardIcon} alt="credit-card" />
            </InputAdornment>
          }
        />
      </InputMask>
    </Box>
  )
}
