import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainService from '~/services/domainsService'
import LoadingPage from '~/utils/components/LoadingPage'

import View from './View'

function DomainsDetail({ state: { open, id, isCreatedDomain }, action: { handleClose } }) {
  const dispatchApp = useAppDispatch()
  const [domain, setDomain] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (open) {
      setIsFetching(true)
      getDomain()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  async function closeModal() {
    if (isCreatedDomain || domain.status === 'active') await domainService.FetchDomains(dispatchApp)
    handleClose()
  }

  async function getDomain() {
    try {
      const response = await domainService.getDomain(id)
      const {
        data: {
          data: { name, status },
        },
      } = response

      setDomain({ id, name, status })
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um problema nesta operação. Por favor tente mais tarde.',
          variant: 'error',
        },
      })
    }
    setIsFetching(false)
  }

  async function handleSubmit() {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })
    try {
      await domainService.verifyDomain(id)
      getDomain()
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: error,
          variant: 'error',
        },
      })
    }

    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  if (isFetching) return <LoadingPage />
  return <View state={{ open, domain }} actions={{ handleClose: closeModal, handleSubmit }} />
}

DomainsDetail.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    isCreatedDomain: PropTypes.bool,
    id: PropTypes.number,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

export default DomainsDetail
