import React from 'react'
import InputMask from 'react-input-mask'

import { InputBase } from '../InputBase'

type InputWithMaskProps = {
  name: string
  placeholder?: string
  handleChange: (props: any) => void
  onBlur?: () => void
  type?: string
  value: string
  mask: string
  placeholderMask?: string
  required?: boolean
  fullWidth?: boolean
}

const InputWithMask = ({
  name,
  placeholder,
  type,
  value,
  handleChange,
  placeholderMask,
  mask,
  onBlur,
  ...rest
}: InputWithMaskProps) => (
  <InputMask
    mask={mask}
    onChange={handleChange}
    onBlur={onBlur}
    maskPlaceholder={placeholderMask ? placeholderMask : null}
    value={value}
  >
    <InputBase
      {...rest}
      type={type ? type : 'text'}
      name={name}
      placeholder={placeholder}
      required
    />
  </InputMask>
)

export default InputWithMask
