import { Box, Typography } from '@material-ui/core'
import { default as DoneIcon } from '@material-ui/icons/CheckCircleOutlineRounded'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import BaseButton from '~/components/BaseButton'
import { Disclamer } from '~/components/Disclamer'
import { useModal } from '~/components/Modal'
import { ASYNC_STATE, SET_ALERT, SET_FUNNEL } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { useAlert } from '~/hooks/useAlert'
import { useLoading } from '~/hooks/useLoading'
import { FetchDomains } from '~/services/domainsService'
import { shareFunnelAsTemplate, stopShareFunnelAsTemplate } from '~/services/funnelService'
import { AppState, SharingStatus } from '~/types'
import colors from '~/utils/colors'
import { useAsync } from '~/utils/useAsync'

import ShareAsTemplateConfirmation from './ShareAsTemplateConfirmation'
import SharedLinkPath from './SharedLinkPath'

const Button = styled(BaseButton)`
  &&& {
    text-transform: capitalize !important;
    font-weight: 400;
    font-size: '13px';
    ${({ isDanger }) =>
      isDanger &&
      `color: ${colors.erro};
       border-color: ${colors.erro};

       &:hover: {
        border-color: ${colors.erro};
       }
    `}
  }
`

function ShareAsTemplate() {
  const {
    funnel: { data: funnel },
  }: AppState = useAppState()
  const appDispatch = useAppDispatch()
  const { setAlert } = useAlert()
  const { open, toggle } = useModal()
  const { isLoading } = useLoading()

  const { id: funnelId, sharing_as_template } = funnel
  const { status: sharingInitialStatus, share_uuid } = sharing_as_template ?? {}
  const sharingStatus = sharingInitialStatus || SharingStatus.STOPED
  const sharedLink = `${window.location.origin.toString()}/sharing-template/${share_uuid}` || ''
  const { data: response, run, status: asyncStatus, error } = useAsync()

  useEffect(() => {
    async function fetch() {
      await FetchDomains(appDispatch)
    }
    if (asyncStatus === ASYNC_STATE.RESOLVED) {
      fetch()
      appDispatch({
        type: SET_FUNNEL,
        payload: {
          data: {
            ...funnel,
            sharing_as_template: response?.sharing_as_template,
          },
        },
      })
      isLoading(false)
    }
    if (asyncStatus === ASYNC_STATE.REJECTED) {
      isLoading(false)
      return setAlert(error, 'error')
    }
  }, [asyncStatus])

  const handleShareAsTemplate = () => {
    run(shareFunnelAsTemplate(funnelId))
    isLoading(true)
    toggle()
  }

  const handleStopSharedTemplate = () => {
    run(stopShareFunnelAsTemplate(funnelId))
    isLoading(true)
    toggle()
  }

  function handleClickCopyButton() {
    navigator.clipboard.writeText(sharedLink)
    appDispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Link Copiado!',
        variant: 'info',
      },
    })
  }

  return (
    <>
      {sharingStatus === SharingStatus.STOPED && (
        <Box my={3}>
          <Button
            size="large"
            variant="outlined"
            handleClick={() => toggle()}
            color="primary"
            text="Gerar Link Público Compartilhável"
          />
          <Box pt={2}>
            <Disclamer
              title="Atenção"
              content="Ao gerar um link público, as páginas do funil ficarão visíveis publicamente no link gerado. Outros usuários, que tiverem acesso ao seu link, poderão importar o funil inteiro para suas contas."
            />
          </Box>
        </Box>
      )}
      {sharingStatus === SharingStatus.PROCESSING && (
        <Box display="flex" justifyContent="flex-start" alignItems="center" my={3}>
          <DoneIcon style={{ color: colors.success }} />
          <Box ml={1}>
            <Typography>
              Estamos gerando seu link de compartilhamento. Volte em alguns minutos.
            </Typography>
          </Box>
        </Box>
      )}
      {sharingStatus === SharingStatus.PUBLISHED && (
        <Box my={3}>
          <Typography>Link de compartilhamento</Typography>
          <Box display="flex" justifyContent="flex-start" alignItems="center" py={1}>
            <SharedLinkPath url={sharedLink} onClick={handleClickCopyButton} />
          </Box>
          <Button
            size="large"
            variant="outlined"
            handleClick={() => toggle()}
            color="primary"
            text="Parar Compartilhamento"
            isDanger
          />
        </Box>
      )}
      <ShareAsTemplateConfirmation
        isOpen={open}
        handleClose={toggle}
        onClickConfirmation={
          sharingStatus === SharingStatus.PUBLISHED
            ? handleStopSharedTemplate
            : handleShareAsTemplate
        }
        isSharing={sharingStatus === SharingStatus.PUBLISHED}
      />
    </>
  )
}

export default ShareAsTemplate
