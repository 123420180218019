/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { SkeletonBox, SkeletonText as SkeletonTextBase } from '~/pages/Layout/DefaultLayout'

export const SkeletonText = styled(SkeletonTextBase)`
  &&& {
    width: 12rem;
  }
`

function DisclaimerSkeleton() {
  return (
    <>
      <SkeletonText variant="h6" gutterBottom />
      <SkeletonBox />
      {/* <Box mt={2}>
        <SkeletonBox style={{ minHeight: '9rem' }} />
      </Box> */}
    </>
  )
}

export default DisclaimerSkeleton
