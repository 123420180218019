/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { SkeletonBox as SkeletonBoxBase } from '~/pages/Layout/DefaultLayout'

export const SkeletonBox = styled(SkeletonBoxBase)`
  &&& {
    margin-top: 0.5rem;
    height: 2.5rem;
    min-height: auto;
  }
`

export const Content = styled.div`
  max-height: 500px;
  overflow: hidden;
`

function MultipleSelectSkeleton() {
  const elements = []
  for (let index = 0; index < 8; index++) {
    elements.push(<SkeletonBox key={index} />)
  }

  return <Content>{elements}</Content>
}

export default MultipleSelectSkeleton
