/* eslint-disable react/prop-types */
import CheckBase from '@material-ui/icons/Check'
import React from 'react'
import styled from 'styled-components'

import { ButtonBase, DefaultBox, TextBase } from '~/pages/Layout/DefaultLayout'
import colors from '~/utils/colors'

export const TextBold = styled(TextBase)`
  &&& {
    font-weight: bold;
  }
`

export const DarkGray = styled.span`
  &&& {
    color: #52575c;
  }
`

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    /* color: #f6c209; */
    margin-right: 8px;
  }
`

export const Button = styled(ButtonBase)`
  &&& {
    background-color: ${colors.bluePrimary};
    text-transform: initial !important;
    color: white;
    & span {
      text-transform: 'initial !important';
    }
  }
`

function CanNotUpgradeAutomatically() {
  return (
    <>
      <DefaultBox>
        <TextBold variant="h4" gutterBottom>
          Precisa de mais upgrade?
        </TextBold>
        <TextBase variant="body2" gutterBottom>
          <DarkGray>
            Entre em contato com nosso time no e-mail{' '}
            <a href="mailto:suporte@makefunnels.com.br">suporte@makefunnels.com.br</a> e solicite um
            plano maior.
          </DarkGray>
        </TextBase>
      </DefaultBox>
    </>
  )
}

CanNotUpgradeAutomatically.displayName = 'CanNotUpgradeAutomatically'

export default CanNotUpgradeAutomatically
