import React from 'react'

import { getSubscriptionByCode } from '~/services/subscription'
import { useAsync } from '~/utils/useAsync'

function useLoadPlan(code) {
  const { data, status, error, run } = useAsync()

  React.useEffect(() => {
    run(getSubscriptionByCode(code))
  }, [code, run])

  return {
    data,
    status,
    error,
  }
}

export { useLoadPlan }
