import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  progress: {
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    zIndex: 9999,
    width: '100vw',
    left: 0,
    height: '100vh',
    '&.background': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
}))

function LoadingView({ loading, withBackground }) {
  const classes = useStyles()

  return (
    <>
      {loading && (
        <div className={`${classes.progress} ${withBackground && 'background'}`}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default LoadingView
