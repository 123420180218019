import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'

import { Arrow } from '@egjs/flicking-plugins'
import Flicking, { Plugin, ViewportSlot } from '@egjs/react-flicking'
import React, { useEffect, useRef, useState } from 'react'
import { Lightbox } from 'react-modal-image'

import { SharedFunnel_Page } from '../../types'
import { Panel } from '../Panel'

interface SliderPanelProps {
  pages: SharedFunnel_Page[]
}
interface ImageModalProps {
  open: boolean
  img?: string
}

export function SliderPanel({ pages }: SliderPanelProps) {
  const slideRef = useRef(null)
  const [plugins, setPlugins] = useState<Plugin[]>([])
  const [imgModal, setImgModal] = useState<ImageModalProps>({ open: false })

  useEffect(() => {
    setPlugins([new Arrow({ parentEl: slideRef.current })])
  }, [])

  const closeImgModal = () => {
    setImgModal({ open: false, img: undefined })
  }

  const openImgModal = (img: string) => {
    setImgModal({ open: true, img })
  }

  if (!pages?.length) return null

  return (
    <>
      <div id="slide" ref={slideRef}>
        <Flicking align="prev" plugins={plugins} noPanelStyleOverride bound>
          {pages.map((page) => (
            <Panel key={page.id} img={page.image} onClick={openImgModal}>
              {page.name}
            </Panel>
          ))}
          <ViewportSlot>
            <span className="flicking-arrow-prev"></span>
            <span className="flicking-arrow-next"></span>
          </ViewportSlot>
        </Flicking>
      </div>
      {imgModal.open && (
        <Lightbox
          small={imgModal.img || ''}
          large={imgModal.img}
          hideZoom
          hideDownload
          // eslint-disable-next-line
          // @ts-ignore
          onClose={closeImgModal}
        />
      )}
    </>
  )
}
