import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import duplicateIcon from '~/assets/images/duplicate_icon.svg'
import editIcon from '~/assets/images/edit_icon_blue.svg'
import trashIcon from '~/assets/images/trash_icon.svg'

import style from './style'

const useStyles = makeStyles(style)

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
}
CircularProgressWithLabel.defaultProps = {
  color: 'primary',
}

const HelpIcon = ({ title }) => (
  <Tooltip title={title}>
    <i style={{ marginLeft: '5px' }} className="fa fa-info-circle" aria-hidden="true"></i>
  </Tooltip>
)
HelpIcon.propTypes = {
  title: PropTypes.string.isRequired,
}

function RedirectionCard({ redirect, handleClickCopyButton, handleDuplicate, handleDelete }) {
  const classes = useStyles()
  let { push } = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    return setOpenModal(false)
  }

  const handleOpen = () => {
    setAnchorEl(null)
    return setOpenModal(true)
  }

  function translateStatus(status) {
    const translated = {
      published: 'Ativo',
      soldout: 'Lotado',
      expired: 'Expirado',
      empty: 'Inativo',
    }

    return translated[status]
  }

  const optionItem = (icon, label, handleClick) => (
    <Box
      onClick={handleClick}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          width: 38,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={icon} alt="menu item icon" />
      </Box>
      {label}
    </Box>
  )

  const options = [
    optionItem(editIcon, 'Editar', () => push(`/redirection/detail/${redirect.id}`)),
    optionItem(duplicateIcon, 'Duplicar', () => handleDuplicate(redirect.id)),
    optionItem(trashIcon, 'Deletar', handleOpen),
  ]

  const progress = () => {
    const percent = (redirect.metrics.visits_total * 100) / redirect.metrics.visits_limit
    return percent ? percent : 0
  }

  const isCloseToFullFill = progress() >= process.env.REACT_APP_CLOSE_TO_FULL_FILL_PERCENT
  const progressColor = isCloseToFullFill ? 'secondary' : 'primary'

  const currentLink = () => {
    if (redirect.metrics.current_link !== -1) {
      return `${redirect.metrics.current_link + 1}/${redirect.links.length}`
    }
    return `${redirect.links.length}/${redirect.links.length}`
  }

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.header}>
          {/* <Box className={classes.folder}>
            <img src={redirectIcon} alt="" />
          </Box> */}
          {/* <Box className={`${classes.redirectionName} ${classes.defaultFont}`}>
          </Box> */}
          <Box className={`${classes.status} ${redirect.status} ${classes.defaultFont}`}>
            <FiberManualRecordIcon /> {translateStatus(redirect.status)}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box className={`${classes.redirectionName} ${classes.defaultFont}`}>
              {redirect.name}
            </Box>
            <Box width="10%" className={classes.settings}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                ref={anchorEl}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                onClose={() => setAnchorEl(null)}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            className={`${classes.defaultFont} url`}
            mt={-2}
          >
            <Box>
              <Tooltip title="Copiar link de compartilhamento">
                <IconButton
                  aria-label="copy"
                  className={classes.shareLink}
                  onClick={() =>
                    handleClickCopyButton(process.env.REACT_APP_ZAP_URL, redirect.path)
                  }
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>{`${process.env.REACT_APP_ZAP_URL}/${redirect.path}`}</Box>
          </Box>
        </Box>
        <Box className={classes.statistics}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={`${classes.info} number`}>
                <p>
                  Visitas recebidas
                  <HelpIcon title="Baseado no número total de visitas" />
                </p>
                {redirect.metrics.visits_total}
                {/* {Math.round(Math.random() * 1000)} */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={`${classes.info} number`}>
                <p>
                  Visita duplicadas
                  <HelpIcon title="Baseado no número total de visitas" />
                </p>
                {redirect.metrics.visits_reenter}
                {/* {Math.round(Math.random() * 1000)} */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.info}>
                <p>
                  Progresso
                  <HelpIcon title="Porcentagem para completar todas as visitas do redirecionamento" />
                </p>
                <Box mt={1}>
                  <CircularProgressWithLabel
                    value={progress()}
                    color={progressColor}
                    // value={Math.random() * 100}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={`${classes.info}`}>
                <p>
                  Link Atual
                  <HelpIcon title="Baseado no número total de links. Ex: 2/5 indica que o segundo link do total de 5 está sendo populado no momento" />
                </p>
                {currentLink()}
              </Box>
            </Grid>
            {/* <Grid item xs={6}>
              <Box className={classes.info}>
                <p>Data de expiração</p>
                {redirect.expires_at}
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Popper
          id={`menu-${redirect.id}`}
          open={open}
          anchorEl={anchorEl}
          placement={'bottom-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className={classes.menu}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                  <MenuList>
                    {options.map((el, index) => (
                      <MenuItem key={index}>{el}</MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle className={classes.title}>
          <Typography component="p">Tem certeza?</Typography>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="body2" component="p">
            Deletar Redirecionamento: {redirect.name}
            <span>(/{redirect.path})</span>
            <br />
            Atenção: Ao deletar este redirecionamento, ele sumirá para sempre.
            <br />
            Você tem certeza?
          </Typography>
        </DialogContent>
        <DialogContent className={classes.content2}>
          <Box
            className={classes.selectType}
            style={{ alignItems: 'flex-end', padding: '0 0px 40px' }}
          >
            <Button variant="outlined" color="secondary" onClick={() => handleDelete(redirect.id)}>
              Sim, deletar este Redirecionamento.
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

RedirectionCard.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
  handleClickCopyButton: PropTypes.func,
}

export default RedirectionCard
