import './style.css'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import makefunnelsLogo from '~/assets/images/makefunnels-logo-y.svg'
import BackgroundAnimation from '~/components/BackgroundAnimation'
import Copyright from '~/components/Copyright'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: '10px',
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    maxHeight: '70px',
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    marginTop: theme.spacing(8),
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Montserrat',
  },
}))

function LoginView({ email, password, handleChange, handleSubmit, loading }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <BackgroundAnimation />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h3" className={classes.title}>
          MakeFunnels
        </Typography>
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.avatar} src={makefunnelsLogo} alt="makeFunnels logo" />
          <Typography component="h1" variant="h5">
            Acesse sua conta
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              value={email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={password}
            />
            <Box
              style={{
                textAlign: 'right',
              }}
            >
              <Link
                onClick={() => history.push('/forgotpassword')}
                color="inherit"
                style={{
                  cursor: 'pointer',
                }}
              >
                Esqueci minha senha
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={loading}
            >
              Entrar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}

LoginView.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default LoginView
