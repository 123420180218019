import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    '&:hover, &.isSelected': {
      'border-color': '#016dfd',
      color: '#016dfd',
      cursor: 'pointer',
      '& p': {
        color: '#016dfd',
      },
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default function CardButton({ content, actions, selected, style, handleClick }) {
  const classes = useStyles()

  return (
    <>
      <Card
        style={{
          ...style,
        }}
        className={classNames(classes.root, {
          isSelected: selected,
        })}
        variant="outlined"
        onClick={() => handleClick()}
      >
        <CardContent>{content}</CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </>
  )
}

CardButton.propTypes = {
  content: PropTypes.any,
  actions: PropTypes.func,
  selected: PropTypes.bool,
  style: PropTypes.shape({}),
  handleClick: PropTypes.func,
}
