import { Profile } from '~/types'

import axios from '../config/axios'
const profileScenario: string | null = new URLSearchParams(window.location.search).get(
  'profileScenario',
)

type getMockProfileResponse = {
  data: {
    data: Profile | null
  }
} | null

const getMockProfile = (profileScenario: string): getMockProfileResponse => {
  if (!profileScenario) return null

  switch (profileScenario) {
    case 'platinum':
      return {
        data: {
          data: {
            id: 1,
            name: 'Client Platinum',
            email: 'platinum@gmail.com',
            email_verified_at: '2021-05-20T21:13:59.000000Z',
            image: '1.jpg',
            company_name: '',
            role: 'platinum',
            verified: '1',
            affiliate: 1,
            created_at: '2020-11-12T22:28:25.000000Z',
            updated_at: '2022-02-03T19:57:08.000000Z',
            deleted_at: null,
            aff_commission: '0.40',
            aff_status: 'active',
            current_subscription_id: 15,
            auto_update_commission: 1,
            phone_ddd: null,
            phone_number: null,
            base_domain_id: 1,
          },
        },
      }
    case 'diamond':
      return {
        data: {
          data: {
            id: 1,
            name: 'Client Diamond',
            email: 'diamond@gmail.com',
            email_verified_at: '2021-05-20T21:13:59.000000Z',
            image: '1.jpg',
            company_name: '',
            role: 'diamond',
            verified: '1',
            affiliate: 1,
            created_at: '2020-11-12T22:28:25.000000Z',
            updated_at: '2022-02-03T19:57:08.000000Z',
            deleted_at: null,
            aff_commission: '0.40',
            aff_status: 'active',
            current_subscription_id: 15,
            auto_update_commission: 1,
            phone_ddd: null,
            phone_number: null,
            base_domain_id: 1,
          },
        },
      }
    case 'free':
      return {
        data: {
          data: {
            id: 1,
            name: 'User FREE',
            email: 'free@gmail.com',
            email_verified_at: '2021-05-20T21:13:59.000000Z',
            image: '1.jpg',
            company_name: '',
            role: 'free',
            verified: '1',
            affiliate: 1,
            created_at: '2020-11-12T22:28:25.000000Z',
            updated_at: '2022-02-03T19:57:08.000000Z',
            deleted_at: null,
            aff_commission: '0.25',
            aff_status: 'active',
            current_subscription_id: 15,
            auto_update_commission: 1,
            phone_ddd: null,
            phone_number: null,
            base_domain_id: 1,
          },
        },
      }
    default:
      return {
        data: {
          data: {
            id: 1,
            name: 'Filipe Torres',
            email: 'filipe.bvr@gmail.com',
            email_verified_at: '2021-05-20T21:13:59.000000Z',
            image: '1.jpg',
            company_name: '',
            role: 'admin',
            verified: '1',
            affiliate: 1,
            created_at: '2020-11-12T22:28:25.000000Z',
            updated_at: '2022-02-03T19:57:08.000000Z',
            deleted_at: null,
            aff_commission: '0.25',
            aff_status: 'active',
            current_subscription_id: 15,
            auto_update_commission: 1,
            phone_ddd: null,
            phone_number: null,
            base_domain_id: 1,
          },
        },
      }
  }
}

export const _getProfile = () =>
  profileScenario ? Promise.resolve(getMockProfile(profileScenario)) : axios.get('/auth/me')
