import { Box } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

import makefunnelsLogo from '~/assets/images/makefunnels-logo.svg'
import CreateFunnelModalView from '~/pages/home/components/createFunnelModal/createFunnelModalView'
import MenuProfile from '~/utils/components/menuProfile/menuProfileController'

import DrawerList from './DrawerList'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F5F7FF',
    // backgroundColor: "#f2f2f2",
    height: 'auto',
  },
  sidebar: {
    flex: 0.1,
  },
  navbar: {
    flex: 1,
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    background: 'white',
    color: '#909090',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
  },
  wrapper: {
    flex: 1,
    minHeight: '100vh',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.background,
    color: '#c2d9f5',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  brand: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    '& img': {
      width: 192,
      height: 30,
    },
  },
}))

function DashboardView({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.brand}>
              <img className={classes.avatar} src={makefunnelsLogo} alt="makefunnels logo" />
            </Typography>
          </Toolbar>
          <DrawerList />
        </Drawer>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.navbar}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Box className={classes.title}>
                <CreateFunnelModalView domainId={0} isHeader={true} />
              </Box>
              <MenuProfile />
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  )
}

DashboardView.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.any,
  children: PropTypes.node,
}

export default DashboardView
