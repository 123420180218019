import React, { useState } from 'react'

import { ASYNC_STATE } from '~/constants'
import { useAppState } from '~/context'
import { useAlert } from '~/hooks/useAlert'
import { pagarme } from '~/services/pagarme'
import { changeCreditCard } from '~/services/subscription'
import { useAsync } from '~/utils/useAsync'

import { Drawer } from '../../components/Drawer'

type CreditCardProps = {
  card_holder_name: string
  card_expiration_date: string
  card_number: string
  card_cvv: string
}

const CreditCardDrawer = ({ ...rest }) => {
  const {
    currentSubscription: { reload },
  } = useAppState()
  const [loading, setLoading] = useState<boolean>(false)
  const { data, status, run } = useAsync()
  const { setAlert } = useAlert()
  const resolved = status === ASYNC_STATE.RESOLVED

  React.useEffect(() => {
    if (status === ASYNC_STATE.REJECTED)
      return setAlert(
        'Não foi possível executar esta operação. Por favor entre em contato com o suporte via email',
        'error',
      )

    if (status === ASYNC_STATE.RESOLVED) {
      typeof reload === 'function' && reload()
      return setAlert(data, 'success')
    }
  }, [status])

  const handleChangeCreditCard = (card: CreditCardProps) => {
    setLoading(true)
    const cardValidations = pagarme.validate(card)

    if (!cardValidations.card.card_number) {
      setLoading(false)
      return setAlert('Oops...Número do cartão incorreto', 'error')
    }
    pagarme.cardHash(card).then((card_hash: any) => {
      run(
        changeCreditCard({
          card_hash: card_hash,
          ...card,
        }),
      )
      setLoading(false)
    })
  }

  return <Drawer resolved={resolved} loading={loading} handleClick={handleChangeCreditCard} />
}

export default CreditCardDrawer
