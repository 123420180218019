import { default as ChipBase } from '@material-ui/core/Chip'
import styled from 'styled-components'

export const Chip = styled(ChipBase)`
  &&& {
    background-color: ${(props) => props.$customColor};
    border-radius: 8px;
    color: ${(props) => (props.$isDark ? 'unset' : 'white')};
  }
`
