export const ELEMENT_EVENT = {
  dimension: {
    w: 60,
    h: 60,
  },
}

export const ELEMENT_PAGE = {
  dimension: {
    w: 105,
    h: 130,
  },
}
