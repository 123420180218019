import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getFunnel } from '~/actions'
import EditIcon from '~/assets/images/edit_icon.svg'
import Onboarding from '~/components/Onboarding'
import { ONBOARDING_VIDEOS, PAGES, SET_ALERT, SET_FUNNEL } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as funnelService from '~/services/funnelService'
import { Funnel } from '~/types'
import Loading from '~/utils/components/LoadingPage'

import PagesList from './components/PagesList'

const StyledTextField = styled(TextField)`
   '& input': {
      fontSize: '1.5rem !important',
      fontWeight: 400,
    },
`

function FunnelDetail() {
  const { id }: { id?: string } = useParams()
  const {
    funnel: { data: funnel, isFetching },
  }: { funnel: { data: Funnel; isFetching: boolean } } = useAppState()
  const dispatchApp = useAppDispatch()
  const [name, setName] = useState('')
  const [changeFunnelName, setChangeFunnelName] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getFunnel(dispatchApp, id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return setName(event.target.value)
  }

  const handleEdit = () => {
    setName(funnel.name)
    setChangeFunnelName(true)
  }

  const handleSave = async () => {
    if (name === funnel.name) {
      return dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'É preciso especificar um valor diferente para poder salvar',
          variant: 'warning',
        },
      })
    }

    setLoading(true)
    const data = {
      name,
    }

    try {
      const response = await funnelService.updateFunnelAttr(funnel.id, data)
      const {
        data: {
          data: { ...values },
        },
      } = response
      dispatchApp({
        type: SET_FUNNEL,
        payload: { ...funnel, name: values.name },
      })
    } catch (error) {
      setChangeFunnelName(false)
      setLoading(false)
      return dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Ops! Houve um erro ao editar! Favor tentar novamente mais tarde',
          variant: 'error',
        },
      })
    }
    setChangeFunnelName(false)
    setLoading(false)

    getFunnel(dispatchApp, id)

    return dispatchApp({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Nome do funil editado!',
        variant: 'success',
      },
    })
  }

  const handleCancel = () => {
    setChangeFunnelName(false)
    setName(funnel.name)
  }

  if (isFetching) return <Loading />
  return (
    <>
      <Onboarding page={PAGES.HOME} video={ONBOARDING_VIDEOS.CREATE_PAGE} />
      <Box m={4}>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start" mb={8}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {!changeFunnelName ? (
              <Typography variant="h4">{funnel.name}</Typography>
            ) : (
              <StyledTextField name="path" value={name} size="small" onChange={handleChange} />
            )}
            {loading ? (
              <CircularProgress />
            ) : !changeFunnelName ? (
              <Box>
                <IconButton aria-label="edit" onClick={handleEdit}>
                  <img src={EditIcon} alt="Ícone de edição" />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <IconButton aria-label="save" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        {/* <hr /> */}
        {/*
      <Tabs>
         <DraggableTabs /> 
      </Tabs>
      */}
        <PagesList funnel={funnel} />
      </Box>
    </>
  )
}

export default FunnelDetail
