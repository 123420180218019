import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const InputField = styled(TextField)`
  fieldset,
  input {
    height: 45px;
    border-radius: 8px;
    border: 2px solid #edf0f7;
    box-sizing: border-box;
  }
  input {
    height: 40px;
  }
`
