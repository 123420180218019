import { Avatar, Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  // MoreVert as MoreVertIcon,
  FiberManualRecord as CircleIcon,
} from '@material-ui/icons'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import duplicateIcon from '~/assets/images/duplicate_icon.svg'
import editIcon from '~/assets/images/edit_icon_blue.svg'
import pageIcon from '~/assets/images/pageIcon.svg'
import trashIcon from '~/assets/images/trash_icon.svg'
import { default as Button } from '~/components/BaseButton'
import TooltipStatus from '~/components/TooltipStatus/TooltipStatus'
import { STATUS } from '~/constants'
import Menu from '~/pages/Templates/components/Menu'
import colors from '~/utils/colors'
import menuOption from '~/utils/menuOption'

import PagePath from '../PagePath'
import styles from './styles'

const Status = ({ page, classes }) => {
  if (page.status === STATUS.PAGE.BLOCKED)
    return (
      <TooltipStatus
        className={classes.status}
        style={{ color: colors.danger }}
        label="Desativado"
        title="Este recurso está desativado, por favor entrar em contato com o suporte caso queira ativar novamente."
      />
    )
  let status = {
    color: colors.success,
    message: 'Publicado',
  }
  if (page.status === 'empty') {
    status = {
      color: colors.erro,
      message: 'Vazia',
    }
  }
  return (
    <Typography
      variant="body1"
      component="p"
      gutterBottom
      className={classes.status}
      style={{ color: status.color }}
    >
      <CircleIcon /> {status.message}
    </Typography>
  )
}

const ItemTypes = {
  CARD: 'card',
}

const useStyle = makeStyles(styles)

export const Card = ({
  id,
  page,
  domainUrl,
  index,
  moveCard,
  classesNames,
  handleEvent,
  openEditor,
  setPageIDSelected,
  setDeletePageModal,
  setDuplicatePageModal,
  setEditPageModal,
}) => {
  const classes = useStyle()

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const setDragMode = (isEditing) => {
    return drag(isEditing ? false : drop(ref))
  }

  const normalizeNumber = (number) => {
    const normalized = number + 1
    if (normalized < 10) return `0${normalized}`
    return normalized
  }

  const computedClasses = () => {
    let str = classes.number
    classesNames.forEach((element) => {
      str += ' ' + element
    })
    return str
  }

  const updated_at = new Date(page.updated_at)

  const EditorButton = () => {
    const handle = page.status === 'empty' ? handleEvent : openEditor
    return (
      <Button
        size="small"
        variant="contained"
        handleClick={() => {
          setPageIDSelected(page.id)
          handle(page.id)
        }}
        color="primary"
        text="Abrir Editor"
        width={150}
      />
    )
  }

  function menuOptions(page) {
    return [
      menuOption(editIcon, 'Editar', () =>
        setEditPageModal({
          open: true,
          pageId: page.id,
        }),
      ),
      menuOption(duplicateIcon, 'Duplicar', () =>
        setDuplicatePageModal({
          open: true,
          pageId: page.id,
          id: page.funnel_id,
        }),
      ),
      menuOption(trashIcon, 'Deletar', () =>
        setDeletePageModal({
          validator: page.name,
          open: true,
          pageId: page.id,
          funnelId: page.funnel_id,
        }),
      ),
    ]
  }

  return (
    <Box display="flex" alignItems="center" marginBottom={2} className={classes.row}>
      <Box mr={5} className={computedClasses()}>
        <Avatar className={classes.avatar}>{normalizeNumber(index)}</Avatar>
        <div className="line"></div>
      </Box>

      <Grid container className={classes.funnelInfo} ref={ref} style={{ opacity }}>
        <Grid item xs={12} xl={2}>
          <Box display="flex" height="100%" alignItems="center" marginLeft="20px">
            <Box className={classes.dragIcon}>
              <DragHandleIcon m={0} />
            </Box>
            <Box className={classes.typeIcon}>
              <img src={pageIcon} alt="Ícone de uma página" />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography className={`truncate bold`} variant="h6" gutterBottom>
                {page.name}
              </Typography>
              <PagePath
                page={page}
                domainUrl={domainUrl}
                funnelId={page.funnel_id}
                setDragMode={setDragMode}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} xl={10}>
          <Box className={classes.cardInfos}>
            <div>
              <Typography component="p" gutterBottom>
                Categoria da página
              </Typography>
              <Typography component="span" gutterBottom className="bold">
                {page.page_category.description}
              </Typography>
            </div>
            <div>
              <Typography component="p" gutterBottom>
                Última modificação
              </Typography>
              <Typography component="span" gutterBottom className="bold">
                {updated_at.toLocaleString('pt-BR', {
                  timeZone: 'America/Sao_Paulo',
                })}
              </Typography>
            </div>
            <div className={classes.infoStatus}>
              <Typography component="p" gutterBottom>
                Status
              </Typography>
              <Typography component="span" gutterBottom>
                <Status page={page} classes={classes} />
              </Typography>
            </div>
            <div className="editButton">{EditorButton()}</div>
            <Menu className={classes.menu} index={index} options={menuOptions(page)} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

Card.propTypes = {
  id: PropTypes.number,
  page: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    updated_at: PropTypes.string,
    status: PropTypes.string,
    funnel_id: PropTypes.number,
    page_category: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  setEditPageModal: PropTypes.any,
  domainUrl: PropTypes.string,
  index: PropTypes.number,
  moveCard: PropTypes.func,
  classesNames: PropTypes.arrayOf(PropTypes.string),
  handleEvent: PropTypes.func,
  openEditor: PropTypes.func,
  setPageIDSelected: PropTypes.func,
  setDeletePageModal: PropTypes.func,
  setDuplicatePageModal: PropTypes.func,
}

export default Card
