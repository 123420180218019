import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import React from 'react'

import { StyledButton } from './styles'

const AddButton = ({ onClick, Icon = AddIcon, fit, children }) => {
  return (
    <StyledButton
      variant="contained"
      color="secondary"
      onClick={onClick}
      startIcon={<Icon />}
      $fit={fit}
    >
      {children}
    </StyledButton>
  )
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  Icon: PropTypes.object,
  fit: PropTypes.bool,
  children: PropTypes.node,
}

export default AddButton
