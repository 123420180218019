/* eslint-disable react/prop-types */
import { useMediaQuery, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { useAppState } from '~/context'
import { Dashboard } from '~/utils/components'
import * as localStorage from '~/utils/localStorage'

import AccountDetail from './pages/account-detail'
import Boards from './pages/Boards'
import CanvasDraw from './pages/canvas-draw'
import CreateNewFunnel from './pages/create-new-funnel'
import { DomainsDetail, DomainsList } from './pages/Domains'
import { Downgrade } from './pages/Downgrade'
import { FeedBackCancel } from './pages/FeedBackCancel'
import ForgotPassword from './pages/forgot-password'
import FunnelDetail from './pages/funnel-detail'
import Home from './pages/home'
import Integration from './pages/Integrations'
import Login from './pages/login/components'
import { MobileUnavailable } from './pages/mobile-unavailable'
import NotFound from './pages/not-found'
import Plans from './pages/Plans'
import Recover from './pages/recover'
import { RedirectionCreate, RedirectionList } from './pages/Redirection'
import Resources from './pages/Resources'
import Settings from './pages/Settings'
import { ShareTemplate } from './pages/ShareTemplate'
import { TemplatesList } from './pages/Templates'
import { Upgrade } from './pages/Upgrade'

let lastPathDispatch = false

function Routes({ children }) {
  return (
    <Router>
      <Switch>
        <AuthRoute type="guest" path="/login">
          <Login />
        </AuthRoute>
        <AuthRoute type="guest" path="/recover/:token">
          <Recover />
        </AuthRoute>
        <AuthRoute type="guest" path="/forgotpassword" renderOnMobile>
          <ForgotPassword />
        </AuthRoute>
        <AuthRoute type="public" path="/sharing-template/:funnelId" renderOnMobile>
          <ShareTemplate />
        </AuthRoute>
        <AuthRoute type="private" path="/upgrade/:planCode">
          <Upgrade />
        </AuthRoute>
        <AuthRoute type="private" path="/plan_cancel/:planCode?">
          <FeedBackCancel />
        </AuthRoute>
        <AuthRoute type="private" path="/resources/:planCode?">
          <Resources />
        </AuthRoute>
        <AuthRoute type="private" path="/downgrade">
          <Downgrade />
        </AuthRoute>
        <AuthRoute type="private" exact path="/funnelDraw/:boardId">
          <CanvasDraw />
        </AuthRoute>
        <DashboardAuthRoute type="private" exact path="/boards">
          <Boards />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" exact path="/">
          <Home />
        </DashboardAuthRoute>
        {/* Domains */}
        <DashboardAuthRoute type="private" exact path="/domains">
          <DomainsList />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" exact path="/domains/detail/:id">
          <DomainsDetail />
        </DashboardAuthRoute>
        {/* Campaigns */}
        <DashboardAuthRoute type="private" exact path="/redirection">
          <RedirectionList />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" exact path="/redirection/create">
          <RedirectionCreate />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" exact path="/redirection/detail/:id">
          <RedirectionCreate />
        </DashboardAuthRoute>
        {/* Integrations */}
        <DashboardAuthRoute type="private" exact path="/integration">
          <Integration />
        </DashboardAuthRoute>
        {/* Others */}
        <DashboardAuthRoute type="private" path="/create-new-funnel/:id">
          <CreateNewFunnel />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" path="/funnel-detail/:id">
          <FunnelDetail />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" exact path="/templates/:page_id">
          <TemplatesList />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" path="/settings/:beta?">
          <Settings />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" path="/plans/:beta?">
          <Plans />
        </DashboardAuthRoute>
        <DashboardAuthRoute type="private" path="/account">
          <AccountDetail />
        </DashboardAuthRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      {children}
    </Router>
  )
}
function DashboardAuthRoute({ children, renderOnMobile = false, ...rest }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile && !renderOnMobile) {
    return <MobileUnavailable />
  }

  return (
    <Dashboard>
      <AuthRoute {...rest}>{children}</AuthRoute>
    </Dashboard>
  )
}

function AuthRoute({ type, children, renderOnMobile = false, ...rest }) {
  const location = useLocation()
  const persist = new URLSearchParams(location.search).get('persist')
  const { user } = useAppState()
  const lastPath = localStorage.get('lastPath')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let redirect = null

  if (type !== 'public' && user) {
    if (type === 'guest' && !user.base_domain_id) {
      redirect = '/'
    }
    if (!persist && lastPath && !lastPathDispatch) {
      const GUEST_ROUTES = ['/login', '/recover', '/forgotpassword', '/sharing-template']
      const { url } = JSON.parse(lastPath)
      redirect = GUEST_ROUTES.includes(url) ? '/' : url

      lastPathDispatch = true
    }
  } else if (type === 'private' && !user) {
    redirect = '/login'
  }

  if (isMobile && !renderOnMobile) {
    return <MobileUnavailable />
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        redirect ? (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

AuthRoute.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
}

export default Routes
