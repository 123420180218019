import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`

export const Left = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`
