import { Grid, TextField, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import CancelIcon from '@material-ui/icons/Cancel'
import { default as CircleIcon } from '@material-ui/icons/FiberManualRecord'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SaveIcon from '@material-ui/icons/Save'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import deleteIcon from '~/assets/images/delete_icon.svg'
import detailIcon from '~/assets/images/detail_icon.svg'
import edit from '~/assets/images/edit_icon.svg'
import fileIcon from '~/assets/images/file_icon.svg'
import funnelIcon from '~/assets/images/funnel_icon.svg'
import moveIcon from '~/assets/images/move_icon.svg'
import Menu from '~/pages/Templates/components/Menu'
import { SharingStatus } from '~/types'
import colors from '~/utils/colors'
import optionItem from '~/utils/menuOption'

import { SET_DOMAIN_NICKNAME } from '../../constants'
import { useCollapseState } from '../../hooks/useCollapseState'
import CreateFunnelModalView from '../createFunnelModal/createFunnelModalView'
import DeleteFunnel from '../Modal/DeleteFunnel'

const useRowStyles = makeStyles({
  root: {
    // "& > *": {
    //   "box-shadow": "none",
    // },

    '& .edit': { display: 'none' },
    '&:hover': {
      '& .edit': { display: 'inline' },
    },
  },
  editMenu: {
    display: 'none',
    '& svg': {
      color: '#222',
    },
  },
  customRow: {
    '&:hover .show': {
      display: 'block',
      marginLeft: 'auto',
    },
  },
  customCell: {
    width: '40%',
    '&:hover': {
      cursor: 'pointer',
    },
    '&.p0': {
      padding: '0px',
    },
    '&.small': {
      width: '5%',
    },
  },

  tableContainer: {
    position: 'relative',
  },
  domainName: {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    letterSpacing: '0px',
    color: '#25282B',
    '& p': {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '0px',
      color: '#A0A4A8',
    },
  },

  domainNickName: {
    '& p': {
      fontSize: '16px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '36px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#25282B',
    },
  },
  tableRoot: {
    '& .MuiTableCell-root': {
      fontSize: '1rem',
    },
  },
  folder: {
    background: '#EDF1FF',
    borderRadius: 8,
    width: 53,
    height: 50,
    textAlign: 'center',
    alignItems: ' center',
    justifyContent: ' center',
    display: 'flex',
    '& img': {
      // margin: "9px",
      fontSize: '30px',
      color: '#4C6FFF',
    },
    '&.funnel': {
      background: '#FFF1E9',
      marginRight: '40px',
      '& img': {
        color: '#FF9349',
      },
    },
  },
  funnelInfo: {
    marginBottom: '10px',
    background: '#fff',
    height: '64px',
    borderRadius: '8px',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',

    '&:hover .show': {
      display: 'block',
      marginLeft: 'auto',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  funnelName: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#25282B',
  },
  funnelCategory: {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    letterSpacing: '0px',
    color: '#25282B',
    '& p': {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '0px',
      color: '#A0A4A8',
    },
  },
  noNickname: {
    fontStyle: 'italic !important',
    color: `${colors.blueGray} !important`,
  },
})

function FunnelRowView({ domain, openModal, handleSave, loading }) {
  const { open, toggle } = useCollapseState(domain.id)
  const [charLimit, setCharLimit] = React.useState(0)
  const [name, setName] = React.useState('')
  const [hasNickname, setHasNickname] = React.useState(Boolean(domain.nickname))
  const [changeDomainNickname, setChangeDomainNickname] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState({
    open: false,
    id: null,
    validator: null,
  })
  const classes = useRowStyles()
  const history = useHistory()

  useEffect(() => {
    setName(domain.nickname ? domain.nickname : SET_DOMAIN_NICKNAME)
    setHasNickname(Boolean(domain.nickname))
  }, [domain.nickname])

  const handleClick = (event, id) => {
    openModal(id)
  }

  const handleEdit = () => {
    const nickname = name.indexOf(SET_DOMAIN_NICKNAME) !== -1 ? '' : name
    setCharLimit(nickname.length)
    setName(nickname)
    setChangeDomainNickname(true)
  }

  const handleCancel = () => {
    setChangeDomainNickname(false)
    setName(domain.nickname ? domain.nickname : SET_DOMAIN_NICKNAME)
  }

  const handleChange = (event) => {
    const { value } = event.target

    if (value.length < 191) {
      setCharLimit(value.length)
      return setName(event.target.value)
    }
  }

  function menuOptions(funnel) {
    const { id, name } = funnel
    return [
      optionItem(detailIcon, 'Ver detalhes', () => history.push(`/funnel-detail/${id}`)),
      optionItem(moveIcon, 'Mover funil', (e) => handleClick(e, id)),
      optionItem(deleteIcon, 'Excluir', () =>
        setOpenDeleteModal({
          open: true,
          id,
          validator: name,
        }),
      ),
    ]
  }

  const types = [
    '',
    'Funil Customizado',
    'Funil de Vendas',
    'Funil de Leads',
    'Funil de Webinário',
    'Funil de Lançamento',
  ]

  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell>
          <Box className={classes.folder}>
            <img src={fileIcon} alt="" />
          </Box>
        </TableCell>

        <TableCell component="th" scope="row" width="60%" className={classes.domainNickName}>
          <Box display="flex" alignItems="center">
            {!changeDomainNickname ? (
              <Typography
                component="p"
                gutterBottom
                className={!hasNickname ? classes.noNickname : ''}
              >
                {name}
              </Typography>
            ) : (
              <TextField
                name="path"
                style={{
                  width: '50%',
                }}
                focused={true}
                placeholder={SET_DOMAIN_NICKNAME}
                label={`máx (${charLimit}/191)`}
                value={name}
                size="small"
                onChange={handleChange}
              />
            )}
            {loading ? (
              <CircularProgress />
            ) : !changeDomainNickname ? (
              <Box className={'edit'}>
                <IconButton aria-label="edit" onClick={handleEdit}>
                  <img src={edit} alt="edit" />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <IconButton
                  aria-label="save"
                  onClick={() => {
                    handleSave(domain, name)
                    setChangeDomainNickname(false)
                    setName(name.length ? name : SET_DOMAIN_NICKNAME)
                    setHasNickname(Boolean(name))
                  }}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </TableCell>

        <TableCell component="th" scope="row" width="40%" className={classes.domainName}>
          <p>Domínio</p>
          {domain.name}
        </TableCell>

        <TableCell size="small">
          <IconButton aria-label="expand row" size="small" onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#E5E9F5' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={'0 30px 30px 30px'}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={'40px'}
                marginBottom={'20px'}
              >
                <CreateFunnelModalView domainId={domain.id} isHeader={false} />
              </Box>
              <Box>
                {domain.funnels &&
                  domain.funnels.map((funnel, index) => {
                    return (
                      <Grid container className={classes.funnelInfo} key={funnel.id}>
                        <Grid
                          item
                          xs={7}
                          onClick={() => history.push(`/funnel-detail/${funnel.id}`)}
                        >
                          <Box display="flex" height="100%" alignItems="center" marginLeft="20px">
                            <Box className={`${classes.folder} funnel`}>
                              <img src={funnelIcon} alt="" />
                            </Box>
                            <Typography variant="h6" gutterBottom className={classes.funnelName}>
                              {funnel.name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Box
                            display="flex"
                            height="100%"
                            alignItems="center"
                            textAlign="center"
                            justifyContent="space-between"
                          >
                            <div className={classes.funnelCategory}>
                              <Typography component="p" gutterBottom>
                                Categoria do funil
                              </Typography>
                              {types[funnel.funnel_category_id]}
                            </div>
                            {/* funnel?.isShared */}
                            {funnel?.sharing_as_template?.status &&
                              funnel.sharing_as_template.status !== SharingStatus.STOPED && (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  gridGap="0.5rem"
                                >
                                  <CircleIcon style={{ color: colors.orange, fontSize: '14px' }} />
                                  <div className={classes.funnelCategory}>Compartilhando</div>
                                </Box>
                              )}
                            <Menu index={index} options={menuOptions(funnel)} />
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DeleteFunnel
        funnelId={openDeleteModal.id}
        validator={openDeleteModal.validator}
        isOpen={openDeleteModal.open}
        callBack={setOpenDeleteModal}
      />
    </React.Fragment>
  )
}

FunnelRowView.propTypes = {
  domain: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    nickname: PropTypes.any,

    funnels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        funnel_category_id: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  openModal: PropTypes.func,
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
}

export default FunnelRowView
