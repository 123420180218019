import React from 'react'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainService from '~/services/domainsService'

import FunnelRowView from './funnelRowView'

function FunnelRowController({ openModal, domain }) {
  const [loading, setLoading] = React.useState(false)
  const appDispatch = useAppDispatch()
  const handleSave = async (domain, name) => {
    setLoading(true)
    const data = {
      nickname: name,
    }
    try {
      await domainService.setDomainNickname(domain.id, data)
    } catch (error) {
      setLoading(false)
      return appDispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um erro ao alterar o apelido, tente novamente mais tarde',
          variant: 'error',
        },
      })
    }
    setLoading(false)
    await domainService.FetchDomains(appDispatch)

    return appDispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Apelido do domínio salvo!',
        variant: 'success',
      },
    })
  }

  return (
    <FunnelRowView
      openModal={openModal}
      domain={domain}
      handleSave={handleSave}
      loading={loading}
    />
  )
}

export default FunnelRowController
