import { Typography } from '@material-ui/core'
import AppBarBase from '@material-ui/core/AppBar'
import { default as FormControlBase } from '@material-ui/core/FormControl'
import { Link as LinkBase } from 'react-router-dom'
import styled from 'styled-components'

import { DefaultTitle } from '../Layout/DefaultLayout'

export const Link = styled(LinkBase)`
  &&& {
    color: #4c6fff;
  }
`

export const AppBar = styled(AppBarBase)`
  &&& {
    z-index: 9999;
    background-color: white;
    color: #909090;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`

export const FormControl = styled(FormControlBase)`
  &&& {
    min-width: 120px;
    max-width: 300px;
  }
`

export const Image = styled.img`
  width: 192px;
  height: 30px;
`

export const Brand = styled.a`
  color: white;
  text-align: center;
  font-family: Montserrat;
`

export const Root = styled.div`
  position: relative;
  display: flex;
  background-color: #f5f7ff;
  height: 100vh;
  width: 100wh;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 9rem;
`

export const Content = styled.div`
  width: 1080px;
`

export const ContentTitle = styled.div`
  text-align: left;
`

export const Title = styled(DefaultTitle)`
  &&& {
    padding-bottom: 0.5rem;
  }
`

export const Text = styled(Typography)`
  color: gray;
`

export const ListContent = styled.div`
  overflow-y: scroll;
  height: 500px;
  li {
    padding-left: 0;
    padding-right: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`
