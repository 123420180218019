import {
  DIVERSE_PAGE,
  DOWN_SELL_PAGE,
  LAUNCH_PAGE,
  ORDER_BY,
  OTO,
  THANKS_PAGE,
  UP_SELL_PAGE,
} from '~/constants'

import axios from './config/axios'

const getApiError = (error) => {
  const apiError = error?.response?.data?.error
  return Promise.reject(apiError || 'Houve um problema nesta operação. Por favor tente mais tarde.')
}
/**
 * Funnels
 */
export const getAllFunnels = () => axios.get(`/funnels`)

export const getFunnel = (id) => axios.get(`/funnels/${id}?_embed=pages,favicon_meta`)
// export const getFunnel = (id) => axios.get(`/funnels/${id}?_embed=pages,pages.metrics`);

export const getFunnelCategories = () => axios.get(`/funnelcategories`)

export const createFunnelZero = (domain_id, data) =>
  axios.post(`/domains/${domain_id}/funnels`, data)

export const updateFunnel = (funnel_id, data) => axios.put(`/funnels/${funnel_id}/pages?`, data)

export const updateFunnelAttr = (funnel_id, data) => axios.put(`/funnels/${funnel_id}`, data)

export const createFunnelPage = (funnel_id, data) => axios.post(`/funnels/${funnel_id}/pages`, data)

export const clearFunnelMetrics = (funnel_id) => axios.post(`/funnels/${funnel_id}/metrics/clear`)

export const deleteFunnel = (id) => axios.delete(`/funnels/${id}`)

export const shareFunnelAsTemplate = async (id) => {
  try {
    const response = await axios.post(`/funnels/${id}/share`)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return getApiError(error)
  }
}

export const stopShareFunnelAsTemplate = async (id) => {
  try {
    const response = await axios.post(`/funnels/${id}/stop-share`)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return getApiError(error)
  }
}

export const getSharedFunnelDetails = async (id) => {
  try {
    const response = await axios.get(`/funneltemplate/${id}`)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return getApiError(error)
  }
}

export const importFunnelTemplate = async (id, data) => {
  try {
    const response = await axios.post(`/funneltemplate/import`, data)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return getApiError(error)
  }
}

/**
 * Pages
 */
export const getPageCategories = () => axios.get(`/pagecategories`)

export const getPages = (funnel_id) => axios.get(`/funnels/${funnel_id}/pages?_sort_by=step`)

export const getPagesMetrics = (funnel_id) =>
  axios.get(`/funnels/${funnel_id}/metrics?_sort_by=step`)

export const updatePages = (page_id, data) => axios.put(`/pages/${page_id}`, data)

export const getPage = (page_id) => axios.get(`/pages/${page_id}`)

export const duplicatePage = (page_id, data) => axios.post(`/pages/${page_id}/duplicate`, data)

export const deletePage = (page_id) => axios.delete(`/pages/${page_id}`)

/**
 * Templates
 */
export const getTemplates = (page, limit, pageCategory) => {
  let url = `/pagetemplates?_sort_by=${ORDER_BY.DESC}created_at`
  if (page) url += `&page=${page}`
  if (limit) url += `&limit=${limit}`

  if (pageCategory) {
    if (pageCategory === DIVERSE_PAGE)
      url += `&filter[page_category_id][0]=${THANKS_PAGE}&filter[page_category_id][1]=${LAUNCH_PAGE}&filter[page_category_id][2]=${DIVERSE_PAGE}`
    else if (pageCategory === OTO)
      url += `&filter[page_category_id][0]=${UP_SELL_PAGE}&filter[page_category_id][1]=${DOWN_SELL_PAGE}`
    else url += `&filter[page_category_id]=${pageCategory}`
  }

  return axios.get(url)
}
export const setTemplateForOnePage = (template_id, page_id) =>
  axios.put(`/pagetemplates/${template_id}/choose`, {
    page_id,
  })

export const setFavicon = (funnel_id, formData) => {
  return axios.post(`/funnels/${funnel_id}/favicon`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
