import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getFunnel } from '~/actions'
import ModalComponent from '~/components/Modal'
import { openUpgradeModal, useUpgradeModal } from '~/components/UpgradeModal/context'
import { HTTP_ERROS, SET_ALERT, SET_PAGE_CATEGORIES, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as funnelService from '~/services/funnelService'

export default function CreatePageModal({ open, pageId, handleClose, nextStep }) {
  const [_, dispatch] = useUpgradeModal()
  const dispatchApp = useAppDispatch()
  const {
    pageCategories,
    funnel: { data: funnel },
  } = useAppState()

  const [data, setData] = useState({
    name: '',
    page_category_id: 0,
    step: nextStep,
  })
  const [name, setName] = useState('')
  const [title, setTitle] = useState('Criar Página')
  const [category, setCategory] = useState(0)

  useEffect(() => {
    async function getPageInfo() {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: true, background: false },
      })
      setTitle('Editar Página')
      try {
        const response = await funnelService.getPage(pageId)

        const {
          data: {
            data: { name, path, page_category_id },
          },
        } = response

        setName(name)
        setCategory(page_category_id)
        setData({ ...data, name, path, page_category_id })
      } catch (error) {
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Não foi possível carregar os dados da página',
            variant: 'error',
          },
        })
      }
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: false },
      })
    }
    if (pageId) {
      getPageInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const pageCategoriesResponse = await funnelService.getPageCategories()

        const {
          data: {
            data: [...categories],
          },
        } = pageCategoriesResponse

        dispatchApp({ type: SET_PAGE_CATEGORIES, payload: categories })
      } catch (error) {
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema de conexão!',
            variant: 'error',
          },
        })
      }
    }
    if (pageCategories.length === 0) {
      fetchCategories()
    }
  }, [dispatchApp, pageCategories.length])

  const [errorData, setErrorData] = useState()

  function handleChange(event) {
    const { value, name } = event.target
    const auxData = data

    if (name === 'name') setName(value)
    if (name === 'page_category_id') setCategory(value)

    auxData[name] = value

    setErrorData()

    return setData(auxData)
  }

  async function handleSubmit() {
    if (!data.name) return setErrorData('A página precisa de um nome')

    if (!data.page_category_id) return setErrorData('Não foi escolhida uma categoria')

    data.step = nextStep
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })
    const { id } = funnel

    try {
      if (pageId) await funnelService.updatePages(pageId, data)
      else await funnelService.createFunnelPage(id, data)
      await getFunnel(dispatchApp, id)
      handleClose()
    } catch (err) {
      const {
        response: {
          data: { code, error },
        },
      } = err
      if (code === HTTP_ERROS.UNAUTHORIZED) openUpgradeModal(dispatch)
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: error.toString(),
          variant: 'error',
        },
      })
    }

    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  const inputs = [
    {
      component: 'textField',
      name: 'name',
      label: 'Nome *',
      placeholder: 'Digite o nome da sua página',
      fullWidth: true,
      value: name,
      error: errorData && errorData.indexOf('nome') !== -1,
      helper: errorData && errorData.indexOf('nome') !== -1 ? errorData : '',
      onChange: handleChange,
    },
    {
      component: 'select',
      value: category,
      name: 'page_category_id',
      label: 'Categoria *',
      // fullWidth: true,
      onChange: handleChange,
      optionDisabled: 'Selecione uma categoria',
      options: pageCategories,
      error: errorData && errorData.indexOf('categoria') !== -1,
      helper: errorData && errorData.indexOf('categoria') !== -1 ? errorData : '',
    },
  ]

  const buttons = [
    {
      name: 'Confirmar',
      variant: 'contained',
      handleSubmit,
    },
  ]

  return (
    <ModalComponent state={{ open, title, inputs, buttons }} actions={{ handleClose }}>
      <Typography variant="h6">
        Escolha um nome para sua página e a <br /> categoria na qual ela se encaixa.
      </Typography>
    </ModalComponent>
  )
}

CreatePageModal.propTypes = {
  open: PropTypes.bool,
  pageId: PropTypes.number,
  handleClose: PropTypes.func,
  nextStep: PropTypes.any,
}

CreatePageModal.defaultProps = {
  open: false,
  pageId: null,
  handleClose: () => {},
}
