import { Box, Button, Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

import CreateIntegration from '../Modal/Create'
import DeleteIntegration from '../Modal/Delete'
import CardInfo from '../templates/Card'

const useStyles = makeStyles({
  categories: {
    fontFamily: 'Nunito Sans',
    position: 'relative',
    zIndex: 0,
  },
  listCategory: {
    fontWeight: 300,
    color: colors.blueDark,
    cursor: 'pointer',
    '& .Mui-selected': {
      background: 'rgba(0,0,0,0)',
      color: colors.bluePrimary,
      fontWeight: 600,
    },
  },
  integrationNotFounded: {
    marginTop: 40,
    '& p': {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      fontWeight: 600,
    },
    '& button': {
      borderRadius: '8px !important',
      // margin: "0px 15px",
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      textAlign: 'center',
      width: 220,

      '& span': {
        textTransform: 'capitalize !important',
      },

      color: colors.bluePrimary,
      borderColor: colors.bluePrimary,
      borderWidth: 2,

      '&:hover': {
        borderColor: colors.bluePrimary,
        borderWidth: 2,
      },
    },
  },
  requestIntegration: {
    color: colors.bluePrimary,
    borderColor: colors.bluePrimary,

    borderRadius: '8px !important',
    borderWidth: 2,
    // margin: "0px 15px",
    padding: '5px 40px',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&:hover': {
      background: 'rgba(0,0,0,0)',
      borderColor: colors.bluePrimary,
    },
  },
  card: {
    marginBottom: 50,
    marginRight: 25,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: 290,
  },
  cardsIntegrations: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
})
export default function View({
  state: { integrationsList, selectedOption, categories, isFetching },
  action: { filterList, handleDelete, handleCreate },
}) {
  const classes = useStyles()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [integration, setIntegration] = React.useState({
    name: '',
    service: '',
  })

  const handleOpen = (type) => {
    switch (type) {
      case 'delete':
        return setOpenDelete(!openDelete)
      case 'create':
        return setOpenCreate(!openCreate)
      default:
        break
    }
  }

  if (isFetching) return null
  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Grid container>
        <Grid item lg={9} md={12} xs={12}>
          {integrationsList.length ? (
            <Box className={classes.cardsIntegrations}>
              {integrationsList.map((integration, index) => {
                return (
                  <Box
                    key={index}
                    className={classes.card}
                    onClick={() => setIntegration(integration)}
                  >
                    <CardInfo state={{ info: integration }} actions={{ handleOpen }} />
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Box
              my={2}
              textAlign="center"
              style={{
                paddingTop: '80px',
                flexGrow: 2,
                alignSelf: 'stretch',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Nenhuma Integração foi encontrada
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={3} className={classes.categories}>
          <Typography variant="h6" gutterBottom>
            Categorias
          </Typography>
          <List className={classes.listCategory}>
            {categories.map((category, index) => (
              <ListItem
                key={index}
                selected={selectedOption === index}
                onClick={() => filterList(index)}
              >
                {category}
              </ListItem>
            ))}
          </List>

          <Box className={classes.integrationNotFounded}>
            <Typography variant="body2" gutterBottom>
              Não encontrou o que procurava?
            </Typography>
            <Button
              variant="outlined"
              className={classes.requestIntegration}
              href="https://forms.gle/vcmMp5AEAyTSNMkZ7"
              target="_blank"
            >
              Solicitar Integração
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* Modais */}
      <DeleteIntegration
        state={{ open: openDelete, integration }}
        actions={{ handleOpen, handleDelete }}
      />
      <CreateIntegration
        state={{ open: openCreate, integration }}
        actions={{ handleOpen, handleCreate }}
      />
    </Box>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    integrationsList: PropTypes.array,
    isFetching: PropTypes.bool,
    categories: PropTypes.array,
    selectedOption: PropTypes.number,
  }),
  action: PropTypes.shape({
    filterList: PropTypes.func,
    handleDelete: PropTypes.func,
    handleCreate: PropTypes.func,
  }),
}
