import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import { recover } from '~/services/loginService'

import View from './recoverView'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Recover() {
  const history = useHistory()
  const { token } = useParams()
  const email = useQuery().get('email')
  const dispatch = useAppDispatch()
  const [credential, setCredential] = useState({
    password: '',
    password_confirmation: '',
  })

  function handleChange(e) {
    const { value, name } = e.target

    setCredential({
      ...credential,
      [name]: value,
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (credential.password !== credential.password_confirmation)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'As senhas não coincidem!',
          variant: 'error',
        },
      })

    try {
      await recover(token, email, credential)
      history.push(`/login`)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Sua senha foi redefinida com sucesso.',
          variant: 'success',
        },
      })
    } catch (error) {
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um problema. Tenta por favor mais tarde!',
          variant: 'error',
        },
      })
    }
  }

  return <View state={{ ...credential }} actions={{ handleSubmit, handleChange }} />
}

export default Recover
