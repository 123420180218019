import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'

function DeletePage({ state: { open, validator }, actions: { handleClose, handleSubmit } }) {
  const [pageName, setPageName] = React.useState('')

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'domain-name',
      type: 'text',
      label: (
        <>
          Para confirmar, por favor digite <b>{validator}</b>
        </>
      ),
      onChange: (event) => setPageName(event.target.value),
    },
  ]

  const buttons = [
    {
      variant: 'outlined',
      customClass: 'error',
      name: 'Sim, exclua esta página',
      disabled: validator !== pageName,
      handleSubmit,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{ open, title: 'Tem certeza?', inputs, buttons }}
    >
      <Typography variant="h6" gutterBottom>
        Tem certeza que deseja excluir esta página?
      </Typography>
      <Typography variant="body2" className="disclaimer" my={3}>
        Esta é uma operação irreversível e todos os dados referentes à esta página serão apagados.
      </Typography>
    </ModalComponent>
  )
}

DeletePage.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    validator: PropTypes.string,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}

export default DeletePage
