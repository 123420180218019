import React from 'react'

function NotFound() {
  return (
    <div>
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
      <a className="button" href="/">
        <i className="icon-home"></i> Voltar para a página inicial.
      </a>
    </div>
  )
}

export default NotFound
