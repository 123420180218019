import React, { useEffect, useState } from 'react'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import {
  createIntegration,
  deleteIntegration,
  getAvailableIntegrations,
} from '~/services/integrationsService'

import View from './View'

export default function IntegrationList() {
  const dispatchApp = useAppDispatch()

  const [integrationsList, setIntegrationsList] = useState([])
  const [categories, setCategories] = useState([])
  const [defaultList, setDefaultList] = useState([])
  const [selectedOption, setSelectedOption] = useState(0)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    getIntegrations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getIntegrations() {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })

    try {
      const response = await getAvailableIntegrations()
      const {
        data: { data },
      } = response
      setIntegrationsList(data.services)
      setDefaultList(data.services)
      setCategories(['Todos', ...data.categories])
      setIsFetching(false)
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um problema para carregar as Integrações! Tente novamente mais tarde!',
          variant: 'error',
        },
      })
    }
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  function filterList(index) {
    setSelectedOption(index)

    if (index === 0) return setIntegrationsList(defaultList)
    const newListFiltered = defaultList.filter((el) => el.category === categories[index])
    setIntegrationsList(newListFiltered)
  }

  async function handleDelete(name, handleClose) {
    try {
      await deleteIntegration(name)
      getIntegrations()
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Integração excluída',
          variant: 'success',
        },
      })

      handleClose()
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Não foi possível excluir a integração!',
          variant: 'error',
        },
      })
    }
  }

  async function handleCreate(service, body, handleClose) {
    try {
      await createIntegration(service, body)
      getIntegrations()

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Integração criada',
          variant: 'success',
        },
      })

      handleClose()
    } catch (err) {
      const {
        response: {
          data: {
            error: { ...credentials },
          },
        },
      } = err

      const keys = Object.keys(credentials)

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message:
            credentials[keys[1]] || credentials[keys[0]] || 'Não foi possível criar a integração!',
          variant: 'error',
        },
      })
    }
  }

  return (
    <View
      state={{ integrationsList, selectedOption, categories, isFetching }}
      action={{ filterList, handleDelete, handleCreate }}
    />
  )
}
