import { Box, Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import React from 'react'

import ModalComponent from '~/components/Modal'
import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainService from '~/services/domainsService'
import * as funnelService from '~/services/funnelService'
import fetch from '~/utils/fetch'

type DeleteFunnelProps = {
  isOpen: boolean
  funnelId: string
  validator: string
  callBack: (state: any) => void
}

function DeleteFunnel({ isOpen, funnelId, validator, callBack }: DeleteFunnelProps) {
  const dispatchApp = useAppDispatch()
  const [term, setTerm] = React.useState('')

  const handleDelete = () => {
    fetch(dispatchApp, async () => {
      try {
        await funnelService.deleteFunnel(funnelId)
        domainService.FetchDomains(dispatchApp)
        callBack((state: any) => ({ ...state, open: false }))
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Funil excluído!',
            variant: 'success',
          },
        })
      } catch (error) {
        const err = error as AxiosError
        const errorResponse = err?.response?.data.error || ''

        return dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: errorResponse,
            variant: 'error',
          },
        })
      }
    })
  }

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'funnel-name',
      type: 'text',
      label: (
        <>
          Para confirmar, por favor digite <b>&quot;{validator}&quot;</b>
        </>
      ),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => setTerm(event.target.value),
    },
  ]

  const buttons = [
    {
      variant: 'outlined',
      customClass: 'error',
      name: 'Sim, exclua este funil',
      disabled: validator !== term,
      handleSubmit: handleDelete,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose: () => callBack((state: any) => ({ ...state, open: false })) }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      state={{
        open: isOpen,
        title: 'Tem certeza?',
        inputs,
        buttons,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Tem certeza que deseja excluir este Funil?
      </Typography>
      <Box my={3}>
        <Typography variant="body2" className="disclaimer">
          ATENÇÃO: Ao excluir este funil, esta ação não poderá ser desfeita.
        </Typography>
      </Box>
    </ModalComponent>
  )
}

export default DeleteFunnel
