import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as HelpImg } from '~/assets/images/help_img.svg'
import colors from '~/utils/colors'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    paddingLeft: '50px',
    borderBottom: '1px solid #E5E9F5',
    display: 'flex',
    '& p': {
      textAlign: 'left',
      fontSize: '28px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '33px 50px 29px',
    height: '100%',
  },
  textTitle: {
    textAlign: 'center',
    fontSize: '30px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: '21px 0px 20px',
    color: colors.bluePrimary,
  },
  text: {
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    margin: '0px 0px 27px',
    color: colors.dark80,
  },
  dialog: {
    '& .MuiDialog-paperFullWidth': {
      width: '800px !important',
    },
  },
  button: {
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    background: colors.bluePrimary,
    color: colors.white80,
    boxShadow: 'none',
    marginTop: '27px',

    borderRadius: '8px',
    width: 167,
    height: 42,
    '&:hover': {
      background: colors.bluePrimary,
      color: colors.white80,
    },
  },
}))

export default function View({ state: { open }, actions: { handleClose } }) {
  const classes = useStyles()
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        className={classes.dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.title}>
          <Typography component="p">Precisa de ajuda?</Typography>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <HelpImg />
          <Typography paragraph className={classes.textTitle}>
            Conte conosco!
          </Typography>
          <Typography paragraph className={classes.text}>
            Nosso suporte funciona via <br />
            <b>e-mail</b> (suporte@makefunnels.com.br) e <b>WhatsApp</b>{' '}
            <IconButton href="https://wa.me/5534991519716?text=Oi%20Bia%2C%20preciso%20de%20ajuda%20com%20minha%20conta%20da%20MakeFunnels">
              <WhatsAppIcon />
            </IconButton>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}
