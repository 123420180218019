/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Box, Typography } from '@material-ui/core'
import React from 'react'

const BoardsEmpty = () => (
  <Box
    my={2}
    textAlign="center"
    style={{
      paddingTop: '80px',
      flexGrow: 2,
      alignSelf: 'stretch',
    }}
  >
    <Typography variant="h6" gutterBottom>
      Nenhum Quadro foi encontrada
    </Typography>
  </Box>
)

export default BoardsEmpty
