import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import FunnelRowController from '../funnelRow/funnelRowController'
import MoveModal from '../Modal/MoveFunnel'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      'box-shadow': 'none',
    },
  },
  customCell: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableContainer: {
    position: 'relative',
    background: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    borderRadius: '8px',
  },
})

function FunnelTableView({ domains }) {
  const classes = useRowStyles()
  const [open, setOpenModal] = useState(false)
  const [funnelId, setFunnelId] = useState(NaN)

  function openModal(id) {
    setOpenModal(true)
    setFunnelId(id)
  }
  if (domains && domains.isFetching) return null
  if (domains && domains.data && !domains.data.length)
    return (
      <React.Fragment>
        <Box my={2} textAlign="center" style={{ paddingTop: '80px' }}>
          <Typography variant="h6" gutterBottom>
            Nenhum funil foi encontrado
          </Typography>
        </Box>
      </React.Fragment>
    )
  return (
    <React.Fragment>
      {domains.data.length &&
        domains.data.map((domain) => (
          <Box my={2} key={domain.name}>
            <TableContainer component={Paper} className={classes.tableContainer} variant="outlined">
              <Table aria-label="collapsible table">
                <TableBody>
                  <FunnelRowController domain={domain} openModal={openModal} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      <MoveModal
        open={open}
        handleCloseModal={() => setOpenModal(false)}
        funnelSelected={funnelId}
      />
    </React.Fragment>
  )
}

FunnelTableView.propTypes = {
  domains: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }),
}

export default FunnelTableView
