import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'

import { useRedirectionContext } from '../../context'

function InputFile() {
  const { state, setState } = useRedirectionContext()
  const [img, setImg] = React.useState({
    name: null,
    url: null,
  })
  const { file } = state

  const handleFile = (e) => {
    setState({
      file: e.target.files[0],
    })
  }

  const resetFile = () => {
    setState({
      file: null,
    })
    setImg({
      name: null,
      url: null,
    })
  }

  React.useEffect(() => {
    if (file && file.name && !file.type) {
      setImg({
        name: file.name,
        url: file.url,
      })
    } else if (file && file.type) {
      setImg({
        name: file.name,
        url: URL.createObjectURL(file),
      })
    }
  }, [file])

  return (
    <>
      <Button variant="outlined" component="label">
        {img.name ? 'Trocar imagem' : 'Enviar imagem'}
        <input type="file" onChange={handleFile} accept="image/png, image/jpeg" hidden />
      </Button>
      {img.name && (
        <Button variant="text" onClick={resetFile}>
          Remover imagem
        </Button>
      )}
      {img.name && (
        <>
          <Box mt={3}>
            <img style={{ maxWidth: '300px' }} src={img.url} alt="preview da imagem" />
          </Box>
          <Typography>{img.name}</Typography>
        </>
      )}
    </>
  )
}

export default InputFile
