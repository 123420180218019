// import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { Arrow, Layer, Line, Stage } from 'react-konva'
import styled from 'styled-components'

import LineConnection from '../../components/LineConnection'
import PageCard from '../../components/PageCard'
import ZoomControl from '../../components/ZoomControl'
import { ADD_CONNECTION, EVENT_TYPE, UPDATE_POSITIONS } from '../../constants/actions'
import { ELEMENT_EVENT, ELEMENT_PAGE } from '../../constants/elementsType'
import { RANGE, SCALE_BY } from '../../constants/scale'
import { getElementIndex, useBoard } from '../../context/BoardProvider'
import BoardView from './BoardView'

const StageStyled = styled(Stage)`
  background: radial-gradient(circle, #dce2f9 10%, transparent 11%);
  background-size: 1em 1em;
  background-color: #f7f8fc;
  opacity: 1;
`

function createConnectionPoints(source, destination) {
  return [source.x, source.y, destination.x, destination.y]
}

function hasIntersection(position, step) {
  return !(
    step.x > position.x ||
    step.x + ELEMENT_PAGE.dimension.w < position.x ||
    step.y > position.y ||
    step.y + ELEMENT_PAGE.dimension.h < position.y
  )
}

function detectConnection(position, id, elements, cardsRef, getPointWithScale) {
  const mousePosWithScale = getPointWithScale(position)
  const elementOrigen = elements.find((el) => el.id === id)

  const intersectingStep = elements.find((el) => {
    const nextCardWithScale = getPointWithScale(cardsRef?.current[el?.id].getAbsolutePosition())
    return el?.id !== id && hasIntersection(mousePosWithScale, nextCardWithScale)
  })
  const isDuplicatedConnection =
    intersectingStep && elementOrigen?.match.some((matchId) => matchId === intersectingStep?.id)

  if (!isDuplicatedConnection) {
    return intersectingStep
  }
  return null
}

const Board = () => {
  const [connectionPreview, setConnectionPreview] = useState(null)
  const {
    board: { nodes },
    stageRef,
    dispatch,
    getPointWithScale,
  } = useBoard()

  const cardsRef = React.useRef([])
  const [selectedId, setSelectedId] = useState(null)
  const [arrowSelectedId, setArrowSelectedId] = useState(null)
  const [percentil, setPercentil] = useState(100)

  const setConnections = (id, to) => {
    dispatch({
      type: ADD_CONNECTION,
      payload: {
        id,
        to,
      },
    })
  }

  const handleOnDragMove = (elementRef, e) => {
    const elementsCopy = [...nodes]
    const elementIndex = elementsCopy.findIndex((el) => el.id === elementRef.id)
    elementsCopy[elementIndex] = {
      ...elementsCopy[elementIndex],
      position: {
        x: e.target.x(),
        y: e.target.y(),
      },
    }
    dispatch({
      type: UPDATE_POSITIONS,
      payload: elementsCopy,
    })
  }

  const handleClick = (id) => {
    setSelectedId(id)
  }

  const generateLinePoints = (from, to) => {
    const getRectCenter = (type, x, y) => {
      const dimension = type === EVENT_TYPE ? ELEMENT_EVENT.dimension : ELEMENT_PAGE.dimension
      return {
        x: x + dimension.w / 2,
        y: y + dimension.h / 2,
      }
    }

    const A = getRectCenter(from?.type, from?.position?.x, from?.position?.y)
    const B = getRectCenter(to?.type, to?.position?.x, to?.position?.y)
    const dx = B.x - A.x
    const dy = B.y - A.y
    const angle = Math.atan2(-dy, dx)

    const radius = 70

    return [
      A.x + -radius * Math.cos(angle + Math.PI),
      A.y + radius * Math.sin(angle + Math.PI),
      B.x + -radius * Math.cos(angle),
      B.y + radius * Math.sin(angle),
    ]
  }

  function handleAnchorDragStart(e) {
    const position = e.target.position()
    setConnectionPreview(
      <Line
        x={position.x}
        y={position.y}
        points={createConnectionPoints(position, position)}
        stroke="black"
        strokeWidth={2}
      />,
    )
  }

  function handleAnchorDragMove(e) {
    const position = e.target.position()
    const mousePos = e.target.getRelativePointerPosition()
    setConnectionPreview(
      <Arrow
        x={position.x}
        y={position.y}
        points={createConnectionPoints({ x: 0, y: 0 }, mousePos)}
        stroke={'#4C6FFF'}
        strokeWidth={2}
        fill={'#4C6FFF'}
      />,
    )
  }

  function handleArrowSelected(arrowId) {
    setArrowSelectedId(arrowId)
  }

  function handleAnchorDragEnd(e, id) {
    setConnectionPreview(null)
    const stage = stageRef.current
    const mousePos = stage.getPointerPosition()

    const connectionTo = detectConnection(mousePos, id, nodes, cardsRef, getPointWithScale)
    if (connectionTo) {
      setConnections(id, connectionTo.id)
    }
  }

  const handleWheel = (e) => {
    // stop default scrolling
    e.evt.preventDefault()

    const stage = stageRef.current
    const oldScale = stage.scaleX()
    const pointer = stage.getPointerPosition()

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    }

    // how to scale? Zoom in? Or zoom out?
    let direction = e.evt.deltaY > 0 ? 1 : -1

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
      direction = -direction
    }

    const newScale = direction > 0 ? oldScale + SCALE_BY : oldScale - SCALE_BY

    let scale = newScale
    if (newScale < RANGE.MIN) scale = RANGE.MIN
    if (newScale > RANGE.MAX) scale = RANGE.MAX

    stage.scale({ x: scale, y: scale })
    setPercentil(scale * 100)

    const newPos = {
      x: pointer.x - mousePointTo.x * scale,
      y: pointer.y - mousePointTo.y * scale,
    }
    stage.position(newPos)
  }

  const handleStageOnClick = (e) => {
    const stage = stageRef.current
    // prevent default behavior
    e.evt.preventDefault()
    if (e.target === stage) {
      setSelectedId(null)
      setArrowSelectedId(null)
    }
  }

  const builder = (element) => {
    return (
      <React.Fragment key={`pageCard_${element.id}`}>
        <PageCard
          {...element}
          onDragMove={(e) => handleOnDragMove(element, e)}
          handleClick={() => handleClick(element.id)}
          isSelected={element.id === selectedId}
          handleAnchorDragEnd={(e) => handleAnchorDragEnd(e, element.id)}
          handleAnchorDragMove={handleAnchorDragMove}
          handleAnchorDragStart={handleAnchorDragStart}
          ref={(el) => {
            cardsRef.current[element.id] = el
          }}
        />
        {element.match &&
          element.match.map((matchId) => {
            const index = getElementIndex(matchId, nodes)
            const elementTarget = nodes[index]
            const arrowId = `${element.id}_${elementTarget.id}`
            const isArrowSelected = arrowId === arrowSelectedId

            return (
              elementTarget && (
                <React.Fragment key={arrowId}>
                  <LineConnection
                    onClick={(args) => handleArrowSelected(args !== undefined ? args : arrowId)}
                    isArrowSelected={isArrowSelected}
                    points={generateLinePoints(element, elementTarget)}
                    nodeId={element.id}
                    matchId={matchId}
                  />
                </React.Fragment>
              )
            )
          })}
      </React.Fragment>
    )
  }

  return (
    <BoardView>
      <ZoomControl stageRef={stageRef} percentil={percentil} setPercentil={setPercentil} />
      <StageStyled
        ref={stageRef}
        width={window.innerWidth}
        height={window.innerHeight - 64} // 64px is the Topbar height
        onWheel={handleWheel}
        onClick={handleStageOnClick}
        draggable
      >
        <Layer>
          {nodes.map((element) => builder(element))}
          {connectionPreview}
        </Layer>
      </StageStyled>
    </BoardView>
  )
}

export default Board
