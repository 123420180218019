import { Box as BoxBase } from '@material-ui/core'
import CheckBase from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  Dialog as DialogBase,
  DialogContent,
  DialogHeaderText,
  DialogText,
} from '~/components/CustomModal'
import { ButtonBase, ButtonContained as DialogButton } from '~/pages/Layout/DefaultLayout'

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    margin-right: 8px;
  }
`

export const Box = styled(BoxBase)`
  &&& {
    width: 100%;
  }
`

export const Dialog = styled(DialogBase)`
  &&& {
    & .MuiDialog-paperWidthMd {
      width: 560px;
    }
  }
`

export const CheckText = styled(DialogText)`
  &&& {
    margin-bottom: 8px;
  }
`

export const DialogButtonCancel = styled(ButtonBase)`
  &&& {
    margin-right: 16px;
  }
`

function useIsOpen() {
  const [open, setOpen] = React.useState(false)

  function toggle() {
    setOpen(!open)
  }

  return { open, toggle }
}

function AlertModal({ open, toggle, continueClick, loading }) {
  return (
    <Dialog
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={toggle}
    >
      <DialogContent>
        <DialogHeaderText paragraph>Aviso!</DialogHeaderText>
        <DialogText paragraph>Tem certeza que deseja rebaixar para o plano free? 😢</DialogText>
        <DialogText variant="body2">
          Você{' '}
          <DialogText style={{ fontWeight: 'bold', padding: 0, margin: 0 }} component="span">
            perderá todos os recursos exclusivos do seu plano atual
          </DialogText>{' '}
          assim que o período de assinatura terminar:
        </DialogText>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
          <DialogButtonCancel variant="outlined" onClick={toggle}>
            Cancelar
          </DialogButtonCancel>
          <DialogButton onClick={continueClick} variant="contained" disabled={loading}>
            {loading ? 'Processando...' : 'Rebaixar Conta'}
          </DialogButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

AlertModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  continueClick: PropTypes.func,
}

export { AlertModal, useIsOpen }
