import PropTypes from 'prop-types'
import React from 'react'

import { getFunnel } from '~/actions'
import { useAppDispatch } from '~/context'
import * as funnelService from '~/services/funnelService'
import fetch from '~/utils/fetch'

import DeletePage from './DeletePage'

function DeletePageContainer({
  state: { pageId, funnelId, open, validator },
  action: { handleClose },
}) {
  const dispatchApp = useAppDispatch()

  function handleSubmit() {
    fetch(
      dispatchApp,
      async () => {
        await funnelService.deletePage(pageId)
        await getFunnel(dispatchApp, funnelId)
      },
      () => {},
    )
  }

  return (
    <DeletePage
      state={{ open: open, validator: validator }}
      actions={{ handleClose, handleSubmit }}
    />
  )
}

DeletePageContainer.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    pageId: PropTypes.number,
    funnelId: PropTypes.number,
    validator: PropTypes.string,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

export default DeletePageContainer
