import { Dialog } from '@material-ui/core'
import React, { ElementType } from 'react'
import styled from 'styled-components'

interface LoginModalProps {
  isOpen: boolean
  handleClose: () => void
  ContentComponent: ElementType
  [x: string]: unknown
}

const ModalContent = styled.div`
  margin: 0 auto;
  padding: 2rem 24px 1.5rem;
  /* justify-content: center; */
  align-items: flex-start;
  width: 25rem;
  min-height: 36rem;
  display: flex;
  flex-direction: column;
`

export function LoginModal(props: LoginModalProps) {
  const { isOpen, handleClose, ContentComponent } = props

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalContent>
        <ContentComponent {...props} />
      </ModalContent>
    </Dialog>
  )
}
