import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'

const DeleteIntegration = ({
  state: { open, integration },
  actions: { handleOpen, handleDelete },
}) => {
  const [integrationName, setIntegrationName] = React.useState('')
  const handleClose = () => {
    setIntegrationName('')
    handleOpen('delete')
  }

  const buttons = [
    {
      variant: 'outlined',
      customClass: 'error',
      name: 'Sim, exclua esta integração',
      disabled: integrationName !== integration.name,
      handleSubmit: () => {
        handleDelete(integration.service, handleClose)
      },
    },
  ]

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'integration-name',
      type: 'text',
      label: (
        <>
          Para confirmar, por favor digite <b>{integration.name}</b>
        </>
      ),
      onChange: (event) => setIntegrationName(event.target.value),
    },
  ]
  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{ open, title: 'Tem certeza?', buttons, inputs }}
    >
      <Typography variant="h6" gutterBottom>
        Tem certeza que deseja excluir essa integração?
      </Typography>
      <Typography variant="body2" className="disclaimer" my={3}>
        Ao excluir essa integração, suas páginas e funis que a utilizam poderão não funcionar como
        esperado.
      </Typography>
    </ModalComponent>
  )
}

DeleteIntegration.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    integration: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleOpen: PropTypes.func,
    handleDelete: PropTypes.func,
  }),
}

export default DeleteIntegration
