/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

export const CardsSkeleton = () => {
  return (
    <GridStyle item>
      <Skeleton variant="rect" width={300} height={375} />
    </GridStyle>
  )
}

export const GridCardsSkeleton = () => {
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {[...new Array(9)].map((_, index) => (
        <CardsSkeleton key={index} />
      ))}
    </Grid>
  )
}

const GridStyle = styled(Grid)`
  height: 399px;
  width: 324px;
`
