/* eslint-disable react/prop-types */
import React from 'react'

import { Content, ContentHeader, Credit, TextBold, Wrapper } from './styles'

function normalizePrice(price) {
  return Number.parseFloat(price / 100)
    .toFixed(2)
    .replace('.', ',')
}

function Payment({ plan, offer }) {
  const { name } = plan
  const { offer_price, upgrade_discount } = offer
  const total = offer_price - upgrade_discount

  return (
    <>
      <Wrapper variant="outlined">
        <ContentHeader>
          <TextBold variant="body1">Makefunnels {name}</TextBold>
        </ContentHeader>
        <Content borderBottom>
          <TextBold>Preço</TextBold>
          <TextBold>R$ {normalizePrice(offer_price)}</TextBold>
        </Content>
        <Content borderBottom>
          <Credit>Credito atual</Credit>
          <Credit>-R$ {normalizePrice(upgrade_discount)}</Credit>
        </Content>
        <Content>
          <TextBold>Total</TextBold>
          <TextBold>R$ {normalizePrice(total)}</TextBold>
        </Content>
      </Wrapper>
    </>
  )
}

export default Payment
