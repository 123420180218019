import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import React from 'react'

import CardButton from '../../../components/CardButton'

export default function CardFunnel({ title, subtitle, Icon, selected }) {
  return (
    <>
      <CardButton
        content={
          <>
            <Icon fontSize="large" />
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            <Typography color="textSecondary">{subtitle}</Typography>
          </>
        }
        selected={selected}
      />
      {selected && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <ArrowDownwardIcon htmlColor="#aaa" />
        </Box>
      )}
    </>
  )
}
