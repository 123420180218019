/* eslint-disable react/prop-types */
import { Box, Button, ButtonGroup, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { Skeleton } from '@material-ui/lab'
import React, { useState } from 'react'
import styled from 'styled-components'

import TabsGroup from '../Tabs'

const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}
const ITEMS = [
  { id: 0, name: DEVICES.DESKTOP, icon: <DesktopMacIcon /> },
  { id: 1, name: DEVICES.MOBILE, icon: <PhoneIphoneIcon /> },
]

const IFrame = ({ src, open, handleClose, handleChose }) => {
  const [device, setDevice] = useState(DEVICES.DESKTOP)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const width = device === DEVICES.DESKTOP ? '1080' : '415'

  const handleChange = (id) => {
    const device = ITEMS?.find((el) => el.id === id)?.name
    setDevice(device)
  }

  const onClose = () => {
    setIframeLoaded(false)
    handleClose()
  }

  return (
    <DialogStyled open={open} onClose={handleClose}>
      <BoxStyled display="flex">
        <CloseSection display="flex" justifyContent="center" alignItems="center" minWidth={'5rem'}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CloseSection>
        <Box
          flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          pt={2}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box pr={3}>
              <TabsGroup items={ITEMS} onClick={handleChange} />
              {/* <ButtonGroupStyled aria-label="small outlined button group">
              <Button
                size="small"
                variant="outlined"
                color={device === DEVICES.DESKTOP && 'primary'}
                onClick={() => handleChange(DEVICES.DESKTOP)}
              >
                Desktop
              </Button>
              <Button
                size="small"
                variant="outlined"
                color={device === DEVICES.MOBILE && 'primary'}
                onClick={() => handleChange(DEVICES.MOBILE)}
              >
                Mobile
              </Button>
            </ButtonGroupStyled> */}
            </Box>
          </Box>
          <Box>
            <Button size="small" color="primary" variant="contained" onClick={handleChose}>
              Escolher
            </Button>
          </Box>
        </Box>
      </BoxStyled>
      <DialogContent>
        {!iframeLoaded && <Skeleton variant="rect" width={1080} height={768} />}
        <iframe
          hidden={!iframeLoaded}
          src={src}
          width={width}
          height={768}
          onLoad={() => setIframeLoaded(true)}
        />
      </DialogContent>
    </DialogStyled>
  )
}

const DialogStyled = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1200px;
    min-width: 1200px;
  }
  & .MuiDialogContent-root {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #f0f0f0;
    padding: 25px 25px 0px 25px;
  }
  iframe {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-width: 0;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
  }
`
const BoxStyled = styled(Box)`
  z-index: 999;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
`
const CloseSection = styled(Box)`
  border-right: 1px solid #dee2e6;
`
const ButtonGroupStyled = styled(ButtonGroup)`
  max-height: 26px;
`

export default IFrame
