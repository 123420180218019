const { makeStyles } = require('@material-ui/core')
const { default: colors } = require('~/utils/colors')

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  root: {
    padding: '0px 24px 12px',
    width: '100%',
    '&.disabled': {
      filter: 'blur(4px)',
    },
  },
  table: {
    borderRadius: '5px !important',
    marginTop: 20,
    border: `4px solid ${colors.white60}`,
    color: colors.dark80,
    fontFamily: 'Nunito Sans',
  },
  header: {
    borderBottom: `2px solid ${colors.white60}`,
    background: colors.white60,
    fontWeight: 600,
    padding: '10px 0px',
    display: 'flex',
    '& .item': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20%',
    },
  },
  info: {
    display: 'flex',
    // justifyContent: 'space-around',
    borderBottom: `1px solid ${colors.white60}`,
    '& .item': {
      width: '20%',
      fontWeight: 400,
      padding: '15px 0px',
    },
  },
}))
