import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { REGISTERED_PAGES } from '~/constants'
import { useAppState } from '~/context'
import useRegisterLastPath from '~/hooks/useRegisterLastPath'
import * as localStorage from '~/utils/localStorage'

import View from './View'

export default function DrawerList() {
  const [selected, setSelected] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const history = useHistory()
  const { user } = useAppState()
  const { registerLastPath } = useRegisterLastPath()
  // const mounted = React.useRef()
  const {
    location: { pathname },
  } = history

  useEffect(() => {
    if (!user) return

    const lastPath = localStorage.get('lastPath')
    if (lastPath) {
      const { index, url } = JSON.parse(localStorage.get('lastPath'))
      const currentPath = history.location.pathname
      if (currentPath !== url) {
        const getKey = Object.keys(REGISTERED_PAGES).find(
          (key) => REGISTERED_PAGES[key]?.url === currentPath,
        )
        localStorage.save(
          'lastPath',
          JSON.stringify({ index: REGISTERED_PAGES[getKey]?.index, url: currentPath }),
        )
        return setSelected(REGISTERED_PAGES[getKey]?.index)
      }
      setSelected(index)
    } else {
      localStorage.save('lastPath', JSON.stringify({ index: 0, url: '/' }))
      history.replace('/')
    }
  }, [history, pathname, user])

  function handleClick(...args) {
    const [index] = args

    registerLastPath(...args)
    setSelected(index)
  }

  function handleClose() {
    setOpen(false)
  }

  return <View action={{ handleClick, handleClose, setOpen }} state={{ selected, open }} />
}
