import { makeStyles } from '@material-ui/core'

import colors from '~/utils/colors'

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  folder: {
    background: '#EDF1FF',
    borderRadius: 8,
    width: 53,
    height: 50,
    textAlign: 'center',
    alignItems: ' center',
    '& svg': {
      margin: '9px',
      fontSize: '30px',
      color: '#4C6FFF',
    },
    '&.funnel': {
      background: '#FFF1E9',
      marginRight: '40px',
      '& svg': {
        color: '#FF9349',
      },
    },
  },
  domainName: {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '0px',
      color: '#A0A4A8',
    },
    '& .active': {
      color: '#1BC5BD',
    },
    '& .active.protect': {
      color: '#0094FF',
    },
    '& .peding': {
      color: '#F64E60',
    },
    '& .blocked': {
      color: '#FAB348',
    },
  },
  tableContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#FFFFFF',
  },
  boardName: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
