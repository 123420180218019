import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import ReportRoundedIcon from '@material-ui/icons/ReportRounded'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'
import { YOUTUBE_VIDEO } from '~/constants'
import colors from '~/utils/colors'

import Table from './components/Table'

function View({ state: { open, domain }, actions: { handleSubmit, handleClose } }) {
  const domainSynced = domain && domain.status === 'active'

  const buttons = [
    domainSynced
      ? {
          name: 'Entendi!',
          variant: 'contained',
          handleSubmit: handleClose,
        }
      : {
          name: 'Já apontei. Verificar domínio',
          variant: 'contained',
          handleSubmit,
        },
  ]

  const helper = domainSynced
    ? {
        title: 'Nota',
        content: (
          <>
            Boas notícias! Já foi solicitado, de forma automática, a instalação do{' '}
            <b>certificado ssl</b> do seu domínio. O <b>certificado ssl</b> garante o acesso seguro
            via https. Cuidaremos disso para você sem valor adicional algum e será notificado por
            e-mail quando tiver sido instalado.
          </>
        ),
      }
    : {
        title: 'Nota',
        content: (
          <>
            Disponibilizamos um vídeo explicativo para te auxiliar nessa etapa:{' '}
            <a
              target="_blank"
              href={`${YOUTUBE_VIDEO}/watch?v=55W6w37L84c&t=175s`}
              rel="noopener noreferrer"
            >
              aperte aqui para assistir
            </a>
            .
            <br />
            <br />
            Ainda precisa de ajuda? Contate nosso suporte.
          </>
        ),
      }

  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{
        open,
        title: 'Detalhe do domínio',
        buttons,
        helper,
        isLarge: true,
      }}
    >
      {domainSynced ? <Synced /> : <Pending path={domain.name} />}
    </ModalComponent>
  )
}

function Pending({ path }) {
  return (
    <>
      <Box mb={2}>
        <Typography gutterBottom>Status</Typography>
        <Box
          style={{
            color: colors.erro,
            fontFamily: 'Nunito Sans',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ReportRoundedIcon style={{ fontSize: 30, marginRight: 15 }} />
          Sincronização Pendente
        </Box>
      </Box>

      <Typography component="h6" gutterBottom style={{ marginBottom: 10 }}>
        Domínio adicionado, agora é necessário apontar este domínio à MakeFunnels.
      </Typography>

      <Typography component="h6" gutterBottom style={{ marginTop: 16 }}>
        Para isso, é necessário que você adicione um registro
        <span style={{ color: colors.bluePrimary }}> cname </span>
        para <span style={{ color: colors.bluePrimary }}>target.makefunnels.com.br</span> no
        gerenciador DNS do seu domínio.
      </Typography>

      <Table path={path} />
    </>
  )
}

function Synced() {
  return (
    <>
      <Box mb={4}>
        <Typography gutterBottom>Status</Typography>
        <Box
          style={{
            color: colors.success,
            fontFamily: 'Nunito Sans',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <>
            <CheckCircleOutlineRoundedIcon style={{ fontSize: 30, marginRight: 15 }} />
            Sincronizado com Sucesso!
          </>
        </Box>
      </Box>

      <Typography component="h6" gutterBottom>
        Parabéns, seu domínio está pronto para uso!
      </Typography>
      <Typography variant="body2" gutterBottom>
        Agora comece a adicionar seus funis a este domínio. Depois defina uma página padrão para ele
        aqui mesmo nessa sessão.
      </Typography>
    </>
  )
}

Pending.propTypes = {
  path: PropTypes.string,
}
View.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    domain: PropTypes.object,
  }),
  actions: PropTypes.shape({
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
  }),
}

export default View
