import { useEffect } from 'react'

import { ASYNC_STATE, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'

export const useStatusMachine = (status, handleSuccess, handleError) => {
  const dispatchApp = useAppDispatch()

  useEffect(() => {
    switch (status) {
      case ASYNC_STATE.PENDING:
        return dispatchApp({
          type: TOGGLE_LOADING,
          payload: { show: true, background: false },
        })
      case ASYNC_STATE.REJECTED:
        dispatchApp({
          type: TOGGLE_LOADING,
          payload: { show: false, background: false },
        })
        return handleError?.()
      case ASYNC_STATE.RESOLVED:
        dispatchApp({
          type: TOGGLE_LOADING,
          payload: { show: false, background: false },
        })
        return handleSuccess?.()
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])
}
