import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

// import { Container } from './styles';

function Image({ className, content, alt }) {
  if (typeof content === 'object') {
    const ImageComponent = content

    return (
      <Box
        sx={{
          backgroundColor: '#EDF1FF',
          borderRadius: '8px',
          width: '53px',
          height: '50px',
        }}
        justifyContent="center"
        alignItems="center"
        display="flex"
        mr={2}
      >
        <ImageComponent alt="Feature" style={{ fontSize: 30, color: '#4c6fff' }} />
      </Box>
    )
  }

  const fieldProps = { className, src: content, alt }
  return <img {...fieldProps} alt="Feature" height={72} width={85} />
}

Image.propTypes = {
  content:
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.func]) || undefined,
  alt: PropTypes.string,
  className: PropTypes.shape({}),
}

export default Image
