import React, { useCallback, useRef } from 'react'

import { SET_CURRENT_SUBSCRIPTION } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { getCurrentSubscription } from '~/services/subscription'
import { useAsync } from '~/utils/useAsync'

function useLoadPlan() {
  const dispatchApp = useAppDispatch()
  const { user } = useAppState()
  const { data, status, error, run } = useAsync()
  const dispatched = useRef(false)

  const reload = useCallback(() => {
    run(getCurrentSubscription())
  }, [run])

  React.useEffect(() => {
    if (user) {
      if (!dispatched.current) {
        run(getCurrentSubscription())
        dispatched.current = true
      }
    } else {
      dispatched.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  React.useEffect(() => {
    dispatchApp({
      type: SET_CURRENT_SUBSCRIPTION,
      payload: { plan: data || { plan: {} }, status, error, reload },
    })
  }, [data, dispatchApp, error, reload, status])
}

export { useLoadPlan }
