import React, { useState } from 'react'

import { useAppState } from '~/context'
import { useFetchDomains } from '~/hooks/useFetchDomains'
import { useIsMobile } from '~/hooks/useIsMobile'
import { Domain } from '~/types'
import { filterByStatus } from '~/utils/domainFilter'

import { ImportSetupDrawer } from './ImportSetupDrawer'
import { ImportSetupModal } from './ImportSetupModal'

interface ImportSetupProps {
  isOpen: boolean
  handleClose: () => void
  handleSubmit: ({ name, domain }: { name: string; domain: number }) => void
}

export function ImportSetup({ isOpen, handleClose, handleSubmit }: ImportSetupProps) {
  useFetchDomains()
  const { domains } = useAppState()
  const domainList: Domain[] = filterByStatus(domains.data, 'active')
  const { isMobile } = useIsMobile()

  const [formState, setFormState] = useState({
    name: {
      value: '',
      error: false,
    },
    domain: {
      value: 0,
      error: false,
    },
  })

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value, name = '' } = e.target
    setFormState((prevValues) => ({
      ...prevValues,
      [name]: {
        ...prevValues[name as 'name' | 'domain'],
        value,
      },
    }))
  }

  const validateHandleSubmit = (props: { name: string; domain: number }) => {
    let hasError = false

    const setError = (key: keyof typeof formState, value: boolean) =>
      setFormState((prevformState) => ({
        ...prevformState,
        [key]: {
          ...prevformState[key],
          error: value,
        },
      }))

    if (!formState.name.value) {
      hasError = true
      setError('name', true)
    } else {
      setError('name', false)
    }

    if (formState.domain.value === 0) {
      hasError = true
      setError('domain', true)
    } else {
      setError('domain', false)
    }

    if (!hasError) return handleSubmit({ ...props })
  }

  const RenderComponent = isMobile ? ImportSetupDrawer : ImportSetupModal

  return (
    <RenderComponent
      isOpen={isOpen}
      domainList={domainList}
      formState={formState}
      handleChange={handleChange}
      handleClose={handleClose}
      handleSubmit={validateHandleSubmit}
    />
  )
}
