import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 2rem;
`

export const HeadLine = () => (
  <StyledTypography>O login é necessário para a importação desse funil</StyledTypography>
)
