import CANVAS_EVENT_ADS from '~/assets/images/canvas_event_ads.svg'
import CANVAS_EVENT_BUY from '~/assets/images/canvas_event_buy.svg'
import CANVAS_EVENT_CHECKOUT from '~/assets/images/canvas_event_checkout.svg'
import CANVAS_EVENT_EMAIL from '~/assets/images/canvas_event_email.svg'
import CANVAS_EVENT_FACEBOOK from '~/assets/images/canvas_event_facebook.svg'
import CANVAS_EVENT_INSTAGRAM from '~/assets/images/canvas_event_instagram.svg'
import CANVAS_EVENT_SPLIT from '~/assets/images/canvas_event_split.svg'
import CANVAS_EVENT_TELEGRAM from '~/assets/images/canvas_event_telegram.svg'
import CANVAS_EVENT_TIKTOK from '~/assets/images/canvas_event_tiktok.svg'
import CANVAS_EVENT_WPP from '~/assets/images/canvas_event_wpp.svg'
import CANVAS_EVENT_YOUTUBE from '~/assets/images/canvas_event_youtube.svg'

export const eventTypes = [
  // Element Disabled
  // {
  //   id: 'split',
  //   name: 'Split',
  //   type: 'event',
  //   src: CANVAS_EVENT_SPLIT,
  // },
  {
    id: 'buy',
    name: 'Compra',
    type: 'event',
    src: CANVAS_EVENT_BUY,
  },
  {
    id: 'checkout',
    name: 'Carrinho',
    type: 'event',
    src: CANVAS_EVENT_CHECKOUT,
  },
  {
    id: 'email',
    name: 'Email',
    type: 'event',
    src: CANVAS_EVENT_EMAIL,
  },
  {
    id: 'ads',
    name: 'Ads',
    type: 'event',
    src: CANVAS_EVENT_ADS,
  },
  {
    id: 'facebook',
    name: 'Facebook',
    type: 'event',
    src: CANVAS_EVENT_FACEBOOK,
  },
  {
    id: 'instagram',
    name: 'Instagram',
    type: 'event',
    src: CANVAS_EVENT_INSTAGRAM,
  },
  {
    id: 'telegram',
    name: 'Telegram',
    type: 'event',
    src: CANVAS_EVENT_TELEGRAM,
  },
  {
    id: 'tiktok',
    name: 'Tiktok',
    type: 'event',
    src: CANVAS_EVENT_TIKTOK,
  },
  {
    id: 'wpp',
    name: 'WhatsApp',
    type: 'event',
    src: CANVAS_EVENT_WPP,
  },
  {
    id: 'youtube',
    name: 'Youtube',
    type: 'event',
    src: CANVAS_EVENT_YOUTUBE,
  },
]
