import CANVAS_PAGE_DOWNSELL from '~/assets/images/canvas_page_downsell.svg'
import CANVAS_PAGE_LEAD from '~/assets/images/canvas_page_lead.svg'
import CANVAS_PAGE_OTHERS from '~/assets/images/canvas_page_others.svg'
import CANVAS_PAGE_SALES from '~/assets/images/canvas_page_sales.svg'
import CANVAS_PAGE_THANKS from '~/assets/images/canvas_page_thanks.svg'
import CANVAS_PAGE_UPSELL from '~/assets/images/canvas_page_upsell.svg'

export const pageTypes = [
  {
    id: 'download',
    name: 'Downsell',
    type: 'page',
    src: CANVAS_PAGE_DOWNSELL,
  },
  {
    id: 'sales',
    name: 'Vendas',
    type: 'page',
    src: CANVAS_PAGE_SALES,
  },
  {
    id: 'others',
    name: 'Obrigado',
    type: 'page',
    src: CANVAS_PAGE_THANKS,
  },
  {
    id: 'lead',
    name: 'Captura',
    type: 'page',
    src: CANVAS_PAGE_LEAD,
  },
  {
    id: 'thanks',
    name: 'Upsell',
    type: 'page',
    src: CANVAS_PAGE_UPSELL,
  },
  {
    id: 'upsell',
    name: 'Diversa',
    type: 'page',
    src: CANVAS_PAGE_OTHERS,
  },
]
