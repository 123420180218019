import { useEffect, useState } from 'react'

const useLoadImage = ({ src }) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    const image = new window.Image()
    image.src = src
    image.onload = () => {
      // setState will redraw layer
      // because "image" property is changed
      setImage(image)
    }
  }, [src])
  return image
}

export default useLoadImage
