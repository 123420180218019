import { Box } from '@material-ui/core'
import React from 'react'

const optionItem = (icon, label, handleClick) => (
  <Box
    onClick={handleClick}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <Box
      style={{
        display: 'flex',
        width: 38,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={icon} alt="" />
    </Box>
    {label}
  </Box>
)

export default optionItem
