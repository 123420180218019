import React, { useEffect, useRef } from 'react'

import { useAppDispatch, useAppState } from '~/context'
import DefaultDomain from '~/pages/Domains/components/DefaultDomain/'
import * as domainService from '~/services/domainsService'

import DashboardView from './DashboardView'

export default function ClippedDrawer(props) {
  const dispatchApp = useAppDispatch()
  const { user } = useAppState()

  useEffect(() => {
    const fetchDomains = async () => {
      await domainService.FetchDomains(dispatchApp)
    }
    if (user) {
      fetchDomains()
    }
  }, [dispatchApp, user])

  if (user && !user.base_domain_id) return <DefaultDomain />
  return <DashboardView {...props} />
}
