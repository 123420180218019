import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import TextareaAutosizeBase from '@material-ui/core/TextareaAutosize'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

import { Text } from '../../styles'

const BlueRadio = withStyles({
  root: {
    '&.Mui-checked': {
      color: colors.bluePrimary,
    },
  },
})(Radio)

const TextareaAutosize = withStyles({
  root: {
    color: 'red',
    width: '100%',
  },
  textarea: {
    background: 'red',
  },
})(TextareaAutosizeBase)

const RenderItem = React.memo(function _RenderItem({ item, showTextArea, textAreaRef }) {
  return (
    <>
      <FormControlLabel value={String(item.id)} control={<BlueRadio />} label={item.reason} />
      {Boolean(item.feedback_required) && showTextArea && (
        <Box mt={1}>
          <Text variant="body2">{item.description}</Text>
          <TextareaAutosize
            ref={textAreaRef}
            required
            style={{
              height: '120px',
              width: '100%',
              resize: 'none',
              overflow: 'hidden',
              borderRadius: '5px',
              borderColor: 'lightgray',
              padding: '8px',
              fontFamily: 'Nunito sans',
            }}
          />
        </Box>
      )}
    </>
  )
})

function RadioButtonGroupComponent({ value, items, loading, handleChange }, ref) {
  if (loading) return <h3>'Loading...'</h3>

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="cancelReason"
          name="cancelReason"
          value={value}
          onChange={handleChange}
        >
          {items.map((item) => (
            <RenderItem
              key={item.id}
              item={item}
              showTextArea={parseInt(value) === item.id}
              textAreaRef={ref}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  )
}

const RadioButtonsGroup = React.forwardRef(RadioButtonGroupComponent)

RadioButtonsGroup.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
}

export default RadioButtonsGroup
