import {
  Avatar,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import logout from '~/assets/images/logout_icon.svg'
import user from '~/assets/images/user_icon.svg'

const StyledUserButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding-right: 0px;
  span {
    margin-right: 6px;
  }

  @media (min-width: 768px) {
    span {
      margin-right: 12px;
    }
    padding-right: inherit;
  }
`

const useStyles = makeStyles(() => ({
  MenuArrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginLeft: 12,
    color: '#A0A4A8',
    border: '2px solid #EDF0F7',
    borderRadius: 50,
  },
  popper: {
    position: 'relative',
  },
  MenuItem: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'left',
    '& img': {
      marginRight: '10px',
    },
  },
}))

function MenuProfileView({ handleClick, anchorEl, handleClose, handleLogOut, userName }) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <>
      <StyledUserButton onClick={handleClick}>
        <span>
          Olá, &nbsp;<b>{userName}</b>
        </span>
        <Avatar alt={userName} src="" />
        <div className={classes.MenuArrow}>
          {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      </StyledUserButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom right',
              position: 'absolute',
              right: '-110px',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="simple-menu" open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem
                    className={classes.MenuItem}
                    onClick={() => {
                      history.push('/account')
                      handleClose()
                    }}
                    disabled
                  >
                    <img src={user} alt="user" /> Minha Conta
                  </MenuItem>
                  <MenuItem className={classes.MenuItem} onClick={handleLogOut}>
                    <img src={logout} alt="logout" /> Log out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

MenuProfileView.propTypes = {
  handleClick: PropTypes.func,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  handleLogOut: PropTypes.func,
  userName: PropTypes.string,
}

export default MenuProfileView
