import { useEffect, useState } from 'react'

import { TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'

export function useLoading() {
  const dispatchApp = useAppDispatch()
  const [on, setOn] = useState(false)

  useEffect(() => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: on, background: true },
    })
  }, [dispatchApp, on])

  return {
    isLoading: setOn,
  }
}
