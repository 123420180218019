import { Button as ButtonCore, Typography } from '@material-ui/core'
import CheckBase from '@material-ui/icons/Check'
import styled from 'styled-components'

import { ButtonBase, DefaultBox, DefaultTitle } from '~/pages/Layout/DefaultLayout'
import colors from '~/utils/colors'

export const Card = styled(DefaultBox)`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 12rem;
  }
`

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    margin-right: 8px;
  }
`

export const ContentTitle = styled.div`
  text-align: left;
`
export const Price = styled(Typography)`
  &&& {
    color: gray;
    font-weight: bold;
  }
`

export const SubTitle = styled(Typography)`
  color: gray;
`

export const Title = styled(DefaultTitle)`
  &&& {
    padding-bottom: 0.5rem;
  }
`

export const Button = styled(ButtonBase)`
  &&& {
    background-color: ${colors.bluePrimary};
    text-transform: initial !important;
    color: white;
    white-space: nowrap;
    & span {
      text-transform: 'initial !important';
    }
  }
`
export const CurrentButton = styled(ButtonCore)`
  &&& {
    text-transform: initial !important;
    & span {
      text-transform: 'initial !important';
    }
  }
`

export const DowngradeButton = styled(ButtonCore)`
  &&& {
    text-transform: initial !important;
    color: ${colors.bluePrimary};
    & span {
      text-transform: 'initial !important';
    }
  }
`
