import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as TooltipIcon } from '~/assets/images/tooltip.svg'
import { Header } from '~/ui-box/header'
import colors from '~/utils/colors'

const StyledContainer = styled(Container)`
  top: 65px;
  position: relative;
  height: calc(100vh - 65px);
`
const StyledTooltipIcon = styled(TooltipIcon)`
  path {
    text-align: center;
    color: ${colors.orange};
    stroke: ${colors.orange};
  }
`

export function MobileUnavailable() {
  return (
    <>
      <Header showProfile={false} />
      <StyledContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Box mb={3}>
            <StyledTooltipIcon width={'4rem'} height={'4rem'} color="red" />
          </Box>
          <Box mb={3}>
            <Typography align="center" variant="h6">
              Lamentamos, mas a versão mobile não está disponível no momento.
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography align="center" variant="subtitle1">
              Nossa equipe está trabalhando constantemente para melhorar sua experiência, e pedimos
              desculpas por qualquer inconveniente causado. Neste momento, a versão mobile não está
              acessível.
            </Typography>
          </Box>
          <Typography align="center" variant="body1">
            Você pode acessar a partir de um computador desktop ou laptop para obter a experiência
            completa.
          </Typography>
        </Box>
      </StyledContainer>
    </>
  )
}
