import React from 'react'
import { useParams } from 'react-router-dom'

import { BoardProvider } from './context/BoardProvider'
import Board from './modules/Board'

const CanvasDraw = () => {
  const { boardId } = useParams()

  return (
    <BoardProvider boardId={boardId}>
      <Board />
    </BoardProvider>
  )
}

export default CanvasDraw
