import AppBarBase from '@material-ui/core/AppBar'
import styled from 'styled-components'

import { device } from '~/utils/devices'

import { DefaultTitle, SubtitleBase } from '../Layout/DefaultLayout'

export const AppBar = styled(AppBarBase)`
  &&& {
    z-index: 9999;
    background-color: white;
    color: #909090;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`

export const Image = styled.img`
  width: 192px;
  height: 30px;
`

export const Brand = styled.a`
  color: white;
  text-align: center;
  font-family: Montserrat;
`

export const Root = styled.div`
  height: 100vh;
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 10rem;
`

export const Content = styled.div`
  display: flex;
  padding-bottom: 84px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media ${device.laptop} {
    padding-bottom: 0;
  }
`

export const PageTitle = styled(DefaultTitle)`
  &&& {
    font-size: 4rem;
    text-align: center;
    padding-bottom: 1rem;
    font-weight: 100;
    & span {
      color: #52575c7d;
    }
  }
`

export const PageSubtitle = styled(SubtitleBase)`
  &&& {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 100;
    color: #52575c7d;
  }
`

export const PlanWrapper = styled.div`
  width: 80%;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.tablet} {
    width: 43%;
  }

  @media ${device.laptop} {
    margin-top: 0rem;
    width: 23%;
  }
`
