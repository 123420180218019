import '@fortawesome/fontawesome-free/css/all.css'

import Icon from '@material-ui/core/Icon'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import EmailIcon from '@material-ui/icons/Email'
import MenuIcon from '@material-ui/icons/Menu'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import TimerIcon from '@material-ui/icons/Timer'
import React from 'react'

import * as constants from '~/constants'

const categoriesIcons = {
  [constants.ALL_PAGES]: <MenuBookIcon />,
  [constants.SALES_PAGE]: <AttachMoneyIcon />,
  [constants.LEAD_PAGE]: <EmailIcon />,
  [constants.THANKS_PAGE]: <CheckBoxIcon />,
  [constants.UP_SELL_PAGE]: <ArrowUpwardIcon />,
  [constants.DOWN_SELL_PAGE]: <ArrowDownwardIcon />,
  [constants.LAUNCH_PAGE]: <Icon className="fas fa-rocket" />,
  [constants.DIVERSE_PAGE]: <MenuIcon />,
  [constants.OTO]: <TimerIcon />,
}

export const getIcon = (category) => {
  return categoriesIcons[category]
}
