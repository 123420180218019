import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import 'fontsource-montserrat/300.css'
import 'fontsource-montserrat/400.css'
import 'fontsource-montserrat/600.css'

import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Box, Container, createTheme, Grid, ThemeProvider, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { useModal } from '~/components/Modal'
import { ASYNC_STATE } from '~/constants'
import { useAppState } from '~/context'
import { useLogin } from '~/hooks/useLogin'
import { LoginDialog } from '~/modules/login-dialog'
import { Button } from '~/ui-box'
import { Header } from '~/ui-box/header'
import colors from '~/utils/colors'
import LoadingPage from '~/utils/components/LoadingPage'

import { Footer } from './components/Footer'
import { HeadLine } from './components/HeadLine'
import { ImportSetup } from './components/ImportSetup'
import { SliderPanel } from './components/SliderPanel'
import { useSharedFunnel } from './hooks/useSharedFunnel'

const theme = createTheme({
  palette: {
    primary: { ...green, contrastText: '#fff' },
  },
})

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fff;
  }
  * {
    font-family: 'Montserrat' !important;
  }
`

const StyledContainer = styled(Container)`
  top: 65px;
  position: relative;
`

const StyledDescription = styled(Box)`
  top: 65px;
  position: relative;
  background-color: #eeeeee;
  padding: 5rem 0;
  border-radius: 60px 60px 0px 0px;
  box-shadow: 0px 20px 18px rgba(0, 0, 0, 0.1);

  .title {
    font-size: 2rem;
    font-family: 'Montserrat';
    text-align: center;
    line-height: 45px;
    @media (min-width: 431px) {
      text-align: left;
    }
  }
  .description {
    font-family: 'Inter';
    line-height: 30px;
    font-size: 1.25rem;
    font-weight: 300;
  }
`

const StyledTitle = styled(Typography)`
  &&& {
    font-size: 30px;
    color: inherit;
    line-height: 48px;
    color: ${colors.black100};
    text-wrap: balance;

    @media (min-width: 431px) {
      text-wrap: pretty;
      font-size: 40px;
    }
  }
`

const StyledSubTitle = styled(Typography)`
  &&& {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: normal;
    color: ${colors.black100};
    font-weight: 300;
    margin-bottom: 1.9rem;

    @media (min-width: 431px) {
      font-size: 25px;
    }
  }
`

const TypographyBold = styled.b`
  &&& {
    color: ${colors.pink};
  }
`

const StyledDotLottieReact = styled(DotLottieReact)`
  overflow: hidden;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1), 0 7px 7px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  transform: rotate(3deg);
`
const StyledCTAButton = styled(Button)`
  &&& {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.5;
    min-width: 16rem;
  }
`

export function ShareTemplate() {
  const history = useHistory()
  const { user } = useAppState()
  const { open: importSetupIsOpen, toggle: importSetupToggle } = useModal()
  const { open: loginDialogIsOpen, toggle: loginDialogToggle } = useModal()
  const { funnelId } = useParams<{ funnelId: string }>()
  const { data: funnel, error, status, importFunnel } = useSharedFunnel(funnelId)
  const { sigIn } = useLogin()

  if (error || status === ASYNC_STATE.REJECTED) {
    return history.push(`/`)
  }

  if (status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE) return <LoadingPage />

  const { pages, name } = funnel || {}

  function handleImport() {
    if (user) {
      return importSetupToggle()
    }
    return loginDialogToggle()
  }

  async function handleLogin({ email, password }: { email: string; password: string }) {
    sigIn({
      email,
      password,
    }).then((res) => {
      if (res === 'success') return loginDialogToggle()
    })
  }

  return (
    <>
      <GlobalStyle />
      <Header />
      <Box mt={5}>
        <StyledContainer maxWidth="md">
          <StyledSubTitle align="center" variant="subtitle1">
            A <TypographyBold>Makefunnels</TypographyBold> torna tudo mais rápido e eficiente para
            fazer seu negócio crescer
          </StyledSubTitle>
          <StyledTitle align="center" variant="h1">
            Importe <TypographyBold>{name}</TypographyBold> para sua conta automaticamente:
          </StyledTitle>
          <Box my={5}>
            <SliderPanel pages={pages} />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <ThemeProvider theme={theme}>
              <StyledCTAButton
                layout="containedDark"
                size="large"
                disableElevation
                onClick={handleImport}
              >
                Importar Funil
              </StyledCTAButton>
            </ThemeProvider>
          </Box>
        </StyledContainer>
        <StyledDescription>
          <Container maxWidth="md">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <Typography variant="h5" className="title">
                    Você poderá editar tudo dessas páginas, da forma como preferir!
                  </Typography>
                </Box>
                <Typography align="left" variant="body1" className="description">
                  Edite facilmente os elementos das páginas, personalize-os como quiser e os arraste
                  para onde preferir. Adicione textos, imagens, vídeos, listas, botões, contadores,
                  pop-ups e muito mais com a MakeFunnels!
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledDotLottieReact
                  src="https://lottie.host/6cee1b11-2235-44af-a13a-7222137feb67/PlZaGBiCl6.lottie"
                  style={{ maxWidth: '100%', maxHeight: 'auto' }}
                  // mode="normal"
                  loop
                  autoplay
                  speed={1}
                  autoResizeCanvas={false}
                />
              </Grid>
            </Grid>
          </Container>
        </StyledDescription>
        <Container maxWidth="md">
          <Box mt={20}>
            <Footer />
          </Box>
        </Container>
      </Box>
      <ImportSetup
        isOpen={importSetupIsOpen}
        handleClose={importSetupToggle}
        handleSubmit={importFunnel}
      />
      <LoginDialog
        isOpen={loginDialogIsOpen}
        handleClose={loginDialogToggle}
        handleSubmit={handleLogin}
        HeadLine={HeadLine}
      />
    </>
  )
}
