import { Typography } from '@material-ui/core'
import AppBarBase from '@material-ui/core/AppBar'
import { Link as LinkBase } from 'react-router-dom'
import styled from 'styled-components'

import { DefaultTitle } from '../Layout/DefaultLayout'

export const Link = styled(LinkBase)`
  &&& {
    color: #4c6fff;
  }
`

export const AppBar = styled(AppBarBase)`
  &&& {
    z-index: 9999;
    background-color: white;
    color: #909090;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`

export const Image = styled.img`
  width: 192px;
  height: 30px;
`

export const Brand = styled.a`
  color: white;
  text-align: center;
  font-family: Montserrat;
`

export const Root = styled.div`
  display: flex;
  background-color: #f5f7ff;
  height: 100vh;
  width: 100wh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 450px;
`

export const ContentTitle = styled.div`
  text-align: left;
`

export const Title = styled(DefaultTitle)`
  &&& {
    padding-bottom: 0.5rem;
  }
`

export const Text = styled(Typography)`
  color: gray;
`
