import PropTypes from 'prop-types'
import React from 'react'
// import {  } from "./constants"

const RedirectionContext = React.createContext()

const RedirectionReducer = (state, action) => ({
  ...state,
  ...(typeof action === 'function' ? action(state) : action),
})

function RedirectionProvider({ children }) {
  const initialState = {
    file: null,
    urls: [
      {
        url: '',
        visits_limit: 350,
        order: 1,
      },
    ],
    visits_per_link: 350,
    metrics: {
      visits_total: null,
      visits_limit: null,
      visits_reenter: null,
      visits_unique: null,
      current_link: -1,
    },
  }
  const [state, dispatch] = React.useReducer(RedirectionReducer, initialState)
  return (
    <RedirectionContext.Provider
      value={{
        state,
        setState: dispatch,
      }}
    >
      {children}
    </RedirectionContext.Provider>
  )
}

function useRedirectionContext() {
  const context = React.useContext(RedirectionContext)
  if (context === undefined) {
    throw new Error('useRedirectionState must be used within a RedirectionProvider')
  }
  return context
}
RedirectionProvider.propTypes = {
  children: PropTypes.node,
}

export { RedirectionProvider, useRedirectionContext }
