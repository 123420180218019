import { AppBar, Toolbar } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import makefunnelsLogo from '~/assets/images/logo-makefunnels-short-2.svg'
import { useAppState } from '~/context'
import { MenuProfile } from '~/utils/components'

const StyledAppBar = styled(AppBar)`
  background-color: #fff;
  box-shadow: none;
`
const StyledImg = styled.img`
  max-width: 190px;
`
const ImgWrapper = styled.div`
  flex-grow: 0.7;
  margin-right: auto;

  @media (min-width: 768px) {
    flex-grow: 1;
  }
`

interface HeaderProps {
  showProfile?: boolean
}

export function Header({ showProfile = true }: HeaderProps) {
  const { user } = useAppState()

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <ImgWrapper>
          <StyledImg src={makefunnelsLogo} alt="makefunnels logo" />
        </ImgWrapper>
        {showProfile && user && <MenuProfile />}
      </Toolbar>
    </StyledAppBar>
  )
}
