import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
// import { Container } from './styles';

function Loading() {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

export default Loading
