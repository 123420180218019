import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

import funnelTemplates from '~/assets/images/funnel_templates.svg'
import zeroFunnel from '~/assets/images/zero_funnel.svg'
import colors from '~/utils/colors'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: '1px solid #E5E9F5',
    '& p': {
      textAlign: 'left',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 80px',
    height: '100%',
  },
  selectType: {
    border: '2px solid #E5E9F5',
    borderRadius: '20px',
    padding: theme.spacing(4),
    display: 'flex',
    flex: 0.48,
    flexDirection: 'column',
    alignItems: 'center',

    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,

    '&.disabled': {
      '& button, & button:hover': {
        background: '#E5E9F5',
        color: '#fff',
        boxShadow: 'none',
        pointerEvents: 'none',
      },
    },

    '& button, & button:hover': {
      background: colors.button.main,
      color: '#fff',
      borderRadius: colors.button.radius,
    },

    '& button, & h4': {
      marginTop: '20px',
    },

    '&:hover': {
      border: '2px solid #4C6FFF',
    },
    '&.disabled:hover': {
      border: '2px solid #E5E9F5',
    },
  },
}))

export default function ModalComponent({ open, handleClose, openEditor, openTemplate }) {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography component="p">Sua página está totalmente vazia</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={`${classes.selectType}`}>
          <img src={funnelTemplates} alt="" />
          <Typography variant="h4">Modelo de página pronta</Typography>
          <Button variant="contained" onClick={openTemplate} autoFocus>
            Selecionar
          </Button>
        </Box>
        <Box className={classes.selectType}>
          <img src={zeroFunnel} alt="" />
          <Typography variant="h4">Criar minha página do zero</Typography>
          <Button variant="contained" onClick={openEditor} autoFocus>
            Selecionar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

ModalComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  openEditor: PropTypes.func,
  openTemplate: PropTypes.func,
}

ModalComponent.defaultProps = {
  open: false,
  handleClose: () => {},
}
