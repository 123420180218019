import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { DEFAULT_MAKEFUNNELS_FAVICON, SET_ALERT, SET_FUNNEL } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as funnelService from '~/services/funnelService'

import View from './View'

function Favicon({ path, updatedAt }) {
  const { id } = useParams()
  const appDispatch = useAppDispatch()
  const {
    funnel: { data: funnel },
  } = useAppState()

  const [img, setImg] = useState({
    src: DEFAULT_MAKEFUNNELS_FAVICON,
    name: 'makefunnels-logo.png',
    updatedAt: new Date().toISOString(),
  })

  useEffect(() => {
    setImg({
      src: path || DEFAULT_MAKEFUNNELS_FAVICON,
      name: path ? path.slice(path.lastIndexOf('/') + 1) : 'makefunnels-logo.png',
      updatedAt: new Date(updatedAt).toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
      }),
    })
  }, [path, updatedAt])

  const handleFile = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (file.size < 1024000) {
        setImg({
          name: file.name,
          src: URL.createObjectURL(file),
          updatedAt: new Date().toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }),
        })

        saveFavicon(file)
      } else {
        appDispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Tamanho da imagem deve ser inferior a 1mb',
            variant: 'error',
          },
        })
      }
    }
  }

  const resetFile = () => {
    const target = document.getElementById('favicon')
    target.value = ''
    setImg({
      src: DEFAULT_MAKEFUNNELS_FAVICON,
      name: 'makefunnels-logo.png',
      updatedAt: new Date().toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
      }),
    })

    saveFavicon('')
  }

  const saveFavicon = async (file) => {
    try {
      const formData = new FormData(this)
      formData.append('favicon', file)

      const response = await funnelService.setFavicon(id, formData)
      appDispatch({
        type: SET_FUNNEL,
        payload: {
          data: {
            ...funnel,
            favicon_path: response?.data?.data?.favicon_path,
            updated_at: response?.data?.data?.updated_at,
          },
        },
      })
      appDispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Favicon salvo!',
          variant: 'success',
        },
      })
    } catch (error) {
      appDispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um problema. Tenta por favor mais tarde!',
          variant: 'error',
        },
      })
    }
  }

  return <View state={{ img }} actions={{ resetFile, handleFile, saveFavicon }} />
}

Favicon.propTypes = {
  path: PropTypes.string,
  updatedAt: PropTypes.string,
}

export default Favicon
