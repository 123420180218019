/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'

import { SET_HEADER_TITLE } from '~/constants'
import { useAppDispatch } from '~/context'

const HeaderTitleHOC = (WrappedComponent, pageTitle) => (props) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch({ type: SET_HEADER_TITLE, payload: pageTitle })
  }, [dispatch])
  // eslint-disable-next-line react/display-name
  return <WrappedComponent {...props} />
}

export default HeaderTitleHOC
