import './overwrite.css'

import { Box, Grid } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import React from 'react'

import makefunnelsLogo from '~/assets/images/makefunnels-logo.svg'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { ASYNC_STATE } from '~/constants'
import { useAppState } from '~/context'
import LoadingPage from '~/utils/components/LoadingPage'

import { PlanItem } from './components/PlanItem'
import { useLoadPlans } from './hooks/useLoadPlans'
import { AppBar, Brand, Content, Image, PageSubtitle, PageTitle, PlanWrapper, Root } from './styles'

const Downgrade = () => {
  const { currentSubscription } = useAppState()
  const { data: plans, error: requestError, status } = useLoadPlans()
  const loading = status !== ASYNC_STATE.RESOLVED && status !== ASYNC_STATE.REJECTED
  const {
    plan: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      plan: { code, can_upgrade_to_plans },
    },
  } = currentSubscription
  // eslint-disable-next-line @typescript-eslint/camelcase
  const canUpgradeToPlan = (can_upgrade_to_plans?.length && can_upgrade_to_plans) || null
  const error = requestError || (plans && !plans.length)

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Brand href="/">
            <Image src={makefunnelsLogo} alt="makefunnels logo" />
          </Brand>
        </Toolbar>
      </AppBar>
      <Root>
        <Content>
          {loading || !code ? (
            <LoadingPage />
          ) : (
            <ErrorBoundary error={error}>
              <Grid container justifyContent="space-around">
                <Box width="100%">
                  <PageTitle>
                    Makefunnels <span>Planos</span>
                  </PageTitle>
                  <PageSubtitle>Descubra qual é o melhor preço para você</PageSubtitle>
                </Box>
                {plans.map((plan, index) => (
                  <PlanWrapper key={`plan_${index}`}>
                    <PlanItem
                      plan={plan}
                      currentCodePlan={code}
                      canUpgradeToPlanList={canUpgradeToPlan}
                    />
                  </PlanWrapper>
                ))}
              </Grid>
            </ErrorBoundary>
          )}
        </Content>
      </Root>
    </>
  )
}

export default Downgrade
