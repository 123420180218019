import { MONTH, MONTHS, YEAR, YEARS } from '~/constants'

export const normalizePeriod = (period: number = 1, interval: string = ''): string => {
  const moreOne = period > 1

  switch (interval) {
    case YEAR:
      return moreOne ? YEARS : YEAR
    case MONTH:
      return moreOne ? MONTHS : MONTH
    default:
      return 'no string provided'
  }
}
