export function destructuringResponseObject(response) {
  try {
    const {
      data: {
        data: { ...values },
      },
    } = response
    return values
  } catch (error) {
    console.log(error)
  }
}

export function destructuringResponseArray(response) {
  const {
    data: {
      data: [...values],
    },
  } = response
  return values
}
