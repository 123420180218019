import { Box, Typography } from '@material-ui/core'
import React, { ElementType } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '~/ui-box'
import { InputField } from '~/ui-box/forms'

const Wrapper = styled(Box)`
  width: 100%;
  flex: 1;
`
const StyledDrawerFooter = styled(Box)``

interface LoginContentProps {
  handleClose: () => void
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  formState: {
    email: {
      value: string
      error: boolean
    }
    password: {
      value: string
      error: boolean
    }
  }
  handleSubmit: ({ email, password }: { email: string; password: string }) => void
  HeadLine: ElementType
}

export const LoginContent = ({
  handleChange,
  handleSubmit,
  formState,
  HeadLine,
}: LoginContentProps) => {
  const history = useHistory()

  const redirectToSignUp = () => {
    window.location.href = 'https://makefunnels.com.br/planos'
  }

  return (
    <React.Fragment>
      {HeadLine && <HeadLine />}
      <Wrapper
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="100%">
          <Typography gutterBottom variant="h5" align="center">
            Acesse sua conta
          </Typography>
          <Box my={3}>
            {/* <InputLabel htmlFor="funnel-name">Email *</InputLabel> */}
            <InputField
              variant="outlined"
              autoComplete="off"
              placeholder="Email *"
              name="email"
              helperText={formState.email.error && 'Campo obrigatório'}
              value={formState.email.value}
              type="text"
              onChange={handleChange}
              error={formState.email.error}
              fullWidth
            />
          </Box>
          <Box my={3}>
            {/* <InputLabel htmlFor="funnel-name">Email *</InputLabel> */}
            <InputField
              variant="outlined"
              autoComplete="off"
              placeholder="Senha *"
              name="password"
              helperText={formState.password.error && 'Campo obrigatório'}
              value={formState.password.value}
              type="password"
              onChange={handleChange}
              error={formState.password.error}
              fullWidth
            />
            <Button layout="textDark" onClick={() => history.push('/forgotpassword')}>
              Esqueci minha senha
            </Button>
          </Box>
          <StyledDrawerFooter
            display="flex"
            justifyContent="flex-end"
            gridGap="1rem"
            alignItems="center"
            flexDirection="column"
          >
            <Button
              layout="containedDark"
              onClick={() =>
                handleSubmit({ email: formState.email.value, password: formState.password.value })
              }
              fullWidth
            >
              Entrar
            </Button>
          </StyledDrawerFooter>
        </Box>
        <Box>
          <Button layout="textDark" onClick={redirectToSignUp}>
            Crie Uma Conta Agora
          </Button>
        </Box>
      </Wrapper>
    </React.Fragment>
  )
}
