export default {
  mediumGrey: '#f5f7ff',
  blueDark: '#12dasd',
  font: 'Nunito Sans',
  white60: '#E5E9F5',
  white80: '#F5F7FF',
  dark80: '#52575C',
  dark100: '#25282B',
  dark20: '#A0A4A8',
  info: '#0094FF',
  bluePrimary: '#4C6FFF',
  blueGray: '#CFD0E7',
  success: '#1BC5BD',
  danger: '#F6C209',
  erro: '#F64E60',
  orange: '#FF9349',
  pink: '#ff2a87',
  black100: '#212529',
  button: {
    main: '#4C6FFF',

    font: 'Inter',
    border: '2px solid #4C6FFF',
    radius: '8px !important',
  },
}
