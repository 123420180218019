import { useEffect } from 'react'

import { useAppDispatch, useAppState } from '~/context'
import { FetchDomains } from '~/services/domainsService'

export function useFetchDomains() {
  const dispatchApp = useAppDispatch()
  const { user } = useAppState()

  useEffect(() => {
    const fetchDomains = async () => {
      await FetchDomains(dispatchApp)
    }
    if (user) {
      fetchDomains()
    }
  }, [dispatchApp, user])
}
