import { makeStyles } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import DomainsCreateModal from '../components/DomainsCreateModal'
import DomainsDetail from '../DomainsDetail'
import { Table } from './components'

const useRowStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontFamily: 'Nunito Sans',
    '& h6': {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      margin: 0,
    },
  },
  addDomain: {
    borderRadius: '8px !important',
    borderWidth: 2,
    // color: "#4C6FFF",
    color: '#fff',
    borderColor: '#4C6FFF',
    backgroundColor: '#4C6FFF',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    width: 180,
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&:hover': {
      backgroundColor: '#4C6FFF',
      borderColor: '#4C6FFF',
      borderWidth: 2,
    },
  },
  search: {
    width: 400,
    height: 40,
    background: '#FFFFFF',
    borderRadius: 50,
    marginRight: '40px',
    '& fieldset': {
      height: 45,
      borderRadius: 50,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& div': {
      height: 40,
    },
    '& input': {
      padding: '0 40px !important',
    },
    '& svg': {
      color: '#A9ABAE',
    },
  },
})

function CreateDomain() {
  const classes = useRowStyles()
  const [open, setOpen] = useState(false)
  const [domainDetail, setDomainDetail] = useState({ open: false, id: null })

  const handleClose = () => {
    setDomainDetail({ open: false, id: null })
  }

  const handleOpenDetail = (id) => {
    setDomainDetail({ open: true, id, isCreatedDomain: true })
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.addDomain}
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
      >
        Novo Domínio
      </Button>
      <DomainsCreateModal open={open} handleClose={setOpen} handleOpenDetail={handleOpenDetail} />
      <DomainsDetail state={{ ...domainDetail }} action={{ handleClose }} />
    </>
  )
}

function SearchField({ handleSearch, domains }) {
  const classes = useRowStyles()
  const [searchValue, setSearchValue] = useState('')

  return (
    <Box className={classes.search}>
      <TextField
        variant="outlined"
        fullWidth
        id="search"
        name="search"
        placeholder="Pesquisar"
        onChange={(e) => {
          const { value } = e.target

          setSearchValue(value)

          handleSearch(domains, value)
        }}
        value={searchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

function List({ domains }) {
  return domains.length ? (
    <Box marginTop="2rem">
      <Table domains={domains} />
    </Box>
  ) : (
    <Box my={2} textAlign="center" style={{ paddingTop: '80px' }}>
      <Typography variant="h6" gutterBottom>
        Nenhum domínio foi encontrado
      </Typography>
    </Box>
  )
}

function DomainsListView({ domains, isFetching, handleSearch }) {
  const classes = useRowStyles()

  return (
    <Box m={4}>
      <Box mb={4} className={classes.header}>
        <Typography variant="h6" gutterBottom>
          Meus Domínios
        </Typography>
        {!isFetching && domains && (
          <Box display="flex">
            <SearchField handleSearch={handleSearch} domains={domains} />
            <Box>
              <CreateDomain />
            </Box>
          </Box>
        )}
      </Box>
      {!isFetching && domains && <List domains={domains} />}
    </Box>
  )
}

DomainsListView.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
  handleSearch: PropTypes.func,
}

SearchField.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
  handleSearch: PropTypes.func,
}

List.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
}

export default DomainsListView
