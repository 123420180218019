import { SET_FUNNEL, SET_TEMPLATES } from '~/constants'
import * as funnelService from '~/services/funnelService'
import fetch from '~/utils/fetch'
import { destructuringResponseObject } from '~/utils/help'

export function getFunnel(dispatchApp, id) {
  fetch(
    dispatchApp,
    async () => {
      dispatchApp({ type: SET_FUNNEL, payload: { isFetching: true } })
      const response = await funnelService.getFunnel(id)
      const funnel = destructuringResponseObject(response)
      dispatchApp({
        type: SET_FUNNEL,
        payload: { data: funnel, isFetching: false },
      })
    },
    () => dispatchApp({ type: SET_FUNNEL, payload: { isFetching: false } }),
  )
}

export function getTemplates({ dispatchApp, page, limit, pageCategory }) {
  fetch(
    dispatchApp,
    async () => {
      dispatchApp({ type: SET_TEMPLATES, payload: { isFetching: true } })
      funnelService.getTemplates(page, limit, pageCategory).then(({ data }) => {
        dispatchApp({
          type: SET_TEMPLATES,
          payload: { data: data, isFetching: false },
        })
      })
    },
    () => dispatchApp({ type: SET_TEMPLATES, payload: { isFetching: false } }),
  )
}
