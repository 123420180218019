import colors from '~/utils/colors'

const styles = (theme) => ({
  typeIcon: {
    background: '#FFF1E9',
    borderRadius: 8,
    width: 53,
    height: 40,
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    '& svg': {
      color: '#FF9349',
    },
  },
  funnelInfo: {
    background: '#fff',
    minHeight: '64px',
    padding: '0.5rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',

    '&:hover': {
      cursor: 'grab',
    },

    '&:active': {
      cursor: 'grabbing',
    },

    '&:hover .show': {
      display: 'block',
      marginLeft: 'auto',
    },

    fontFamily: 'Nunito Sans',

    '& h6': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      textAlign: 'left',
    },
    '& h6 span': {
      color: '#A0A4A8',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      textAlign: 'left',
    },
    '& .bold': {
      color: '#222',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    '& .truncate': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '30px',
    },
    '& p': {
      color: '#A0A4A8',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  },
  avatar: {
    color: colors.bluePrimary,
    backgroundColor: colors.white60,
    zIndex: 1,
    width: '60px',
    height: '60px',
  },
  number: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .line': {
      position: 'absolute',
      height: '200%',
      border: `1px solid ${colors.blueGray}`,
      borderStyle: 'dashed',
      top: 0,
    },
    '&.arrow .line': {
      display: 'flex',
      justifyContent: 'center',
      height: '122%',
    },
    '&.arrow .line::before': {
      content: '""',
      display: 'inline-block',
      width: 0,
      height: 0,
      border: '8px solid transparent',
      verticalAlign: 'middle',
      borderTopColor: '#CBCBCB',
      position: 'absolute',
      bottom: '-16px',
    },
    '&.last .line': {
      display: 'none',
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 0,
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
      fontSize: '0.7rem',
    },
  },
  cardInfos: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '& > div': {
      minWidth: '7vw',
      marginLeft: '2.3vw',
    },
    '& .editButton': {
      right: '84px',
    },
    '& .editButton, & > button': {
      position: 'absolute',
      [theme.breakpoints.up('xl')]: {
        position: 'relative',
        right: '0px',
      },
    },
    '& > button': {
      marginLeft: '0.2vw',
      right: '36px',
    },
    justifyContent: 'space-start',
    paddingTop: '26px',
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'flex-end',
      paddingTop: '0px',
    },
  },
  infoStatus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dragIcon: {
    marginRight: '10px',
    marginLeft: '-15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fill: colors.dark20,
    },
  },
})

export default styles
