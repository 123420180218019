import React from 'react'

import { getSubscriptionPlans } from '~/services/subscription'
import { useAsync } from '~/utils/useAsync'

function useLoadPlans() {
  const { data, status, error, run } = useAsync()

  React.useEffect(() => {
    run(getSubscriptionPlans())
  }, [run])

  return {
    data,
    status,
    error,
  }
}

export { useLoadPlans }
