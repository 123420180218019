import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { openUpgradeModal, useUpgradeModal } from '~/components/UpgradeModal/context'
import { HTTP_ERROS, SET_ALERT } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as domainsService from '~/services/domainsService'
import { filterByStatus } from '~/utils/domainFilter'

import * as funnelService from '../../../../services/funnelService'
import View from './View'

export default function CreateFunnelZero({ domainId, open, handleClose }) {
  const [_, dispatch] = useUpgradeModal()
  const { domains } = useAppState()
  const { push } = useHistory()
  const [data, setData] = useState({
    domain: domainId,
    name: '',
    funnel_category_id: 0,
  })
  const [errorName, setErrorName] = useState()
  const [errorSelect, setErrorSelect] = useState()
  const domainList = filterByStatus(domains.data, 'active')
  const funnelType = [
    {
      value: 1,
      disabled: false,
      name: 'Funil Customizado',
    },
    {
      value: 2,
      disabled: false,
      name: 'Funil de Vendas',
    },
    {
      value: 3,
      disabled: false,
      name: 'Funil de Leads',
    },
    {
      value: 4,
      disabled: false,
      name: 'Funil de Webinário',
    },
    {
      value: 5,
      disabled: false,
      name: 'Funil de Lançamento',
    },
  ]
  const [domainSelected, setDomainSelected] = useState(domainId)
  const [funnelTypeSelected, setFunnelTypeSelected] = useState(0)
  const appDispatch = useAppDispatch()

  const handleChange = (e) => {
    const { value, name } = e.target
    const auxData = { ...data }
    auxData[name] = value

    if (name === 'name') setErrorName('')
    if (name === 'domain' || name === 'funnel_category_id') {
      setErrorSelect('')
      if (name === 'domain') setDomainSelected(value)
      else {
        setFunnelTypeSelected(value)
      }
    }

    setData(auxData)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (!data.funnel_category_id) return setErrorSelect(`Não foi escolhido um tipo de funil`)

    if (!data.name) return setErrorName('Campo obrigatório')

    if (!data.domain) return setErrorSelect(`Não foi escolhido um domínio`)

    try {
      const domain = data.domain
      const response = await funnelService.createFunnelZero(domain, data)
      const {
        data: {
          data: { id },
        },
      } = response
      if (id) {
        await domainsService.FetchDomains(appDispatch)
        handleClose()

        push('/funnel-detail/' + id)
      }
    } catch (err) {
      const {
        response: {
          data: { code, error },
        },
      } = err

      if (code === HTTP_ERROS.UNAUTHORIZED) openUpgradeModal(dispatch)
      return appDispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: error,
          variant: 'error',
        },
      })
    }
  }

  return (
    <View
      state={{
        open,
        data,
        domainList,
        funnelType,
        errorName,
        domainSelected,
        funnelTypeSelected,
        errorSelect,
      }}
      actions={{ handleClose, handleChange, handleSubmit }}
    />
  )
}

CreateFunnelZero.propTypes = {
  open: PropTypes.bool,
  domainId: PropTypes.number.isRequired,
  handleClose: PropTypes.func,
}
