import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

const useStyles = makeStyles({
  media: {
    height: 50,
    '& img': {
      height: 50,
      width: '100%',
    },
  },
  title: {
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0px',
    color: colors.blueDark,
  },
  status: {
    fontFamily: 'Nunito Sans',
    color: '#1BC5BD',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '14px',
      marginRight: 2,
    },
  },
  content: {
    position: 'relative',
    padding: '0px 0px 5px',
    background: '#FFFFFF',
    border: 'none',
    width: 290,
    height: 210,
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    borderRadius: '10px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  action: {
    borderRadius: '8px !important',
    borderWidth: 2,
    margin: '0px 15px',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    width: '100%',
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&.create': {
      color: '#fff',
      borderColor: '#4C6FFF',
      backgroundColor: '#4C6FFF',

      '&:hover': {
        backgroundColor: '#4C6FFF',
        borderColor: '#4C6FFF',
        borderWidth: 2,
      },
    },
    '&.delete': {
      color: colors.erro,
      borderColor: colors.erro,

      '&:hover': {
        background: '#ffffffff',
        borderColor: colors.erro,
        borderWidth: 2,
      },
    },
  },
})
export default function CardInfo({ state: { info }, actions: { handleOpen } }) {
  const classes = useStyles()
  return (
    <Card className={classes.content} my={2}>
      <CardContent className={classes.media}>
        <img src={info.service_logo} alt="" />
      </CardContent>
      <CardContent style={{ margin: '10px 15px', flexGrow: 2 }}>
        <Typography variant="body1" className={classes.title}>
          {info.name}
        </Typography>

        <Box className={classes.status}>
          {info.subscriber_has ? (
            <>
              <FiberManualRecordIcon size="large" /> Integrado
            </>
          ) : null}
        </Box>
      </CardContent>

      <CardActions style={{ justifySelf: 'flex-end' }}>
        <Button
          variant={!info.subscriber_has ? 'contained' : 'outlined'}
          color="secondary"
          className={clsx(classes.action, !info.subscriber_has ? 'create' : 'delete')}
          onClick={() => handleOpen(!info.subscriber_has ? 'create' : 'delete')}
        >
          {!info.subscriber_has ? 'Integrar' : 'Excluir Integração'}
        </Button>
      </CardActions>
    </Card>
  )
}

CardInfo.propTypes = {
  state: PropTypes.shape({
    info: PropTypes.object,
  }),
  actions: PropTypes.shape({
    handleOpen: PropTypes.func,
  }),
}
