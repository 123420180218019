import React from 'react'

import { Plan } from '~/types'

import { ContentTitle, Text, Title } from '../../styles'
import DisclaimerSkeleton from './DisclaimerSkeleton'

type DisclaimerProps = {
  targetPlan: string
  loading?: boolean
}

interface useLoadPlanByCodeResponse {
  data: Plan
  error: string | null
  status: string
}

const Disclaimer: React.FC<DisclaimerProps> = ({ targetPlan, loading = false }) => {
  // const loading = status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE

  //render skeleton
  if (loading) return <DisclaimerSkeleton />

  return (
    <>
      <ContentTitle>
        <Title>Rebaixar para o {targetPlan}</Title>
        <Text variant="body2">
          Voce possui mais recursos do que o limite permitido pelo plano Básico, para continuar você
          precisa selecionar os recursos que deseja manter ativos.
        </Text>
      </ContentTitle>
    </>
  )
}

export default Disclaimer
