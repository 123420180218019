import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import ModalComponent from '~/components/Modal'
import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainsService from '~/services/domainsService'

function SelectDefaultErrorPage({
  state: { open, id, defaultPage, url },
  action: { handleClose },
}) {
  const [pagesList, setPagesList] = useState([])
  const [pageSelected, setPageSelected] = useState(defaultPage)
  const dispatchApp = useAppDispatch()

  useEffect(() => {
    const fetchPages = async () => {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: true, background: false },
      })
      setPageSelected(defaultPage)

      try {
        const response = await domainsService.getPages(id)
        const {
          data: {
            data: [...rest],
          },
        } = response

        if (rest.length === 1) setPageSelected(rest[0].id)

        setPagesList([...rest])
      } catch (error) {
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema de conexão!',
            variant: 'error',
          },
        })
      }
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: false },
      })
    }
    if (id) fetchPages()
  }, [defaultPage, dispatchApp, id])

  const handleChange = (event) => setPageSelected(event.target.value)

  async function handleSubmit() {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })

    try {
      await domainsService.setDefaultErrorPage(id, { pageId: pageSelected })
      await domainsService.FetchDomains(dispatchApp)
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          variant: 'success',
          message: 'Página definida',
        },
      })

      handleClose()
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          variant: 'error',
          message: error || 'Não foi possível definir a página de Error 404',
        },
      })
    }

    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  if (!id) return null

  const buttons = [
    {
      variant: 'contained',
      name: 'Definir Página de Erro 404',
      handleSubmit,
    },
  ]

  const helper = {
    title: 'Nota',
    content: (
      <>
        Essa página será exibida quando a URL digitada não corresponder a nenhuma página publicada.
        No lugar, aproveite para mostrar uma página personalizada sua.
      </>
    ),
  }

  const inputs = [
    {
      component: 'select',
      label: 'Página de Erro 404',
      fullWidth: true,
      name: 'default-page',
      value: pageSelected,
      onChange: handleChange,
      optionDisabled: 'Selecione a página',
      options: pagesList,
    },
  ]

  return (
    <ModalComponent
      state={{
        open,
        title: 'Página de Erro 404',
        isLarge: true,
        buttons,
        helper,
        inputs,
      }}
      actions={{ handleClose }}
    >
      <Typography variant="h6" gutterBottom>
        Quando não encontrarmos uma página do domínio <em>“{url}”</em>, qual página deveremos
        mostrar?
      </Typography>
    </ModalComponent>
  )
}

SelectDefaultErrorPage.propTypes = {
  state: PropTypes.shape({
    id: PropTypes.number,
    open: PropTypes.bool,
    url: PropTypes.string,
    defaultPage: PropTypes.number,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

SelectDefaultErrorPage.defaultProps = {
  defaultPage: '',
}

export default SelectDefaultErrorPage
