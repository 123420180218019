import { Dialog } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { Domain } from '~/types'

import { ImportSetupContent } from './ImportSetupContent'

interface ImportSetupModalProps {
  isOpen: boolean
  handleClose: () => void
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  formState: {
    name: {
      value: string
      error: boolean
    }
    domain: {
      value: number
      error: boolean
    }
  }
  handleSubmit: ({ name, domain }: { name: string; domain: number }) => void
  domainList: Domain[]
}

const ModalContent = styled.div`
  margin: 0 auto;
  /* padding: 16px 40px; */
  justify-content: center;
  align-items: flex-start;
`

export function ImportSetupModal(props: ImportSetupModalProps) {
  const { isOpen, handleClose } = props

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalContent>
        <ImportSetupContent {...props} />
      </ModalContent>
    </Dialog>
  )
}
