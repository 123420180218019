import { Box, Button, Container, Grid } from '@material-ui/core'
import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { RedirectionCard } from './components'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontFamily: 'Nunito Sans',
    '& h6': {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      margin: 0,
    },
  },
  addDomain: {
    borderRadius: '8px !important',
    borderWidth: 2,
    // color: "#4C6FFF",
    color: '#fff',
    borderColor: '#4C6FFF',
    backgroundColor: '#4C6FFF',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    // width: 180,
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&:hover': {
      backgroundColor: '#4C6FFF',
      borderColor: '#4C6FFF',
      borderWidth: 2,
    },
  },
  search: {
    width: 400,
    height: 40,
    background: '#FFFFFF',
    borderRadius: 50,
    marginRight: '40px',
    '& fieldset': {
      height: 45,
      borderRadius: 50,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& div': {
      height: 40,
    },
    '& input': {
      padding: '0 40px !important',
    },
    '& svg': {
      color: '#A9ABAE',
    },
  },
}))

function DomainsListView({
  domains,
  isFetching,
  handleClickCopyButton,
  handleDuplicate,
  handleDelete,
  handleSearch,
}) {
  let { push } = useHistory()
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  if (isFetching) return null

  return (
    <Box m={4}>
      <Box mb={4} className={classes.header}>
        <Typography variant="h6" gutterBottom>
          Meus Redirecionamentos
        </Typography>
        <Box display="flex">
          <Box className={classes.search}>
            <TextField
              variant="outlined"
              fullWidth
              id="search"
              name="search"
              placeholder="Pesquisar por nome"
              onChange={(e) => {
                const { value } = e.target

                setSearchValue(value)

                handleSearch(value)
              }}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {domains && (
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addDomain}
                onClick={() => push(`/redirection/create`)}
                startIcon={<AddIcon />}
              >
                Adicionar Redirecionamento
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box marginTop="2rem">
        <Container>
          <Grid
            container
            // className={classes.root}
            spacing={4}
          >
            {/* <Grid item xs={12}> */}
            {/* <Grid container justifyContent="center" spacing={spacing}> */}
            {/* {[0, 1, 2].map((value) => ( */}
            {domains && domains.length ? (
              <>
                {domains.map((domain, index) => (
                  <Grid key={`domain_${index}`} item xs={6}>
                    <RedirectionCard
                      redirect={domain}
                      handleClickCopyButton={handleClickCopyButton}
                      handleDuplicate={handleDuplicate}
                      handleDelete={handleDelete}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <Box my={2} textAlign="center" style={{ paddingTop: '80px' }}>
                <Typography variant="h6" gutterBottom>
                  Nenhum redirecionamento foi encontrado
                </Typography>
              </Box>
            )}

            {/* ))} */}
            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

DomainsListView.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
  handleClickCopyButton: PropTypes.func,
  handleDuplicate: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
}

export default DomainsListView
