import { AxiosError } from 'axios'

import axios from './config/axios'
import { getSubscriptionMock } from './mocks/subscriptionsMock'

type Params = Record<string, string>[]

function uriWithParams(params: Params) {
  return params.reduce((acc, current) => {
    acc += `${current.key}=${current.value}&`
    return acc
  }, '')
}

export const getCurrentSubscription = async (props: { params: Params }) => {
  const { params } = props || {}
  const subscriptionScenario: string | null = new URLSearchParams(window.location.search).get(
    'subscriptionScenario',
  )

  if (subscriptionScenario) return Promise.resolve(getSubscriptionMock(subscriptionScenario))

  try {
    const uri = params ? `/subscription/current?${uriWithParams(params)}` : '/subscription/current'
    const response = await axios.get(uri)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject('Houve um problema nesta operação. Por favor tente mais tarde.')
  }
}

export const getSubscriptionPlans = async () => {
  try {
    const response = await axios.get('/subscription/plans?order_by=level')
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject('Houve um problema nesta operação. Por favor tente mais tarde.')
  }
}

export const getSubscriptionByCode = async (planCode: string) => {
  try {
    const response = await axios.get(`/subscription/plans/${planCode}`)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject('Houve um problema nesta operação. Por favor tente mais tarde.')
  }
}

export const getSubscriptionCancelationReasons = async () => {
  try {
    const response = await axios.get('subscription/cancellation-reasons')
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject('Houve um problema nesta operação. Por favor tente mais tarde.')
  }
}

export const postSubscriptionChange = async (data: any) => {
  try {
    const response = await axios.post('/subscription/change', data)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject('Houve um problema nesta operação. Por favor tente mais tarde.')
  }
}

type CreditCardProps = {
  card_hash: string
  card_holder_name: string
  card_expiration_date: string
  card_number: string
}

export const changeCreditCard = async (data: CreditCardProps) => {
  try {
    const response = await axios.put('/subscription/card', data)
    return Promise.resolve(response.data.data || 'Cartão de crédito atualizado com sucesso!')
  } catch (error: any) {
    return Promise.reject(error.response.data.error)
  }
}
