import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'

import { ListContent } from '../../styles'
import MultipleSelectSkeleton from './MultipleSelectSkeleton'

type Item = {
  id: number
  name: string
  is_mf_domain?: boolean
}

type ItemWithCheck = {
  id: number
  name: string
  check?: boolean
  is_mf_domain?: boolean
}

type MultipleSelectProps = {
  items: Item[] | undefined
  itemsSelected: number[]
  itemsLimit: number
  callback?: (item: Item) => void
  loading?: boolean
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  items,
  itemsSelected,
  itemsLimit,
  callback = () => {},
  loading,
}) => {
  const processItems = (list: Item[]) => {
    const listCopy = [...list]
    return listCopy?.map((item) => {
      const current: ItemWithCheck = item
      current['check'] = current.is_mf_domain || itemsSelected.includes(current.id)
      return current
    })
  }

  const itemsList = items ? processItems(items) : []

  const handleChange = (item: Item) => {
    if (itemsSelected.includes(item.id)) callback(item)
    else if (itemsSelected && itemsSelected?.length + 1 <= itemsLimit) callback(item)
  }

  if (loading) return <MultipleSelectSkeleton />

  if (!items) return null

  return (
    <ListContent>
      {itemsList &&
        itemsList.map((item: ItemWithCheck, index) => (
          <MenuItem
            key={`item_${index}`}
            value={item.name}
            onClick={() => handleChange(item)}
            disabled={item?.is_mf_domain}
          >
            <Checkbox checked={item.check} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
    </ListContent>
  )
}

export default MultipleSelect
