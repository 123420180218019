// import React from 'react'
import * as localStorageService from '~/utils/localStorage'

function useOnboarding(pageId) {
  const dismissOnboarding = localStorageService.get(pageId)

  const dismissDispatch = () => {
    localStorageService.save(pageId, true)
  }

  return [dismissOnboarding, dismissDispatch]
}

export { useOnboarding }
