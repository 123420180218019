import { Box } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import LaunchIcon from '@material-ui/icons/Launch'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as AffiliatesIcon } from '~/assets/images/affiliate_icon.svg'
import { ReactComponent as ConfigIcon } from '~/assets/images/config_icon.svg'
import { ReactComponent as DomainIcon } from '~/assets/images/domain_icon.svg'
import { ReactComponent as IntegrationIcon } from '~/assets/images/integration_icon.svg'
import { ReactComponent as FunnelIcon } from '~/assets/images/mf_funnel_icon.svg'
import { ReactComponent as Boards } from '~/assets/images/quadros.svg'
import { ReactComponent as SupportIcon } from '~/assets/images/support_icon.svg'
import { ReactComponent as TrainingIcon } from '~/assets/images/training_icon.svg'
import { ReactComponent as RedirectIcon } from '~/assets/images/wpp_redirect_icon.svg'
import { AFFILIATE_EXTERNAL_LINK, REGISTERED_PAGES, YOUTUBE_CHANNEL } from '~/constants'

import SupportModal from '../SupportModal'

const useStyles = makeStyles(() => ({
  drawerContainer: {
    overflow: 'auto',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  MenuItem: {
    /* Aumenta a especificidade */
    color: '#222',
    '& svg path, & i': {
      textAlign: 'center',
      color: '#CFD0E7',
      stroke: '#CFD0E7',
      fontSize: 25,
      width: 25,
    },
    '& div span, & div p': {
      fontSize: '16px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
    },
  },

  MenuActive: {
    color: '#4C6FFF',
    background: '#FFFFFFFF !important',
    borderLeft: '5px solid #4C6FFF',

    '& svg path, & i': {
      stroke: '#4C6FFF',
      color: '#4C6FFF',
      width: 25,
    },
    '& svg rect': {
      stroke: '#4C6FFF',
    },
    '&.needFill': {
      '& svg path, & i': {
        fill: '#4C6FFF',
      },
    },

    '& div span, & div p': {
      fontWeight: 700,
    },
  },

  list: {
    width: 259,
  },
  divider: {
    background: '#E5E9F5',
    height: 2,
    margin: '31px 30px',
  },
}))

export default function View({
  action: { handleClick, handleClose, setOpen },
  state: { selected, open },
}) {
  const classes = useStyles()
  const firstList = [
    {
      title: 'Meus Funis',
      index: REGISTERED_PAGES.MY_FUNNELS.index,
      props: {
        onClick: () =>
          handleClick(REGISTERED_PAGES.MY_FUNNELS.index, REGISTERED_PAGES.MY_FUNNELS.url),
      },
      icon: <FunnelIcon />,
    },
    {
      title: 'Meus Domínios',
      index: REGISTERED_PAGES.MY_DOMAINS.index,
      props: {
        onClick: () =>
          handleClick(REGISTERED_PAGES.MY_DOMAINS.index, REGISTERED_PAGES.MY_DOMAINS.url),
      },
      icon: <DomainIcon />,
    },
    {
      title: 'Grupos de whatsapp',
      index: REGISTERED_PAGES.WPP_GROUPS.index,
      props: {
        onClick: () =>
          handleClick(REGISTERED_PAGES.WPP_GROUPS.index, REGISTERED_PAGES.WPP_GROUPS.url),
      },
      icon: <RedirectIcon />,
    },
    {
      title: 'Meus Quadros',
      index: REGISTERED_PAGES.BOARDS.index,
      props: {
        onClick: () => handleClick(REGISTERED_PAGES.BOARDS.index, REGISTERED_PAGES.BOARDS.url),
      },
      icon: <Boards />,
    },
  ]

  const secondList = [
    {
      title: 'Integrações',
      index: REGISTERED_PAGES.INTEGRATION.index,
      icon: <IntegrationIcon />,
      props: {
        onClick: () =>
          handleClick(REGISTERED_PAGES.INTEGRATION.index, REGISTERED_PAGES.INTEGRATION.url),
      },
    },
    {
      title: 'Afiliações',
      index: 4,
      icon: <AffiliatesIcon />,
      props: {
        component: 'a',
        target: '_blank',
        href: AFFILIATE_EXTERNAL_LINK,
        rel: 'noopener noreferrer',
      },
    },
    {
      title: 'Tutoriais',
      index: 5,
      icon: <TrainingIcon />,
      needFill: true,
      props: {
        component: 'a',
        target: '_blank',
        href: YOUTUBE_CHANNEL,
        rel: 'noopener noreferrer',
      },
    },
  ]

  const configList = [
    {
      title: 'Suporte',
      index: 6,
      icon: <SupportIcon />,
      props: {
        onClick: () => setOpen(true),
      },
    },
    {
      title: 'Configurações',
      index: REGISTERED_PAGES.SETTINGS.index,
      icon: <ConfigIcon />,
      props: {
        onClick: () => handleClick(REGISTERED_PAGES.SETTINGS.index, REGISTERED_PAGES.SETTINGS.url),
      },
    },
  ]

  return (
    <div className={classes.drawerContainer}>
      <Box style={{ marginTop: 35 }}>
        <List className={classes.list}>
          {firstList.map((el) => (
            <ListItem
              button
              key={el.index + el.title}
              selected={selected === el.index}
              {...el.props}
              className={clsx(classes.MenuItem, selected === el.index ? classes.MenuActive : '')}
            >
              <ListItemIcon style={{ justifyContent: 'center' }}>{el.icon}</ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItem>
          ))}
        </List>

        <Divider className={classes.divider} />

        <List className={classes.list}>
          {secondList.map((el) => (
            <ListItem
              button
              key={el.index + el.title}
              selected={selected === el.index}
              {...el.props}
              className={clsx(
                classes.MenuItem,
                selected === el.index ? classes.MenuActive : '',
                el.needFill && 'needFill',
              )}
            >
              <ListItemIcon style={{ justifyContent: 'center' }}>{el.icon}</ListItemIcon>
              <ListItemText primary={el.title} />
              {el.props.href && (
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  <LaunchIcon fontSize="small" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </List>

        <Divider className={classes.divider} />
      </Box>

      <List className={classes.list}>
        {configList.map((el) => (
          <ListItem
            button
            key={el.index + el.title}
            selected={selected === el.index}
            {...el.props}
            className={clsx(
              classes.MenuItem,
              selected === el.index ? classes.MenuActive : '',
              el.needFill && 'needFill',
            )}
          >
            <ListItemIcon style={{ justifyContent: 'center' }}>{el.icon}</ListItemIcon>
            <ListItemText primary={el.title} />
          </ListItem>
        ))}
      </List>

      <SupportModal open={open} handleClose={handleClose} />
    </div>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    selected: PropTypes.any,
    open: PropTypes.bool,
  }),
  action: PropTypes.shape({
    handleClick: PropTypes.func,
    handleClose: PropTypes.func,
    setOpen: PropTypes.func,
  }),
}
