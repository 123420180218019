/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Image, Rect } from 'react-konva'

import DeleteIcon from '~/assets/images/delete_icon.svg'

import { REMOVE_NODE } from '../../constants/actions'
import { useBoard } from '../../context/BoardProvider'
import useLoadImage from '../../hooks/useLoadImage'

function Delete({ x, y, id }) {
  const image = useLoadImage({ src: DeleteIcon })
  const { dispatch } = useBoard()
  const width = 18

  const handleOnClick = () => {
    dispatch({
      type: REMOVE_NODE,
      payload: { id },
    })
  }

  return (
    <>
      <Image
        x={x + 4}
        y={y - width}
        width={width}
        height={width}
        onClick={handleOnClick}
        cornerRadius={2}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'pointer'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
      />
    </>
  )
}

export default Delete
