import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { openUpgradeModal, useUpgradeModal } from '~/components/UpgradeModal/context'
import { HTTP_ERROS, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainService from '~/services/domainsService'

import View from './DomainsCreateView'

function DomainCreateController({ open, handleClose, handleOpenDetail }) {
  const [_, dispatch] = useUpgradeModal()
  const [domainUrl, setDomainUrl] = useState('')
  const [hasError, setHasError] = useState(null)
  const [isInputEmpty, setIsInputEmpty] = useState(true)
  const dispatchApp = useAppDispatch()

  const handleChange = (event) => {
    const { value } = event.target
    setIsInputEmpty(false)
    setHasError(null)
    setDomainUrl(value)
  }

  const closeModal = () => {
    handleClose(!open)
  }

  const handleSubmit = async () => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })

    try {
      const response = await domainService.createDomain({
        name: domainUrl,
      })
      const { id } = response

      handleOpenDetail(id)
    } catch (error) {
      if (error.code === HTTP_ERROS.UNAUTHORIZED) openUpgradeModal(dispatch)
      setHasError(error)
    }
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  return (
    <View
      state={{
        domainUrl,
        hasError,
        isInputEmpty,
        open,
      }}
      actions={{
        handleSubmit,
        handleChange,
        handleClose: closeModal,
      }}
    />
  )
}

DomainCreateController.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpenDetail: PropTypes.func,
}

export default DomainCreateController
