import { Box, IconButton, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircle, CancelOutlined, EditOutlined, RemoveCircleRounded } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

import { useRedirectionContext } from '../../context'
import useStyles from './styles'

const STATUS = {
  CURRENT: 'current',
  NONE: 'none',
  FULL: 'full',
}

const useStatusStyle = makeStyles({
  box: (props) => {
    let borderStyle = 'solid 2px #00A152'
    if (props.status !== STATUS.CURRENT) {
      borderStyle = STATUS.NONE
      if (!props.isVisitsCorrect) {
        borderStyle = 'solid 2px ' + colors.erro
      }
    }
    return {
      border: borderStyle,
      borderRadius: '8px',
      padding: '10px',
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffac33',
      },
      '& .MuiFormLabel-root.Mui-error, & .MuiFormLabel-asterisk.Mui-error': {
        color: '#ffac33',
      },
    }
  },
  muted: {
    color: '#999',
    textAlign: 'left',
    '& i': {
      marginRight: '7px',
    },
  },
})

const LinkBox = (props) => {
  const classes = useStatusStyle({ status: props.status, isVisitsCorrect: props.isVisitsCorrect })
  const { status } = props

  return (
    <Box
      // key={`link_${index}`}

      mb={3}
      className={classes.box}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {props.children}
      </Box>
      {status !== STATUS.NONE && (
        <Box mt={2}>
          <Typography variant="caption" className={classes.muted}>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
            {status === STATUS.CURRENT && 'Este link esta sendo populado no momento.'}
            {status === STATUS.FULL && 'Este link já esta lotado e não vai receber mais visitas.'}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

LinkBox.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string || PropTypes.bool,
  isVisitsCorrect: PropTypes.bool,
}

const ToggleEdit = ({ value: { visits, limit }, handleChange }) => {
  const [editMode, setEditMode] = React.useState(false)

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState)
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box style={{ fontSize: 14, paddingRight: 5, fontFamily: 'Nunito Sans' }}>{visits}/</Box>
      <TextField
        value={limit}
        size="small"
        onChange={handleChange}
        disabled={!editMode}
        type="number"
        // className={classes.textField}
      />
      {!editMode ? (
        <Box>
          <IconButton aria-label="edit" onClick={toggleEditMode}>
            <EditOutlined />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton aria-label="delete" onClick={toggleEditMode}>
            <CancelOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

function Urls({ children, visits_per_link }) {
  const classes = useStyles()
  const { state, setState } = useRedirectionContext()
  const {
    urls,
    metrics: { current_link },
  } = state

  const handleAddLink = () => {
    const copyUrls = urls

    copyUrls.push({
      url: '',
      visits_limit: visits_per_link,
      order: copyUrls.length + 1,
    })
    setState({
      urls: copyUrls,
    })
  }

  const handleRemoveLink = () => {
    const copyUrls = urls

    if (copyUrls.length === 1) return
    copyUrls.pop()
    setState({
      urls: copyUrls,
    })
  }

  const handleSetUrls = (event, index) => {
    const value = event.target.value
    const copyUrls = urls
    copyUrls[index].url = value

    setState({
      urls: copyUrls,
    })
  }

  const currentLinkId = current_link !== -1 && urls[current_link].id
  const currentLink = currentLinkId && urls.filter((url) => url.id === currentLinkId)[0]

  const handleSetUrlLimit = (event, index) => {
    const value = event.target.value
    const copyUrls = [...urls]
    const link = copyUrls[index]
    link.visits_limit = value
    setState({
      urls: copyUrls,
    })
  }

  const getStatus = (visits, limit, isCurrent) => {
    if (isCurrent) return STATUS.CURRENT
    if (visits === limit || visits > limit) return STATUS.FULL
    return STATUS.NONE
  }

  const isCurrent = (id) => {
    if (typeof currentLinkId !== 'number') return false
    else return currentLinkId === Number(id)
  }

  const renderLinks = (urls) => {
    return urls.map((link, index) => {
      const status = getStatus(link.visits && link.visits, link.visits_limit, isCurrent(link.id))
      return (
        <LinkBox
          key={`link_${index}`}
          status={status}
          isVisitsCorrect={link.visits ? link.visits_limit >= link.visits : true}
        >
          <Box flex="1" mr={2}>
            <TextField
              error={status === STATUS.FULL}
              label={`URL ${index + 1}`}
              name="link"
              value={link.url}
              size="medium"
              onChange={(e) => handleSetUrls(e, index)}
              fullWidth
              variant="outlined"
              required
            />
          </Box>
          <Box flex="0.2">
            <ToggleEdit
              value={{ visits: link.visits, limit: link.visits_limit }}
              handleChange={(e) => handleSetUrlLimit(e, index)}
            />
          </Box>
        </LinkBox>
      )
    })
  }

  React.useEffect(() => {
    const linkIndex = urls.findIndex((url) => url.visits_limit > url.visits)
    setState((prevState) => ({
      metrics: {
        ...prevState.metrics,
        current_link: linkIndex,
      },
    }))
  }, [currentLink, setState, urls])

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={3}>
        <Typography className={classes.title} variant="h4" align="left">
          URLs Destino
        </Typography>
        <Box display="flex" justifyContent="space-around" height="50px">
          <IconButton aria-label="delete" onClick={handleRemoveLink}>
            <RemoveCircleRounded />
          </IconButton>
          <IconButton aria-label="adicionar" onClick={handleAddLink}>
            <AddCircle />
          </IconButton>
        </Box>
      </Box>
      <Paper className={classes.block}>
        {children}
        <Box
          display="flex"
          // justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          // marginTop="3rem"
          width="100%"
        >
          <Box mb={1} width="100%" display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="100%">
              {renderLinks(urls)}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

Urls.propTypes = {
  visits_per_link: PropTypes.number,
  HeaderComponent: PropTypes.func,
}
Urls.defaultProps = {
  visits_per_link: 350,
  HeaderComponent: () => null,
}

export default Urls
