import { Typography } from '@material-ui/core'
import StarsIcon from '@material-ui/icons/Stars'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Feature } from '../../components/Feature'

function Plans() {
  const history = useHistory()
  const redirect = () => {
    history.push('/plans')
  }

  return (
    <>
      <Feature image={StarsIcon} onClick={redirect}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Meu Plano
        </Typography>
        <Typography variant="body2">
          Aperte aqui para ver informações sobre o seu plano atual.
        </Typography>
      </Feature>
    </>
  )
}

export default Plans
