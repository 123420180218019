/* eslint-disable react/prop-types */
import { Typography } from '@material-ui/core'
import React, { lazy, Suspense } from 'react'

import { AddButton } from '~/components/Buttons'
import { useStatusMachine } from '~/hooks'
import * as canvasServices from '~/services/canvasServices'
import { useAsync } from '~/utils/useAsync'

import { useBoards } from '../../context/BoardsProvider'
const ModalComponent = lazy(() => import('~/components/Modal'))

const initialFormState = {
  boardName: '',
}

const NewBoard = () => {
  const { handleSuccess } = useBoards()
  const { status, error: hasError, run } = useAsync()
  const [form, setForm] = React.useState(initialFormState)
  const [isOpen, setIsOpen] = React.useState(false)
  useStatusMachine(status, handleSuccess)

  const buttons = [
    {
      variant: 'contained',
      name: 'Salvar',
      handleSubmit: () => {
        run(canvasServices.save(form.boardName))
      },
    },
  ]

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'name',
      type: 'text',
      label: 'Quadro',
      value: form.boardName,
      placeholder: 'Meu Quadro',
      onChange: (e) => setForm((prevState) => ({ ...prevState, boardName: e.target.value })),
      error: hasError,
      helper: hasError?.error || '',
    },
  ]

  const handleClose = () => {
    setForm(initialFormState)
    setIsOpen(false)
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)}>Novo Quadro</AddButton>
      {isOpen && (
        <Suspense>
          <ModalComponent
            actions={{ handleClose }}
            state={{ open: isOpen, buttons, inputs, title: 'Novo Quadro' }}
          >
            <Typography variant="h6" gutterBottom>
              Qual o nome do quadro você gostaria de criar?
            </Typography>
          </ModalComponent>
        </Suspense>
      )}
    </>
  )
}

export default NewBoard
