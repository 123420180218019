const LEADS = 'leads'
const SALES = 'sales'
const WEBINAR = 'webinar'
const LAUNCH = 'launch'

export default {
  LEADS,
  SALES,
  WEBINAR,
  LAUNCH,
}
