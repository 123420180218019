import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { default as Button } from '~/components/BaseButton'

import CreatePageModal from './CreatePageModal'

function NewPage({ pages }) {
  const size = pages.length === 0 ? 'large' : 'small'
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={4}>
      <Button
        size={size}
        variant="outlined"
        handleClick={() => {
          toggleOpen()
          // setPageIDSelected(page.id)
          // handle(page.id)
        }}
        color="primary"
        text="Nova Página"
        fullWidth={pages.length === 0}
        startIcon={<AddIcon />}
      />
      <CreatePageModal open={open} handleClose={toggleOpen} nextStep={pages.length + 1} />
    </Box>
  )
}

NewPage.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})),
}

export default NewPage
