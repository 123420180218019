import { Box, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import LanguageIcon from '@material-ui/icons/Language'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import detailIcon from '~/assets/images/detail_icon.svg'
import homeIcon from '~/assets/images/home_icon.svg'
import trashIcon from '~/assets/images/trash_icon.svg'
import TooltipStatus from '~/components/TooltipStatus/TooltipStatus'
import { STATUS } from '~/constants'
import { useAppState } from '~/context'
import DomainsDetail from '~/pages/Domains/DomainsDetail'
import Menu from '~/pages/Templates/components/Menu'

import { DefaultPageModal, SelectDefaultErrorPage } from '../../../components'
import DeleteDomain from '../DeleteDomain'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  folder: {
    background: '#EDF1FF',
    borderRadius: 8,
    width: 53,
    height: 50,
    textAlign: 'center',
    alignItems: ' center',
    '& svg': {
      margin: '9px',
      fontSize: '30px',
      color: '#4C6FFF',
    },
    '&.funnel': {
      background: '#FFF1E9',
      marginRight: '40px',
      '& svg': {
        color: '#FF9349',
      },
    },
  },
  domainName: {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '0px',
      color: '#A0A4A8',
    },
    '& .active': {
      color: '#1BC5BD',
    },
    '& .active.protect': {
      color: '#0094FF',
    },
    '& .peding': {
      color: '#F64E60',
    },
    '& .blocked': {
      color: '#FAB348',
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& svg': {
      fontSize: '14px',
      marginRight: 2,
    },
  },
  tableContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    borderRadius: '8px',
  },
  domainNickName: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
})
function BasicTable({ domains, isFetching }) {
  const classes = useStyles()
  const {
    user: { base_domain_id },
  } = useAppState()
  const [detailDomain, setDetailDomain] = useState({
    open: false,
    id: null,
  })

  const [defaultPageModal, setDefaultPageModal] = useState({
    open: false,
    id: null,
    defaultPage: 0,
    url: '',
  })
  const [defaultErrorPageModal, setDefaultErrorPageModal] = useState({
    open: false,
    id: null,
    defaultPage: 0,
    url: '',
  })
  const [deleteDomainModal, setDeleteDomainModal] = useState({
    open: false,
    id: null,
    validator: null,
  })

  const optionItem = (Icon, label, handleClick) => (
    <Box
      onClick={handleClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        style={{
          display: 'flex',
          width: 38,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {typeof Icon === 'object' ? <Icon /> : <img src={Icon} alt="" />}
      </Box>
      {label}
    </Box>
  )

  function menuOption(status, domain) {
    const deleteOption = optionItem(trashIcon, 'Deletar', () =>
      setDeleteDomainModal({
        open: true,
        id: domain?.id,
        validator: domain?.name,
      }),
    )

    const arrStatusActive = [
      optionItem(homeIcon, 'Definir página padrão', () =>
        setDefaultPageModal({
          open: true,
          id: domain?.id,
          defaultPage: domain?.default_page_id,
          url: domain?.name,
        }),
      ),
      optionItem(LinkOffIcon, 'Definir Página de Erro 404', () =>
        setDefaultErrorPageModal({
          open: true,
          id: domain?.id,
          defaultPage: domain?.default_invalid_page_id,
          url: domain?.name,
        }),
      ),
    ]
    if (base_domain_id !== domain?.id) arrStatusActive.push(deleteOption)

    return status
      ? arrStatusActive
      : [
          optionItem(detailIcon, 'Ver detalhes', () =>
            setDetailDomain({ open: true, id: domain?.id }),
          ),
          deleteOption,
        ]
  }

  function handleClose() {
    setDetailDomain({
      open: false,
      id: null,
    })
  }

  function statusText(status, ssl) {
    return status === STATUS.DOMAIN.ACTIVE
      ? ssl
        ? 'Sincronizado e Seguro'
        : 'Sincronizado'
      : 'Não Sincronizado'
  }

  if (isFetching) return null
  if (domains && !domains.length)
    return (
      <React.Fragment>
        <Box my={2} textAlign="center">
          <Typography variant="h6" gutterBottom>
            Nenhum domínio foi encontrado
          </Typography>
        </Box>
      </React.Fragment>
    )

  return (
    <>
      {domains &&
        domains.map((domain, index) => {
          return (
            <Box key={`${domain?.name}_${index}`} my={2}>
              <TableContainer
                className={classes.tableContainer}
                key={domain?.name}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow my={2}>
                      <TableCell>
                        <Box className={classes.folder}>
                          <LanguageIcon size="large" />
                        </Box>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        width="70%"
                        className={classes.domainNickName}
                      >
                        {domain?.name}
                      </TableCell>

                      <TableCell component="th" scope="row" width="30%">
                        <Box display="flex" justifyContent="space-between">
                          <Box className={classes.domainName}>
                            <Typography variant="h6" gutterBottom>
                              <span>Status</span>
                            </Typography>

                            <Box
                              className={`${classes.status} ${
                                // row.status === 'active' ? 'sync' : 'pending'
                                domain?.status
                              } ${domain?.ssl_active && 'protect'}`}
                            >
                              {domain?.status === STATUS.DOMAIN.BLOCKED ? (
                                <TooltipStatus
                                  label="Desativado"
                                  title="Este recurso está desativado, por favor entrar em contato com o suporte caso queira ativar novamente."
                                />
                              ) : (
                                <>
                                  <FiberManualRecordIcon size="large" />
                                  {statusText(domain?.status, domain?.ssl_active)}
                                </>
                              )}
                            </Box>
                          </Box>
                          <Menu
                            index={index}
                            options={menuOption(domain?.status === 'active', domain)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        })}
      <DefaultPageModal
        state={{ ...defaultPageModal }}
        action={{
          handleClose: () => setDefaultPageModal((prev) => ({ ...prev, open: false })),
        }}
      />
      <SelectDefaultErrorPage
        state={{ ...defaultErrorPageModal }}
        action={{
          handleClose: () => setDefaultErrorPageModal((prev) => ({ ...prev, open: false })),
        }}
      />
      <DeleteDomain
        state={{ ...deleteDomainModal }}
        action={{
          handleClose: () => setDeleteDomainModal({ id: null, validator: null, open: false }),
        }}
      />
      <DomainsDetail state={{ ...detailDomain }} action={{ handleClose }} />
    </>
  )
}

BasicTable.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
}

export default BasicTable
