/* eslint-disable react/prop-types */
import React from 'react'
import { Line, Rect } from 'react-konva'

import { Copy, Delete } from '../Actions'
import { Anchor } from '../Anchor'

export function Border({
  id,
  position,
  width,
  height,
  onAnchorDragStart,
  onAnchorDragMove,
  onAnchorDragEnd,
}) {
  return (
    <>
      <Line
        x={position.x}
        y={position.y}
        points={[0, 0, width, 0, width, height, 0, height, 0, 0]}
        stroke="#0094FF"
        strokeWidth={2}
        perfectDrawEnabled={false}
        lineJoin="round"
        lineCap="round"
      />
      <Anchor
        x={position.x + width}
        y={position.y + height / 2}
        onDragStart={onAnchorDragStart}
        onDragMove={onAnchorDragMove}
        onDragEnd={onAnchorDragEnd}
      />
      <Rect
        x={position.x - 1}
        y={position.y - 18}
        fill="#0094FF"
        width={46}
        height={18}
        perfectDrawEnabled={false}
        cornerRadius={[2, 2, 0, 0]}
      />
      <Delete x={position.x} y={position.y} id={id} />
      <Copy x={position.x} y={position.y} id={id} />
    </>
  )
}
