import { useEffect, useRef } from 'react'

import { ASYNC_STATE, SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import { useAsync } from '~/utils/useAsync'

export const useLoadData = (fetch) => {
  const mounted = useRef(false)
  const dispatchApp = useAppDispatch()
  const { data, status, error, run } = useAsync()
  const isFetching = status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE

  const runRequest = () => run(fetch())

  useEffect(() => {
    if (!mounted.current) {
      run(fetch())
      mounted.current = true
    }
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: isFetching, background: false },
    })
  }, [dispatchApp, isFetching])

  useEffect(() => {
    if (status === ASYNC_STATE.REJECTED || error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve um problema para carregar as Integrações! Tente novamente mais tarde!',
          variant: 'error',
        },
      })
    }
  }, [dispatchApp, error, status])

  return { data, isFetching, error, runRequest }
}
