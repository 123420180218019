let helperFrameWindow = document.getElementById('helperFrame').contentWindow

export const setToken = (token) => {
  helperFrameWindow.postMessage(
    {
      action: 'set',
      key: 'token',
      value: token,
    },
    '*',
  )
}

export const setPageId = (id) => {
  helperFrameWindow.postMessage(
    {
      action: 'set',
      key: 'pageId',
      value: id,
    },
    '*',
  )
}

export const setFunnelId = (id) => {
  helperFrameWindow.postMessage(
    {
      action: 'set',
      key: 'funnelId',
      value: id,
    },
    '*',
  )
}

export const clearCredentials = () => {
  helperFrameWindow.postMessage(
    {
      action: 'clear',
    },
    '*',
  )
}
