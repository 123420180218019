import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import ModalComponent from '~/components/Modal'
import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainsService from '~/services/domainsService'

function DefaultPageModal({ state: { open, id, defaultPage, url }, action: { handleClose } }) {
  const [pagesList, setPagesList] = useState([])
  const [pageSelected, setPageSelected] = useState(defaultPage)
  const dispatchApp = useAppDispatch()

  useEffect(() => {
    const fetchPages = async () => {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: true, background: false },
      })
      setPageSelected(defaultPage)

      try {
        const response = await domainsService.getPages(id)
        const {
          data: {
            data: [...rest],
          },
        } = response

        if (rest.length === 1) setPageSelected(rest[0].id)

        setPagesList([...rest])
      } catch (error) {
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema de conexão!',
            variant: 'error',
          },
        })
      }
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: false },
      })
    }
    if (id) fetchPages()
  }, [defaultPage, dispatchApp, id])

  const handleChange = (event) => setPageSelected(event.target.value)

  async function handleSubmit() {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: false },
    })

    const data = { default_page_id: pageSelected }

    try {
      await domainsService.setDefaultPage(id, data)
      await domainsService.FetchDomains(dispatchApp)
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          variant: 'success',
          message: 'Página padrão definida',
        },
      })

      handleClose()
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          variant: 'error',
          message: error || 'Não foi possível definir a página padrão',
        },
      })
    }

    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: false, background: false },
    })
  }

  if (!id) return null

  const buttons = [
    {
      variant: 'contained',
      name: 'Definir página padrão',
      handleSubmit,
    },
  ]

  const helper = {
    title: 'Nota',
    content: (
      <>
        Essa página é importante porque é a página que o visitante verá quando digitar apenas o seu
        domínio, sem especificar um caminho de página.
      </>
    ),
  }

  const inputs = [
    {
      component: 'select',
      label: 'Página padrão',
      fullWidth: true,
      name: 'default-page',
      value: pageSelected,
      onChange: handleChange,
      optionDisabled: 'Seleciona a página padrão',
      options: pagesList,
    },
  ]

  return (
    <ModalComponent
      state={{
        open,
        title: 'Definir página padrão',
        isLarge: true,
        buttons,
        helper,
        inputs,
      }}
      actions={{ handleClose }}
    >
      <Typography variant="h6" gutterBottom>
        Quando acessar <em>“{url}”</em> qual a primeira página que irá aparecer?
      </Typography>
    </ModalComponent>
  )
}

DefaultPageModal.propTypes = {
  state: PropTypes.shape({
    id: PropTypes.number,
    open: PropTypes.bool,
    url: PropTypes.string,
    defaultPage: PropTypes.number,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

DefaultPageModal.defaultProps = {
  defaultPage: '',
}

export default DefaultPageModal
