import { makeStyles } from '@material-ui/core/styles'
import PaginationBase from '@material-ui/lab/Pagination'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
  pagination: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}))

const Pagination = ({ onChangeFn, total = 0 }) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    onChangeFn(value)
  }

  React.useEffect(() => {
    setPage(1)
  }, [total])

  return (
    <PaginationBase
      data-testid="pagination"
      className={classes.pagination}
      count={total}
      page={page}
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  )
}

Pagination.displayName = 'Pagination'
Pagination.propTypes = {
  onChangeFn: PropTypes.func.isRequired,
  total: PropTypes.number,
}

export default Pagination
