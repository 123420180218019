/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import CheckBase from '@material-ui/icons/Check'
import React from 'react'
import styled from 'styled-components'

import { ButtonBase, DefaultBox, TextBase } from '~/pages/Layout/DefaultLayout'
import colors from '~/utils/colors'

export const TextBold = styled(TextBase)`
  &&& {
    font-weight: bold;
  }
`

export const DarkGray = styled.span`
  &&& {
    color: #52575c;
  }
`

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    /* color: #f6c209; */
    margin-right: 8px;
  }
`

export const Button = styled(ButtonBase)`
  &&& {
    background-color: ${colors.bluePrimary};
    text-transform: initial !important;
    color: white;
    & span {
      text-transform: 'initial !important';
    }
  }
`

function ReactivateYourSubscription() {
  return (
    <>
      <DefaultBox>
        <TextBold variant="h4" gutterBottom>
          Reative sua assinatura
        </TextBold>
        <TextBase variant="body2" gutterBottom>
          <DarkGray>
            Sua assinatura está cancelada e você poderá perder acesso aos seus recursos no fim do
            período dela. Assine novamente e continue com todas as funcionalidades que você tem
            hoje.
          </DarkGray>
        </TextBase>
        <Box mt={2}>
          <Button
            variant="contained"
            onClick={() => (window.location.href = `${process.env.REACT_APP_PLANS_URL}`)}
          >
            Ver Planos Disponíveis
          </Button>
        </Box>
      </DefaultBox>
    </>
  )
}

ReactivateYourSubscription.displayName = 'ReactivateYourSubscription'

export default ReactivateYourSubscription
