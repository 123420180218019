/* eslint-disable react/prop-types */
// import { Container } from './styles';
import { Box as BoxBase, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import styled from 'styled-components'

import colors from '~/utils/colors'

export const Box = styled.div`
  &&& {
    position: relative;
    padding: 16px;
    background: #ffffff;
    border: none;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 10px !important;
    ${({ mouserHover }) =>
      mouserHover &&
      `
      &:hover {
        background-color: rgba(0, 0, 0, 0.04)
      }
    `}
  }
`

const Title = withStyles({
  root: {
    fontFamily: 'Nunito Sans',
    color: colors.dark100,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    margin: 0,
    paddingBottom: '32px',
  },
})(Typography)

export const SubtitleBase = withStyles({
  root: {
    fontFamily: 'Nunito Sans',
    color: '#52575C',
  },
})(Typography)

export const TextBase = withStyles({
  root: {
    fontFamily: 'Nunito Sans',
  },
})(Typography)

export const ButtonBase = withStyles({
  root: {
    color: colors.bluePrimary,
    borderColor: colors.bluePrimary,

    borderRadius: '8px !important',
    borderWidth: 2,
    // margin: "0px 15px",
    padding: '5px 40px',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',

    textTransform: 'capitalize',

    '&:hover': {
      background: 'rgba(0,0,0,0)',
      borderColor: colors.bluePrimary,
    },
  },
})(Button)

export const ButtonContained = withStyles({
  root: {
    backgroundColor: colors.bluePrimary,
    textTransform: 'initial !important',
    color: 'white',
    '& span': {
      textTransform: 'initial !important',
    },
    '&:hover': {
      backgroundColor: colors.bluePrimary,
    },
  },
})(ButtonBase)

export const SkeletonBox = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    // minWidth: '100px',
    minHeight: '5rem',
  },
})(BoxBase)

export const SkeletonText = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    // minWidth: '100px',
    minHeight: '1.3rem',
  },
})(Typography)

// const Box = withStyles({
//   root: {
//     position: 'relative',
//     padding: '16px',
//     background: '#FFFFFF',
//     border: 'none',
//     boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
//     borderRadius: '10px !important',
//   },
// })(BoxBase)

export function DefaultLayout({ children }) {
  return <BoxBase m={4}>{children}</BoxBase>
}

export function DefaultTitle({ children, ...rest }) {
  return (
    <Title variant="h6" gutterBottom {...rest}>
      {children}
    </Title>
  )
}

export function Subtitle({ children }) {
  return (
    <SubtitleBase variant="body2" gutterBottom>
      {children}
    </SubtitleBase>
  )
}

export function DefaultBox({ children, mouserHover = undefined, ...rest }) {
  return (
    <Box mouserHover={mouserHover} {...rest}>
      {children}
    </Box>
  )
}

export function timezone(date) {
  return new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
}
