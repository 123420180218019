import { Input as _InputBase } from '@material-ui/core'
import * as React from 'react'
import styled from 'styled-components'

const Input = styled(_InputBase)`
  &&& {
    border: 2px solid #edf0f7;
    border-radius: 8px;
    font-family: Nunito Sans;
    font-size: 15px;
    margin: 15px 0px 0px;
    padding: 8px;
    padding-left: 20px;

    input {
      padding: 0px;
      ::-webkit-input-placeholder {
        opacity: 1;
        color: #52575c;
      }
    }
  }
`

type InputBaseProps = {
  name: string
  placeholder?: string
  handleChange?: (props: any) => void
  type?: string
  value?: string
  required?: boolean
  fullWidth?: boolean
  disableUnderline?: boolean
  startAdornment?: JSX.Element
}

const InputBase = ({ name, placeholder, type, value, handleChange, ...rest }: InputBaseProps) => (
  <Input
    id={name}
    type={type ? type : 'text'}
    name={name}
    placeholder={placeholder}
    autoComplete={name}
    value={value}
    onChange={handleChange}
    style={{
      border: '2px solid #EDF0F7',
      borderRadius: 8,
      fontFamily: 'Nunito Sans',
      color: '#52575C',
      fontSize: 15,
      margin: '15px 0px',
      paddingLeft: '20px',
    }}
    {...rest}
  />
)

export default InputBase
