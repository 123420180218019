/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import React from 'react'

import { SkeletonBox, SkeletonText } from '~/pages/Layout/DefaultLayout'

function SkeletonDetails() {
  return (
    <>
      <SkeletonText variant="h6" gutterBottom />
      <SkeletonText gutterBottom />
      <SkeletonBox />
      <Box mt={2}>
        <SkeletonBox style={{ minHeight: '9rem' }} />
      </Box>
    </>
  )
}

export default SkeletonDetails
