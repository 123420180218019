import { Box, Icon, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Cancel as CancelIcon, Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import * as canvasServices from '~/services/canvasServices'

import { useBoard } from '../../context/BoardProvider'

const sharedStyle = css`
  font-size: 1.2rem;
`

const CancelIconStyled = styled(CancelIcon)`
  ${sharedStyle}/* some non-shared styles */
`
const EditIconStyled = styled(EditIcon)`
  ${sharedStyle}/* some non-shared styles */
`
const SaveIconStyled = styled(SaveIcon)`
  ${sharedStyle}/* some non-shared styles */
`

export default function EditCanvasName() {
  const dispatch = useAppDispatch()
  const {
    board: { id, name: nameBase, nodes },
  } = useBoard()
  const [editMode, setEditMode] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    setName(nameBase)
  }, [nameBase])

  const handleChange = (event) => setName(event.target.value)

  const toggleEdit = () => setEditMode((prevState) => !prevState)

  const handleSave = () => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: { show: true, background: true },
    })
    canvasServices
      .update(id, { name, nodes })
      .then(() => {
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Board salvo com sucesso',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema. Tenta por favor mais tarde!',
            variant: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({
          type: TOGGLE_LOADING,
          payload: { show: false, background: true },
        })
        setEditMode(false)
      })
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        ml={2}
        // className={classes.root}
      >
        {!editMode ? (
          <Typography variant="h6" noWrap>
            {name}
          </Typography>
        ) : (
          <TextField
            name="canvasNama"
            value={name}
            size="small"
            onChange={handleChange}
            // disabled={!editMode}
            // className={classes.textField}
          />
        )}
        {!editMode ? (
          <Box>
            <IconButton aria-label="edit" onClick={toggleEdit}>
              <EditIconStyled />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-around">
            <IconButton aria-label="save" onClick={handleSave}>
              <SaveIconStyled />
            </IconButton>
            <IconButton aria-label="delete" onClick={toggleEdit}>
              <CancelIconStyled />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}
