import { Button } from '@material-ui/core'
import React from 'react'

import { TextBase } from '~/pages/Layout/DefaultLayout'

import { useStyles } from '../../styles'

function BlockMessage({ name, message, handleClick }) {
  const classes = useStyles()

  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '20px',
      }}
    >
      <TextBase variant="body1" style={{ fontWeight: 'bold' }}>
        {message}
      </TextBase>
      <Button variant="contained" onClick={handleClick} className={classes.button}>
        Atualizar para o plano {name}
      </Button>
    </div>
  )
}

export default BlockMessage
