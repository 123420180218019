import PropTypes from 'prop-types'
import React from 'react'

function YouTubeVideo({ id }) {
  return (
    <iframe
      width="100%"
      height="263"
      src={`https://www.youtube.com/embed/${id}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )
}

YouTubeVideo.propTypes = {
  id: PropTypes.string.isRequired,
}

export default YouTubeVideo
