import { Box } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import makefunnelsLogo from '~/assets/images/makefunnels-logo.svg'
import { ASYNC_STATE } from '~/constants'
import { useAlert } from '~/hooks/useAlert'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'
import { ButtonContained } from '~/pages/Layout/DefaultLayout'
import { getSubscriptionCancelationReasons, postSubscriptionChange } from '~/services/subscription'
import LoadingPage from '~/utils/components/LoadingPage'
import { useAsync } from '~/utils/useAsync'

import { AlertModal, useIsOpen } from './components/AlertModal'
import RadioGroup from './components/RadioGroup'
import { AppBar, Brand, Content, ContentTitle, Image, Link, Root, Text, Title } from './styles'

const FeedBackCancel = () => {
  const history = useHistory()
  const location = useLocation()
  const { setAlert } = useAlert()
  const { planCode } = useParams()
  const asyncRequest = useAsync()
  const reasons = useAsync()
  const { open, toggle } = useIsOpen()
  const { data, error, status } = useLoadPlanByCode(planCode)
  const [value, setValue] = React.useState('')
  const textAreaRef = React.useRef(null)
  const loading = status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE
  const loadingRequest = asyncRequest.status === ASYNC_STATE.PENDING

  useEffect(() => {
    if (asyncRequest.status === ASYNC_STATE.RESOLVED)
      return history.push('/plans', {
        reloadPlan: true,
      })
  }, [asyncRequest.status, history])

  useEffect(() => {
    if (asyncRequest.status === ASYNC_STATE.REJECTED) {
      const error = asyncRequest.error
      return setAlert(error, 'error')
    }
  }, [asyncRequest.error, asyncRequest.status, setAlert])

  useEffect(() => {
    reasons.run(getSubscriptionCancelationReasons())
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleClick = () => {
    const resourceData = location.state
    const downGradeReason = textAreaRef.current.value
    const request = {
      offer_code: planCode,
      cancellation_reason_id: value,
    }
    if (downGradeReason) request.cancellation_feedback = downGradeReason
    if (resourceData) request.resources = resourceData

    asyncRequest.run(postSubscriptionChange(request))
  }

  if (loading) return <LoadingPage />

  return (
    <>
      <Root>
        <AppBar position="fixed">
          <Toolbar>
            <Brand href="/">
              <Image src={makefunnelsLogo} alt="makefunnels logo" />
            </Brand>
          </Toolbar>
        </AppBar>
        <Content>
          <>
            <ContentTitle>
              <Title>Rebaixar para o {data.name}</Title>
              <Text variant="body2" style={{ color: '#F44336' }}>
                Você perderá funcionalidades do seu plano atual.
              </Text>
            </ContentTitle>
            <Box mt={3}>
              <Text variant="h6">Feedback</Text>
              <Text variant="body2">
                Para nos ajudar a melhorar nossos serviços, por favor, nos diga: Por que decidiu
                rebaixar o seu plano nesse momento?
              </Text>
            </Box>
            <Box mt={4}>
              <RadioGroup
                value={value}
                items={reasons.data}
                loading={
                  reasons.status === ASYNC_STATE.PENDING || reasons.status === ASYNC_STATE.IDLE
                }
                handleChange={handleChange}
                ref={textAreaRef}
              />
            </Box>
            {/* <Box mt={4}>
              <Text variant="body2">
                O período de sua assinatura terminará em EXP_DATA, e neste momento sua conta
                regressará para o plano {data.name} e todas as funcionalidades adicionais de seu
                plano corrente serão desabilitados.
              </Text>
            </Box> */}
            <Box mt={3} display="flex" justifyContent="space-between" alignItems={'center'}>
              <ButtonContained variant="contained" disabled={!value} onClick={toggle}>
                Continuar
              </ButtonContained>
              <Text variant="body1">
                <Link to="/settings">Continuar usando a assinatura atual</Link>
              </Text>
            </Box>
          </>
        </Content>
      </Root>
      <AlertModal
        open={open}
        toggle={toggle}
        continueClick={handleClick}
        loading={loadingRequest}
      />
    </>
  )
}

export default FeedBackCancel
