import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  progress: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    zIndex: 9999,
    width: '100vw',
    left: 0,
    height: '100vh',
    '&.background': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
}))

function LoadingPage() {
  const classes = useStyles()

  return (
    <div className={classes.progress}>
      <CircularProgress />
    </div>
  )
}

export default LoadingPage
