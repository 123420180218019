import React from 'react'

import { getCurrentSubscription } from '~/services/subscription'
import { Subscription } from '~/types'
import { useAsync } from '~/utils/useAsync'

interface useLoadCurrentPlanWithResourcesResponse {
  data: Subscription | null
  status: string
  error: string | null
}

function useLoadCurrentPlanWithResources(): useLoadCurrentPlanWithResourcesResponse {
  const { data, status, error, run } = useAsync()
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
    if (mounted.current) {
      run(getCurrentSubscription({ params: [{ key: 'include', value: 'resources' }] }))
    }
    return () => {
      mounted.current = false
    }
  }, [run])

  return {
    data,
    status,
    error,
  }
}

export { useLoadCurrentPlanWithResources }
