import React, { useEffect, useState } from 'react'

import Onboarding from '~/components/Onboarding'
import { TOGGLE_LOADING } from '~/constants'
import { ONBOARDING_VIDEOS, PAGES } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'

import View from './DomainsListView'

function DomainsListController() {
  const dispatchApp = useAppDispatch()
  const { domains } = useAppState()
  const [arrDomains, setArrDomains] = useState(null)

  useEffect(() => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: domains.isFetching, background: false },
    })

    setArrDomains(domains.data)
  }, [dispatchApp, domains])

  function handleSearch(arr, value) {
    let newArr = domains.data

    const aux = newArr.filter((el) => el.name.indexOf(value.toLowerCase()) !== -1)

    if (aux.length) setArrDomains(aux)
    else setArrDomains([])
  }

  return (
    <>
      <Onboarding page={PAGES.MY_DOMAINS} video={ONBOARDING_VIDEOS.CONFIG_DOMAIN} />
      <View domains={arrDomains} isFetching={domains.isFetching} handleSearch={handleSearch} />
    </>
  )
}

export default DomainsListController
