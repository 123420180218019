/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { Image, Text } from 'react-konva'

import { EVENT_TYPE } from '../../constants'
import { ELEMENT_EVENT, ELEMENT_PAGE } from '../../constants/elementsType'
import useLoadImage from '../../hooks/useLoadImage'
import { Border } from '../Border'

const PageCard = forwardRef(function PageCard(
  {
    id,
    name,
    type,
    src,
    position,
    onDragMove,
    handleClick,
    isSelected,
    handleAnchorDragEnd,
    handleAnchorDragMove,
    handleAnchorDragStart,
    handleDblClick,
  },
  ref,
) {
  const image = useLoadImage({ src })
  const isEventType = type === EVENT_TYPE
  const width = isEventType ? ELEMENT_EVENT.dimension.w : ELEMENT_PAGE.dimension.w
  const height = isEventType ? ELEMENT_EVENT.dimension.h : ELEMENT_PAGE.dimension.h

  const textPosition = {
    x: position.x,
    y: position.y + height + 4,
  }

  return (
    <>
      {isSelected && (
        <Border
          id={id}
          position={position}
          width={width}
          height={height}
          onAnchorDragEnd={handleAnchorDragEnd}
          onAnchorDragMove={handleAnchorDragMove}
          onAnchorDragStart={handleAnchorDragStart}
        />
      )}
      <Text
        x={textPosition.x}
        y={textPosition.y}
        width={width}
        text={name}
        align="center"
        fontStyle="bold"
      />
      <Image
        ref={ref}
        x={position.x}
        y={position.y}
        width={width}
        height={height}
        draggable
        onClick={handleClick}
        onDblClick={handleDblClick}
        onDragMove={onDragMove}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'pointer'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
      />
    </>
  )
})

PageCard.propTypes = {
  color: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  onDragMove: PropTypes.func.isRequired,
}

export default PageCard
