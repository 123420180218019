import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiSnackbar-root': {
      zIndex: 99999,
    },
  },
}))

function AlertView({ open, message, variant, noHide }) {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  function handleClose() {
    dispatch({ type: SET_ALERT, payload: { open: false } })
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={noHide ? null : 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertView
