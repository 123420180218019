/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ASYNC_STATE, PLAN_CODE } from '~/constants'
import { useAppState } from '~/context'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'
import * as localStorage from '~/utils/localStorage'

import DialogContent from '../../components/DialogContent'

function FixedContent() {
  const history = useHistory()
  const { currentSubscription } = useAppState()
  const {
    plan: { plan },
  } = currentSubscription
  const nextPlan = plan?.can_upgrade_to_plans?.[0]
  const { data, error, status } = useLoadPlanByCode(nextPlan)
  const { name, features, code, default_offer_index, offers } = data || {}
  const { offer_code } = default_offer_index !== undefined && offers?.[default_offer_index]

  const redirectToUpgradePage = () => {
    const token = localStorage.get('@makeFunnels/token')

    if (plan.code === PLAN_CODE.FREE) {
      return (window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}${offer_code}?token=${token}`)
    }

    return history.push(`/upgrade/${code}`)
  }

  if (error || status === ASYNC_STATE.REJECTED) return null

  if (status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE) return null

  return <DialogContent features={features} name={name} handleClick={redirectToUpgradePage} />
}

export { FixedContent }
