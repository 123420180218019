import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAlert } from '~/hooks/useAlert'
import { useLoading } from '~/hooks/useLoading'
import { getSharedFunnelDetails, importFunnelTemplate } from '~/services/funnelService'
import { useAsync } from '~/utils/useAsync'

import { SharedFunnel } from '../types'

type SharedFunnelResponse = {
  data: SharedFunnel
  error: Error | null
  run: (...args: unknown[]) => unknown
  status: string
}

export function useSharedFunnel(funnelId: string) {
  const { data, error, run, status }: SharedFunnelResponse = useAsync()
  const { isLoading } = useLoading()
  const history = useHistory()
  const { setAlert } = useAlert()

  useEffect(() => {
    run(getSharedFunnelDetails(funnelId))
  }, [])

  function importFunnel({ name, domain }: { name: string; domain: number }) {
    const data = {
      shared_funnel_id: funnelId,
      name,
      domain,
    }
    isLoading(true)
    importFunnelTemplate(funnelId, data)
      .then(() => {
        setAlert('Funil Importado com sucesso', 'success')
        return history.push(`/`)
      })
      .catch((error) => setAlert(error, 'error'))
      .finally(() => isLoading(false))
  }

  return {
    data,
    error,
    status,
    importFunnel,
  }
}
