import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { default as ButtonBase } from '~/components/BaseButton'
import ModalComponent from '~/components/Modal'
import { clearFunnelMetrics } from '~/services/funnelService'

const Button = styled(ButtonBase)`
  text-transform: capitalize !important;
`

const ClearMetrics = ({ id, reload }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const buttons = [
    {
      variant: 'outlined',
      customClass: 'default',
      name: 'Cancelar',
      // disabled: integrationName !== integration.name,
      handleSubmit: () => handleClose(),
    },
    {
      variant: 'outlined',
      customClass: 'error',
      name: 'Sim, limpar métricas',
      // disabled: integrationName !== integration.name,
      handleSubmit: () => {
        clear()
      },
    },
  ]

  const clear = () => {
    clearFunnelMetrics(id).then(() => {
      handleClose()
      reload()
    })
  }

  return (
    <>
      <Box
        pl={3}
        sx={{
          '& button': {
            fontWeight: 400,
            fontSize: '13px',
          },
        }}
      >
        {/* <Button variant="outlined" onClick={}>
          Limpar Métrica desse funil
        </Button> */}
        <Button
          size="large"
          variant="outlined"
          handleClick={() => setIsOpen(true)}
          color="primary"
          text="Limpar Métrica desse funil"
          fullWidth={false}
          // startIcon={<AddIcon />}
        />
      </Box>
      <ModalComponent
        actions={{ handleClose }}
        state={{
          open: isOpen,
          title: 'Tem certeza?',
          buttons,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tem certeza que deseja limpar as métricas desse funil?
        </Typography>
        <Typography variant="body2" className="disclaimer" my={3}>
          Esta é uma operação irreversível, as métricas desse funil serão zeradas.
        </Typography>
      </ModalComponent>
    </>
  )
}

ClearMetrics.propTypes = {
  id: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
}

export default ClearMetrics
