import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import BaseModal from '~/components/Modal'

import YouTubeVideo from './components/YouTubeVideo'
import { useOnboarding } from './utils/useOnboarding'

const Onboarding = ({ page, video }) => {
  const [isDismiss, dismissDispatch] = useOnboarding(page)

  const [open, setOpen] = React.useState(!isDismiss)

  const close = () => {
    setOpen(false)
  }

  const dismiss = () => {
    setOpen(false)
    dismissDispatch()
  }

  const buttons = [
    {
      name: 'Não mostrar novamente',
      variant: 'outlined',
      handleSubmit: dismiss,
    },
    {
      name: 'Fechar',
      variant: 'contained',
      handleSubmit: close,
    },
  ]

  return (
    <BaseModal
      actions={{ handleClose: close }}
      state={{
        open,
        isLarge: true,
        title: video.title,
        buttons,
      }}
    >
      <YouTubeVideo {...video} />
      <Typography variant="body2">
        Você poderá ver esse e outros vídeos posteriormente, na sessão Tutoriais, no menu de
        navegação lateral, à esquerda.
      </Typography>
    </BaseModal>
  )
}

Onboarding.propTypes = {
  page: PropTypes.string.isRequired,
  video: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default Onboarding
