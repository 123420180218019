import { Box, Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { DEFAULT_MAKEFUNNELS_FAVICON } from '~/constants'
import colors from '~/utils/colors'

const useStyles = makeStyles({
  button: {
    borderRadius: '8px !important',
    color: `${colors.bluePrimary} !important`,
    borderColor: `${colors.bluePrimary} !important`,
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
})
function View({ state: { img }, actions: { handleFile, resetFile } }) {
  const classes = useStyles()
  return (
    <form>
      <Box display="flex" paddingBottom="50px">
        <Box
          style={{
            width: 100,
            height: 100,
            border: '1px solid #CFD0E7',
            marginRight: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{ maxWidth: '100px', maxHeight: '100px' }}
            src={img.src}
            alt="preview da imagem"
          />
        </Box>
        <Box
          style={{
            fontFamily: 'Nunito Sans',
          }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <span style={{ color: '#52575C', fontSize: 14, fontWeight: 600 }}>
            {img.name}
            <br />
            <span style={{ color: '#CFD0E7', fontSize: 12 }}>{img.updatedAt}</span>
          </span>
          <Box>
            <Button className={classes.button} variant="outlined" component="label">
              Alterar imagem
              <input type="file" onChange={handleFile} accept="image/ico" hidden id="favicon" />
            </Button>
            {img.src !== DEFAULT_MAKEFUNNELS_FAVICON ? (
              <Button
                variant="text"
                text="Remover"
                onClick={resetFile}
                style={{ margin: '0 10px', textTransform: 'capitalize', width: 100 }}
              >
                Remover
              </Button>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Box>
    </form>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    img: PropTypes.shape({
      src: PropTypes.string,
      name: PropTypes.string,
      updatedAt: PropTypes.any,
    }),
  }),
  actions: PropTypes.shape({
    handleFile: PropTypes.func,
    resetFile: PropTypes.func,
    saveFavicon: PropTypes.func,
  }),
}

export default View
