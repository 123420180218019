import { Box, Tooltip, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import React from 'react'

const DefaultIcon = () => <ErrorIcon color="secondary" />

const TooltipStatus = ({ title, Icon = DefaultIcon, label, ...rest }) => {
  return (
    <Tooltip title={title}>
      <Box justifyContent="center" alignItems="center" display="flex" {...rest}>
        <Icon />
        <Typography variant="caption">{label}</Typography>
      </Box>
    </Tooltip>
  )
}

export default TooltipStatus
