import axios from './config/axios'
const BASE_URL = '/redirectionpages'

async function genericRequest(dispatchApp, type, url, payload = null, config = {}) {
  try {
    const response = await axios[type](url, payload, config)

    const { data } = response

    const res = data.data ? data.data : data
    return Promise.resolve(res)
  } catch (error) {
    return Promise.reject(error.response.data.error)
  }
}

export const readAll = async () => {
  try {
    const response = await axios.get(BASE_URL)
    return Promise.resolve(response.data.data)
  } catch (error) {
    return Promise.reject(error.response.data)
  }
}

export const readOne = (dispatchApp, id) => genericRequest(dispatchApp, 'get', `${BASE_URL}/${id}`)

const initMultiPartFormat = (data) => {
  const { links, tracking, ...rest } = data

  const formData = new FormData()
  Object.keys(rest).map((key) => {
    return formData.append(key, data[key])
  })

  links &&
    links.map((link, index) => {
      return Object.keys(link).map((key) => {
        return formData.append(`links[${index}][${key}]`, link[key])
      })
    })
  tracking &&
    tracking.map((track, index) => {
      return Object.keys(track).map((key) => {
        return formData.append(`tracking[${index}][${key}]`, track[key])
      })
    })
  return [
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  ]
}

export const create = (dispatchApp, data) => {
  const [formData, config] = initMultiPartFormat(data)
  return genericRequest(dispatchApp, 'post', BASE_URL, formData, config) //axios.post(`/domains`, data)
}

// new attribute no formdata _method = "PUT"
export const edit = (dispatchApp, id, data) => {
  const [formData, config] = initMultiPartFormat(data)
  return genericRequest(dispatchApp, 'post', `${BASE_URL}/${id}`, formData, config)
}

export const softDelete = (id) => axios.delete(`/redirectionpages/${id}`)

export const duplicateRedirect = (id) => axios.post(`/redirectionpages/${id}/duplicate`)
