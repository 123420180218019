import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

import BoardsEmpty from './components/BoardsEmpty'
import BoardsList from './components/BoardsList'
import NewBoard from './components/Modal/NewBoard'
import { BoardsProvider, useBoards } from './context/BoardsProvider'
import { useStyles } from './styles'

function Boards() {
  const classes = useStyles()
  const { boards, isFetching } = useBoards()

  if (isFetching) {
    return null
  }

  return (
    <Box mx={4} my={5}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box className={classes.header}>
          <Typography variant="h6">Meus Quadros</Typography>
          <Typography variant="body2" gutterBottom style={{ marginTop: 10 }}>
            Sua lista de quadros
          </Typography>
        </Box>
        <NewBoard />
      </Box>
      <Box marginTop="2rem">
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Grid container>
            <Grid item lg={12}>
              {boards?.length ? <BoardsList items={boards} /> : <BoardsEmpty />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

const BoardsWithContext = () => (
  <BoardsProvider>
    <Boards />
  </BoardsProvider>
)

export default BoardsWithContext
