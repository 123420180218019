import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { TOGGLE_LOADING } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { filterByStatus } from '~/utils/domainFilter'

import FunnelTableView from './funnelTableView'

function FunnelTableController({ handleSearch, arrFilter }) {
  const dispatchApp = useAppDispatch()
  const { domains } = useAppState()
  const [arrDomains, setArrDomains] = useState([])

  const domainsData = (arr) => {
    if (!arr) return []
    return filterByStatus(arr, 'active')
  }

  useEffect(() => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: domains.isFetching, background: false },
    })
  }, [dispatchApp, domains])

  useEffect(() => {
    let auxDomains = domainsData(domains.data)

    if (arrFilter) {
      const aux = handleSearch(auxDomains, arrFilter)
      setArrDomains(aux)
    } else {
      setArrDomains(auxDomains)
    }
  }, [arrFilter, domains.data, handleSearch])

  return (
    <FunnelTableView
      domains={{
        isFetching: domains.isFetching,
        data: arrDomains,
      }}
    />
  )
}

FunnelTableController.propTypes = {
  handleSearch: PropTypes.func,
  arrFilter: PropTypes.string,
}

export default FunnelTableController
