import { TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import FunnelTableController from '../funnelTable/funnelTableController'

const useRowStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontFamily: 'Nunito Sans',
    '& h6': {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      margin: 0,
    },
  },
  search: {
    width: 400,
    height: 40,
    background: '#FFFFFF',
    borderRadius: 50,
    '& fieldset': {
      height: 45,
      borderRadius: 50,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& div': {
      height: 40,
    },
    '& input': {
      padding: '0 40px !important',
    },
    '& svg': {
      color: '#A9ABAE',
    },
  },
})

export default function Home({ handleSearch }) {
  const classes = useRowStyles()
  const [searchValue, setSearchValue] = useState('')
  return (
    <Box m={4}>
      <Box mb={4} className={classes.header}>
        <Typography variant="h6" gutterBottom>
          Meus Funis
        </Typography>
        <Box className={classes.search}>
          <TextField
            variant="outlined"
            fullWidth
            id="search"
            name="search"
            placeholder="Pesquisar"
            onChange={(e) => {
              const { value } = e.target

              setSearchValue(value)
            }}
            value={searchValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      {/* <hr /> */}
      {/* <Container fixed> */}
      <FunnelTableController handleSearch={handleSearch} arrFilter={searchValue} />
      {/* </Container> */}
    </Box>
  )
}

Home.propTypes = {
  handleSearch: PropTypes.func,
}
