import { Link as LinkBase } from 'react-router-dom'
import styled from 'styled-components'

import { TextBase } from '~/pages/Layout/DefaultLayout'

export const Link = styled(LinkBase)`
  &&& {
    color: #4c6fff;
  }
`

export const TextBold = styled(TextBase)`
  &&& {
    font-weight: bold;
  }
`

export const Sumary = styled.span`
  &&& {
    color: #52575c;
  }
`
