// import { Box } from '@material-ui/core'
import { Box } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'

import { DefaultBox, DefaultLayout, DefaultTitle } from '../Layout/DefaultLayout'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Loading } from './components/Loading'
import { loadSettings, SettingsProvider, useSettings } from './context/settings-context'
import { Account, FooterBrandShare, Plans } from './modules'

function Settings() {
  const [{ loading }, dispatch] = useSettings()
  const { beta } = useParams()

  React.useEffect(() => {
    loadSettings(dispatch)
  }, [dispatch])

  return (
    <ErrorBoundary>
      <DefaultLayout>
        <DefaultTitle>Configurações</DefaultTitle>
        {beta && (
          <Box>
            <DefaultBox mouserHover>
              <Account />
            </DefaultBox>
          </Box>
        )}
        <Box mt={2}>
          <DefaultBox mouserHover>
            <Plans />
          </DefaultBox>
        </Box>
        <Box mt={2}>
          {loading ? (
            <Loading />
          ) : (
            <DefaultBox>
              <FooterBrandShare />
            </DefaultBox>
          )}
        </Box>
      </DefaultLayout>
    </ErrorBoundary>
  )
}

const SettingsRender = () => (
  <SettingsProvider>
    <Settings />
  </SettingsProvider>
)

export default SettingsRender
