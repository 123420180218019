import axios from 'axios'

import * as localStorage from '../../utils/localStorage'

const axiosNewInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const token = localStorage.get('@makeFunnels/token')

axiosNewInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
axiosNewInstance.defaults.headers.common['accept-language'] = 'pt_BR'

export default axiosNewInstance
