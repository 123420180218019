import { Box } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import makefunnelsLogo from '~/assets/images/makefunnels-logo.svg'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { ASYNC_STATE, PLAN_CODE } from '~/constants'
import { useAppState } from '~/context'
import { useAlert } from '~/hooks/useAlert'
import { ButtonContained } from '~/pages/Layout/DefaultLayout'
import { postSubscriptionChange } from '~/services/subscription'
import LoadingPage from '~/utils/components/LoadingPage'
import * as localStorage from '~/utils/localStorage'
import { useAsync } from '~/utils/useAsync'

import { useLoadPlan } from './hooks/useLoadPlan'
import { Payment } from './modules/Payment'
import { AppBar, Brand, Content, ContentTitle, Image, Root, SubTitle, Title } from './styles'

const Upgrade = () => {
  let history = useHistory()
  const { setAlert } = useAlert()
  let { planCode } = useParams()
  const asyncRequest = useAsync()
  const { currentSubscription } = useAppState()
  const { data: plan, status, error } = useLoadPlan(planCode)
  const currentOffer = plan?.offers[0]
  const loading = status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE
  const loadingRequest = asyncRequest.status === ASYNC_STATE.PENDING

  useEffect(() => {
    if (asyncRequest.status === ASYNC_STATE.RESOLVED) {
      history.push('/plans', {
        reloadPlan: true,
      })
      return setAlert('Parabéns. Upgrade feito com sucesso!', 'success')
    }
    if (asyncRequest.status === ASYNC_STATE.REJECTED)
      return setAlert(
        'Não foi possível executar esta operação. Por favor entre em contato com o suporte via email',
        'error',
      )
  }, [asyncRequest.status])

  if (!planCode) {
    history.replace('/settings')
  }

  const handleClick = () => {
    const {
      plan: { plan },
    } = currentSubscription
    const token = localStorage.get('@makeFunnels/token')

    if (plan.code === PLAN_CODE.FREE) {
      window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}${planCode}?token=${token}`
      return
    }

    return asyncRequest.run(
      postSubscriptionChange({
        offer_code: currentOffer.offer_code,
      }),
    )
  }

  return (
    <Root>
      <AppBar position="fixed">
        <Toolbar>
          <Brand href="/">
            <Image src={makefunnelsLogo} alt="makefunnels logo" />
          </Brand>
        </Toolbar>
      </AppBar>
      <Content>
        {loading ? (
          <LoadingPage />
        ) : (
          <ErrorBoundary error={error}>
            <>
              <ContentTitle>
                <Title>Upgrade para o plano {plan?.name}</Title>
                <SubTitle variant="body2">
                  Você está atualizando o seu plano. Veja o resumo da cobrança e confirme apertando
                  no botão abaixo.
                </SubTitle>
              </ContentTitle>
              <Box mt={3}>
                <Payment plan={plan} offer={currentOffer} />
              </Box>
              <Box mt={3}>
                <ButtonContained
                  onClick={handleClick}
                  variant="contained"
                  disabled={loadingRequest}
                >
                  {loadingRequest ? 'Processando...' : 'Sim, atualize meu plano'}
                </ButtonContained>
              </Box>
              <Box mt={4}>
                <SubTitle variant="body2">
                  Ao apertar no botão acima, atualizaremos sua assinatura para o plano {plan?.name}{' '}
                  e será renovada automaticamente. Você poderá cancelar quando quiser em sua conta.
                </SubTitle>
              </Box>
            </>
          </ErrorBoundary>
        )}
      </Content>
    </Root>
  )
}

export default Upgrade
