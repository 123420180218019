/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Box } from '@material-ui/core'
import React, { memo } from 'react'

import BoardsItem from '../BoardsItem'
import { useStyles } from './styles'

const BoardsList = memo(
  ({ items }) => {
    const classes = useStyles()

    return (
      <Box className={classes.wrapper}>
        {items?.map((item, idx) => (
          <BoardsItem key={idx} item={item} />
        ))}
      </Box>
    )
  },
  ({ items: prevItems }, { items: nextItems }) => {
    // Memo allows to avoid flashing list order change before user is redirected when user clicks on a recent search
    return prevItems.length === nextItems.length
  },
)

export default BoardsList
