import { Box, Grid, Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import PropTypes from 'prop-types'
import React from 'react'

import { FeatureBlock } from '../UpgradeModal'
import { useStyles } from './styles'

function CustomTable({ state: { header, rows }, isDisabled }) {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {isDisabled && (
        <FeatureBlock message="Para visualizar as métricas completas do seu funil, faça upgrade do seu plano." />
      )}
      <Box className={`${classes.root} ${isDisabled && 'disabled'}`}>
        <Grid className={classes.table} aria-label="simple table">
          <Box className={classes.header}>
            {header.map((el, index) => (
              <Tooltip key={index} title={el.tooltip || ''} placement="top">
                <Box className="item" align={el.align}>
                  {el.name}{' '}
                  {el.tooltip && <HelpOutlineIcon style={{ height: 18, color: '#888' }} />}
                </Box>
              </Tooltip>
            ))}
          </Box>
          {rows.map((row, index) => {
            const keys = [
              'step',
              'name',
              'visits',
              'unique_visits',
              'conversion',
              'general_conversion',
            ] //Object.keys(row)
            return (
              <Box className={classes.info} key={index}>
                {keys.map((val, index) => (
                  <Box key={index} align="center" className="item">
                    {row[val]}
                  </Box>
                ))}
              </Box>
            )
          })}
        </Grid>
      </Box>
    </div>
  )
}

CustomTable.propTypes = {
  state: PropTypes.shape({
    rows: PropTypes.any,
    header: PropTypes.any,
  }),
}

export default CustomTable
