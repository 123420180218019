/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react'
import { Group, Line, Rect } from 'react-konva'

function dragBounds(ref) {
  if (ref.current !== null) {
    return ref.current.getAbsolutePosition()
  }
  return {
    x: 0,
    y: 0,
  }
}

export function Anchor({ x, y, id, onDragStart, onDragMove, onDragEnd }) {
  const anchorGroup = useRef(null)
  const width = 16

  useEffect(() => {
    if (anchorGroup.current) {
      anchorGroup.current.moveToTop()
    }
  }, [])

  return (
    <Group
      ref={anchorGroup}
      x={x - width / 2}
      y={y - width / 2}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container()
        container.style.cursor = 'pointer'
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container()
        container.style.cursor = 'default'
      }}
      draggable
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      dragBoundFunc={() => dragBounds(anchorGroup)}
    >
      <Rect
        fill="#0094FF"
        width={width}
        height={width}
        perfectDrawEnabled={false}
        cornerRadius={2}
      />
      <Line
        points={[width / 2, 4, width / 2, width - 4]}
        stroke="#ffffff"
        strokeWidth={2}
        lineJoin="round"
        lineCap="round"
      />
      <Line
        points={[4, width / 2, width - 4, width / 2]}
        stroke="#ffffff"
        strokeWidth={2}
        lineJoin="round"
        lineCap="round"
      />
    </Group>
  )
}
