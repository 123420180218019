import { SET_ALERT, TOGGLE_LOADING } from '~/constants'

export default (dispatchApp, tryFunction = () => ({}), catchFunction) => {
  dispatchApp({
    type: TOGGLE_LOADING,
    payload: { show: true, background: true },
  })
  try {
    tryFunction()
  } catch (error) {
    typeof catchFunction === 'function'
      ? catchFunction(error)
      : dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema nesta operação. Por favor tente mais tarde.',
            variant: 'error',
          },
        })
  }
  dispatchApp({
    type: TOGGLE_LOADING,
    payload: { show: false, background: true },
  })
}
