import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as InfoIcon } from '~/assets/images/info_icon.svg'

import { useStyles } from './styles'

export default function ModalComponent({
  state: { open, buttons, helper, inputs, title, isLarge, backButton },
  actions: { handleClose },
  children,
  ...rest
}) {
  const classes = useStyles()

  function renderInput(input) {
    const content = {
      textField: (
        <TextField
          id={input.name}
          variant="outlined"
          autoComplete="off"
          placeholder={input.placeholder || ''}
          helperText={input.helper}
          name={input.name}
          value={input.value}
          type={input.type}
          onChange={input.onChange}
          fullWidth={input.fullWidth}
          error={input.error}
        />
      ),
      select: (
        <FormControl fullWidth={input.fullWidth} error={input.error}>
          <Select
            native
            variant="outlined"
            fullWidth={input.fullWidth}
            name={input.name}
            id={input.name}
            value={input.value ? input.value : 0}
            onChange={input.onChange}
          >
            <option value={0} disabled>
              {input.optionDisabled}
            </option>
            {input.options &&
              input.options.map((option, index) => (
                <option key={`${option.name}_${index}`} value={option.value || option.id}>
                  {option.description || option.name}
                </option>
              ))}
          </Select>
          <FormHelperText>{input.helper}</FormHelperText>
        </FormControl>
      ),
      autoComplete: (
        <Autocomplete
          id="combo-box-demo"
          options={input.options}
          getOptionLabel={(option) => option.name}
          fullWidth={input.fullWidth}
          onChange={input.onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className="autocomplete"
              placeholder={input.placeholder}
              label=""
              variant="outlined"
            />
          )}
        />
      ),
    }

    return content[input.component]
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={isLarge ? classes.dialogLarge : classes.dialog}
      {...rest}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography component="p">
          {backButton && (
            <Button onClick={backButton.method} style={{ marginRight: '10px' }}>
              <ArrowBackIosIcon />
            </Button>
          )}
          {title}
        </Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        {children}
        <Box className={classes.input}>
          {inputs &&
            inputs.map((input, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    height: '100%',
                    margin: '36px 0px 0px',
                  }}
                >
                  <InputLabel htmlFor="funnel-name" className={classes.label}>
                    {input.label}
                  </InputLabel>
                  {renderInput(input)}
                </Box>
              )
            })}
        </Box>
      </DialogContent>

      {helper && (
        <DialogContent className={clsx(classes.helper, helper.customClass)}>
          <Grid container>
            <Grid
              item
              xs={2}
              style={{
                alignSelf: 'center',
              }}
            >
              <InfoIcon />
            </Grid>
            <Grid item xs={10}>
              <Box>
                <Typography variant="h6">{helper.title}</Typography>
                <Typography variant="body2">{helper.content}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions className={classes.actions}>
        {buttons &&
          buttons.map((button, index) => {
            return (
              <Button
                key={index}
                variant={button.variant}
                color="secondary"
                className={clsx(classes.action, button.variant, button.customClass)}
                onClick={button.handleSubmit}
                disabled={button.disabled}
              >
                {button.name}
              </Button>
            )
          })}
      </DialogActions>
    </Dialog>
  )
}

export const useModal = () => {
  const [open, setOpen] = React.useState(false)

  const toggle = () => {
    setOpen((prevValue) => !prevValue)
  }

  return {
    open,
    toggle,
  }
}

ModalComponent.propTypes = {
  children: PropTypes.node,
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    buttons: PropTypes.array,
    inputs: PropTypes.array,
    helper: PropTypes.object,
    backButton: PropTypes.object,
    isLarge: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}
