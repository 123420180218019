import React from 'react'

import { useAppState } from '~/context'

import AlertView from './alertView'

function AlertController() {
  const { alert } = useAppState()

  return (
    <AlertView
      open={alert.open}
      message={alert.message}
      variant={alert.variant}
      noHide={alert.noHide}
    />
  )
}

export default AlertController
