import { Subscription } from '~/types'

type getSubscriptionMockResponse = Subscription | null

const defaultSubscription: Subscription = {
  id: 15,
  plan_id: 1,
  status: 'trialing',
  can_upgrade: true,
  plan: {
    name: 'Platinum',
    code: 'PLATINUM',
    offer: {
      offer_code: 'PLATINUM',
      offer_name: 'Platinum',
      offer_price: 12700,
      offer_initial_price: 0,
      offer_initial_period: null,
      offer_initial_interval: null,
      offer_period: 1,
      offer_interval: 'm\u00eas',
      offer_trial_days: 7,
      installments: [],
    },
    can_upgrade_to_plans: ['DIAMOND'],
    features: [
      { name: 'Testes A/B', slug: 'ab-tests', value: 'Y' },
      { name: 'Analytics', slug: 'analytics', value: 'Y' },
      { name: 'Dom\u00ednios', slug: 'domains', value: '6' },
      { name: 'Funis', slug: 'funnels', value: 'U' },
      { name: 'P\u00e1ginas', slug: 'pages', value: 'U' },
      {
        name: 'Redirecionamentos (join-zap)',
        slug: 'redirection-pages',
        value: 'Y',
      },
      {
        name: 'Limite de tr\u00e1fego',
        slug: 'visits-per-month',
        value: '250000',
      },
    ],
  },
  paymentplatform_reference_id: '',
  expires_at: '2030-11-25T03:00:00.000000Z',
  starts_at: null,
  trial_days_left: 0,
  subscriber_id: 1,
  paymentplatform_id: 1,
  affiliate_id: null,
  created_at: '2021-03-17T19:34:39.000000Z',
  updated_at: '2022-05-30T12:48:13.000000Z',
}

export const getSubscriptionMock = (scenario: string): getSubscriptionMockResponse => {
  if (!scenario) return null

  switch (scenario) {
    // platinum paid
    case '1':
      return {
        ...defaultSubscription,
        id: 1,
        status: 'paid',
      }
    // platinum pending
    case '2':
      return {
        ...defaultSubscription,
        id: 2,
        status: 'pending',
      }
    // diamond paid
    case '3':
      return {
        ...defaultSubscription,
        id: 3,
        status: 'paid',
        plan: {
          name: 'Diamond',
          code: 'DIAMOND',
          offer: {
            offer_code: 'DIAMOND',
            offer_name: 'Diamond',
            offer_price: 12700,
            offer_initial_price: 0,
            offer_initial_period: null,
            offer_initial_interval: null,
            offer_period: 6,
            offer_interval: 'm\u00eas',
            offer_trial_days: 7,
            installments: [],
          },
          can_upgrade_to_plans: [],
          features: [
            { name: 'Testes A/B', slug: 'ab-tests', value: 'U' },
            { name: 'Analytics', slug: 'analytics', value: 'U' },
            { name: 'Dom\u00ednios', slug: 'domains', value: 'U' },
            { name: 'Funis', slug: 'funnels', value: 'U' },
            { name: 'P\u00e1ginas', slug: 'pages', value: 'U' },
            {
              name: 'Redirecionamentos (join-zap)',
              slug: 'redirection-pages',
              value: 'U',
            },
            {
              name: 'Limite de tr\u00e1fego',
              slug: 'visits-per-month',
              value: '250000',
            },
          ],
        },
      }
    // platinum paid with card
    case '4':
      return {
        ...defaultSubscription,
        id: 4,
        status: 'paid',
        card: {
          holder_name: 'Name on Card',
          exp_date: '22/02',
          last_four_digits: '8888',
        },
      }
    // can_upgrade false
    case '5':
      return {
        ...defaultSubscription,
        id: 5,
        status: 'paid',
        can_upgrade: false,
      }
    // status canceled
    case '6':
      return {
        ...defaultSubscription,
        id: 6,
        status: 'canceled',
      }
    // status canceled with card
    case '7':
      return {
        ...defaultSubscription,
        id: 4,
        status: 'canceled',
        card: {
          holder_name: 'Name on Card',
          exp_date: '22/02',
          last_four_digits: '8888',
        },
      }
    // status paid
    case '8':
      return {
        ...defaultSubscription,
        id: 4,
        status: 'paid',
        plan: {
          ...defaultSubscription.plan,
          code: 'FREE',
          name: 'free',
          can_upgrade_to_plans: ['PLATINUM', 'DIAMOND'],
        },
        card: {
          holder_name: 'Name on Card',
          exp_date: '22/02',
          last_four_digits: '8888',
        },
      }
    default:
      return defaultSubscription
  }
}
