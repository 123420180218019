import React from 'react'
import { ShorthandPropertyAssignment } from 'typescript'

import { ASYNC_STATE } from '~/constants'
import { getSubscriptionByCode } from '~/services/subscription'
import { Plan } from '~/types'
import { useAsync } from '~/utils/useAsync'

type useLoadPlaByCodeResponse = {
  data: Plan | null
  status: string | null
  error: string | null
}

const cache: Record<string, any> = {}

function useLoadPlanByCode(planCode: string, skip: boolean = false): useLoadPlaByCodeResponse {
  const { data, status, error, run } = useAsync()
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
    if (mounted.current && !skip && !cache?.[planCode]?.data) {
      planCode && run(getSubscriptionByCode(planCode))
    }
    return () => {
      mounted.current = false
    }
  }, [planCode, run, skip])

  React.useEffect(() => {
    if (status === ASYNC_STATE.RESOLVED && data) {
      cache[planCode] = {
        data,
        status,
        error,
      }
    }
  }, [data])

  if (cache?.[planCode]?.data) {
    return cache[planCode]
  }

  return {
    data,
    status,
    error,
  }
}

export { useLoadPlanByCode }
