import 'fontsource-montserrat/700.css'
import './style.css'

import DateFnsAdapter from '@date-io/date-fns'
import { useTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { UpgradeModal } from '~/components/UpgradeModal'
import { UpgradeModalProvider } from '~/components/UpgradeModal/context'
import { SET_ALERT, SET_USER } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import { useLoadPlan } from '~/hooks'
import * as loginService from '~/services/loginService'
import AlertController from '~/utils/components/Alert/alertController'
import LoadingController from '~/utils/components/Loading/loadingController'
import LoadingPage from '~/utils/components/LoadingPage'
import * as localStorage from '~/utils/localStorage'

import Routes from './routes'

function App() {
  useLoadPlan()
  const dispatch = useAppDispatch()
  const { user } = useAppState()
  const [isFetching, setIsFetching] = useState(true)
  const token = localStorage.get('@makeFunnels/token')
  const dispatched = useRef(false)
  const theme = useTheme()

  useEffect(() => {
    async function fetchUser() {
      try {
        const payload = await loginService.getProfile()
        const {
          data: {
            data: { ...allValues },
          },
        } = payload
        const { name, email, role, base_domain_id } = allValues
        dispatch({
          type: SET_USER,
          payload: {
            name,
            email,
            role,
            base_domain_id,
          },
        })
        setIsFetching(false)
      } catch (error) {
        localStorage.remove('@makeFunnels/token')
        localStorage.remove('lastPath')
        setIsFetching(false)
        dispatch({
          type: SET_USER,
          payload: null,
        })
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Sessão expirada, por favor, realize o login novamente.',
            variant: 'error',
          },
        })
      }
    }
    if (token && !dispatched.current) {
      !user && fetchUser()
      dispatched.current = true
    } else {
      setIsFetching(false)
    }
  }, [dispatch, token, user])

  if (isFetching) return <LoadingPage />

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <MuiPickersUtilsProvider utils={DateFnsAdapter}>
          <UpgradeModalProvider>
            <AlertController />
            <LoadingController />
            <Routes>
              <UpgradeModal />
            </Routes>
          </UpgradeModalProvider>
        </MuiPickersUtilsProvider>
      </DndProvider>
    </ThemeProvider>
  )
}

export default App
