import { Box, Button, DialogContent as DialogContentBase, Typography } from '@material-ui/core'
import React from 'react'

import help_img from '~/assets/images/help_img.svg'
import { TextBase } from '~/pages/Layout/DefaultLayout'

import { CheckIcon, useStyles } from '../../styles'

function DialogContent({ name, features, handleClick }) {
  const classes = useStyles()

  return (
    <DialogContentBase className={classes.content}>
      {/* <HelpImg /> */}
      <img src={help_img} alt="" />
      <Typography paragraph className={classes.textTitle}>
        Atualize seu Plano Agora
      </Typography>
      <Typography paragraph className={classes.text}>
        Faça o upgrade agora mesmo e aproveite todos estes recursos exclusivos do Makefunnels
        {name}
      </Typography>
      {features.map((feature, index) => (
        <Box key={`${feature?.name}_${index}`} display="flex" alignItems="flex-start" width="100%">
          <CheckIcon />
          <TextBase variant="body2">{feature?.name}</TextBase>
        </Box>
      ))}
      <Button variant="contained" onClick={handleClick} className={classes.button}>
        Atualizar para o plano {name}
      </Button>
    </DialogContentBase>
  )
}

export default DialogContent
