import colors from '~/utils/colors'

const style = (theme) => ({
  root: {
    margin: '10px 0px',
  },
  header: {
    background: 'rgba(195, 195, 196, 0.3)',
    padding: '16px',
  },
  statistics: {
    padding: '16px',
  },
  shareLink: {
    '& svg': {
      fontSize: '16px',
    },
    paddingLeft: '8px',
  },
  defaultFont: {
    fontSize: '18px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0px',
    textAlign: 'left',
    '&.url': {
      fontSize: '12px',
    },
  },
  redirectionName: {
    // marginLeft: "10px",
    padding: '0px !important',

    '& .MuiIconButton-root': {
      padding: '0px !important',
      marginLeft: '10px',
    },
  },
  domainNickName: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'left',

    // display: "flex",
    // justifyContent: "flex-start",
    // alignContent: "center",
  },
  folder: {
    background: '#EDF1FF',
    borderRadius: 8,
    width: 53,
    height: 50,
    textAlign: 'center',
    alignItems: ' center',
    justifyContent: ' center',
    display: 'flex',
    '& img': {
      // margin: "9px",
      fontSize: '30px',
      color: '#4C6FFF',
    },
  },
  container: {
    // position: "relative",
    // display: "flex",
    // justifyContent: "flex-start",
    // flexWrap: "wrap",
    // alignItems: "center",
    background: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
    borderRadius: '8px',
  },
  status: {
    fontWeight: 400,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',

    '&.expired': {
      color: colors.danger,
    },

    '&.soldout': {
      color: colors.erro,
    },

    '&.published': {
      color: colors.success,
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  info: {
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0px',
    '&.number': {
      fontSize: '17.55px',
    },
    '& p': {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0px',
      color: '#A0A4A8',
      alignItems: 'center',
      display: 'flex',
    },
    '& svg': {
      fontSize: '16px',
    },
    margin: 'auto 10px',
  },
  settings: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  menu: {
    background: '#222',
    color: '#fff',

    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    '& li': {
      padding: '10px auto',
    },
  },
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: '1px solid #E5E9F5',
    display: 'flex',
    '& p': {
      textAlign: 'left',
      fontSize: '20px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      '& span': {
        fontSize: '12px',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 50px',
    height: '100%',

    '& p': {
      textAlign: 'left',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      margin: '40px 0px 0px',

      '& span': {
        fontWeight: 300,
        fontSize: '20px',
      },
    },
  },
  content2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 70px',
    marginTop: '30px',
    height: '100%',
    background: '#F5F7FF',
  },
  selectType: {
    // padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    textAlign: 'left',
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,

    width: '100%',

    '& button, & button:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      // width: "180px",
      borderRadius: colors.button.radius,
    },

    '& button, & h4': {
      marginTop: '20px',
    },
  },
})

export default style
