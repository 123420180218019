import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import React, { ElementType } from 'react'
import styled from 'styled-components'

interface LoginDrawerProps {
  isOpen: boolean
  handleClose: () => void
  ContentComponent: ElementType
  [x: string]: unknown
}

const DrawerContent = styled.div`
  margin: 0 auto;
  padding: 2rem 24px 1.5rem;
  /* justify-content: center; */
  align-items: flex-start;
  width: 100%;
  min-height: 36rem;
  display: flex;
  flex-direction: column;
`

export const LoginDrawer = (props: LoginDrawerProps) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const { isOpen, handleClose, ContentComponent } = props

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <DrawerContent>
        <ContentComponent {...props} />
      </DrawerContent>
    </SwipeableDrawer>
  )
}
