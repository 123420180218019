import { Box, Tab, Tabs, Tooltip, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import React, { ReactNode, useState } from 'react'

import { ReactComponent as TooltipIcon } from '~/assets/images/tooltip.svg'
import { useAppState } from '~/context'
import { Funnel } from '~/types'

import Favicon from '../Favicon'
import Metrics from '../Metrics'
import ShareAsTemplate from '../ShareAsTemplate'

interface TabPanelProps {
  children: ReactNode
  value: number
  index: number
  padding?: number
}

interface FunnelAppState {
  funnel: { data: Funnel }
}

const TabPanel = ({ children, value, index, padding }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    style={{
      background: '#fff',
      padding: `10px ${padding ? padding : 0}px`,
    }}
  >
    {value === index && <Box>{children}</Box>}
  </div>
)

function TabsPanel() {
  const [tab, setTab] = useState(0)
  const {
    funnel: { data: funnel },
  }: FunnelAppState = useAppState()
  const { favicon_path, updated_at } = funnel

  const tabs = [
    { id: 1, label: 'Estatísticas' },
    { id: 2, label: 'Configurações' },
  ]

  return (
    <Box my={3}>
      <>
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue)
          }}
          aria-label="simple tabs example"
          indicatorColor="primary"
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabs.map((el) => (
            <Tab
              key={el.id}
              label={el.label}
              style={{
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
            />
          ))}
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Metrics />
        </TabPanel>
        <TabPanel value={tab} index={1} padding={50}>
          <Typography
            gutterBottom
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              fontFamily: 'Nunito Sans',
              fontWeight: 600,
              fontSize: 14,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Adicionar favicon às páginas
            <Tooltip title="Ao alterar o favicon nessa configuração, ele será aplicado automaticamente em todas as páginas desse funil.">
              <TooltipIcon style={{ marginLeft: 8 }} />
            </Tooltip>
          </Typography>
          <Favicon path={favicon_path} updatedAt={updated_at} />
          <Divider />
          <ShareAsTemplate />
        </TabPanel>
      </>
    </Box>
  )
}

export default TabsPanel
