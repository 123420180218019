/* eslint-disable react/prop-types */

import React, { createContext } from 'react'

import { useLoadData } from '~/hooks'
import * as canvasServices from '~/services/canvasServices'
const BoardsContext = createContext({
  boards: [],
  isFetching: false,
  boardId: null,
  setBoardId: () => ({}),
  handleSuccess: () => ({}),
})

function BoardsProvider({ children }) {
  const { data: boards, isFetching, runRequest } = useLoadData(() => canvasServices.getAll())
  const [boardId, setBoardId] = React.useState(null)

  const handleSuccess = () => {
    runRequest()
    setBoardId(null)
  }

  const value = {
    boards,
    isFetching,
    boardId,
    setBoardId,
    handleSuccess,
  }

  return <BoardsContext.Provider value={value}>{children}</BoardsContext.Provider>
}

function useBoards() {
  const context = React.useContext(BoardsContext)
  if (context === undefined) {
    throw new Error('useBoards must be used within a BoardsProvider')
  }
  return context
}

export { BoardsProvider, useBoards }
