import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { DateTimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import React from 'react'
import MaskedInput from 'react-text-mask'

import InputFile from './components/InputFile'
import Urls from './components/Urls'

const useStyles = makeStyles(() => ({
  block: {
    backgroundColor: 'white',
    // color: "white",
    width: '100%',
    padding: '22px',
    marginTop: '1rem',
  },
  title: {
    fontSize: '1.2rem',
    width: '500px',
  },
  textArea: {
    width: '100%',
    padding: '6px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    fontFamily: 'Roboto',
  },
  muted: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    paddingTop: '7px',
    paddingLeft: '14px',
    color: '#999',
    textAlign: 'left',
    '& i': {
      marginRight: '7px',
    },
    '&.np': {
      padding: '0px',
    },
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addRedirect: {
    borderRadius: '8px !important',
    borderWidth: 2,
    // color: "#4C6FFF",
    color: '#fff',
    borderColor: '#4C6FFF',
    backgroundColor: '#4C6FFF',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: 180,
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '&:hover': {
      backgroundColor: '#4C6FFF',
      borderColor: '#4C6FFF',
      borderWidth: 2,
    },
  },
  group: {
    border: '1px solid #c6c6c6',
    padding: 10,
    borderRadius: 8,
    marginBottom: 12,
  },
  facebook: {
    marginBottom: 12,
  },
}))

function TextMaskCustom(props) {
  // eslint-disable-next-line react/prop-types
  const { inputRef, ...other } = props
  const regex = /^[\w-_.]*$/

  const initMask = ['G', 'T', 'M']
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      guide={false}
      // mask={[/^ua-\d{4,9}-\d{1,4}$/i]}
      mask={[
        ...initMask,
        '-',
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
        regex,
      ]}
    />
  )
}

const View = ({
  id = null,
  redirectionForm,
  visits_per_link,
  showDatePicker,
  expires_at,
  setShowDatePicker,
  tracking,
  handleSubmit,
  handleSetExpires_at,
  handleChange,
  handleChangeTracking,
  handleChangeContextValue,
  handleChangeFacebookTracking,
}) => {
  const classes = useStyles()
  const { name, path, text, reenter } = redirectionForm
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box m={4}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Editar' : 'Cadastrar'} Redirecionamento
        </Typography>
        <hr />
        <Container fixed>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            // textAlign="center"
            alignItems="flex-start"
            // height="calc(80vh - 200px)"
            position="relative"
          >
            <Box width="100%">
              <Box pt={3}>
                <Typography className={classes.title} variant="h4" align="left">
                  Informações básicas
                </Typography>
              </Box>
              <Paper className={classes.block}>
                <Box
                  display="flex"
                  // justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                  // marginTop="3rem"
                  width="100%"
                >
                  <Box mb={1} width="100%">
                    <TextField
                      label="Nome:"
                      name="name"
                      value={name}
                      size="medium"
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Box>
                  <Box mt={1} width="100%">
                    <TextField
                      label="Link de Compartilhamento:"
                      name="path"
                      // variant="outlined"
                      value={path}
                      size="medium"
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Box>
                  <span className={classes.muted}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    Neste campo você deve informar o caminho do link que você irá divulgar. Ele
                    ficará dessa forma: https://zap.makefunnels.com.br/link-de-compartilhamento
                  </span>
                </Box>
              </Paper>
            </Box>

            <Box width="100%">
              <Box pt={3}>
                <Typography className={classes.title} variant="h4" align="left">
                  Redirecionamento
                </Typography>
              </Box>
              <Paper className={classes.block}>
                <Box
                  display="flex"
                  // justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                  // marginTop="3rem"
                  width="100%"
                >
                  {/* <Typography align="left" variant="body2">
                  Entrada até:
                </Typography> */}
                  <Box>
                    <FormControlLabel
                      control={
                        <Switch
                          // checked={state.checkedB}
                          checked={showDatePicker}
                          color="primary"
                          onChange={() =>
                            setShowDatePicker(() => {
                              handleSetExpires_at(null)
                              return !showDatePicker
                            })
                          }
                        />
                      }
                      label="Ativar Expiração"
                    />
                  </Box>
                  {showDatePicker && (
                    <DateTimePicker
                      clearable
                      format="dd/MM/yyyy HH:mm"
                      label="Entrada até:"
                      margin="normal"
                      inputVariant="outlined"
                      name="expires_at"
                      value={expires_at}
                      onChange={handleSetExpires_at}
                    />
                  )}
                  <Box mt={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          // checked={state.checkedB}
                          checked={Boolean(reenter)}
                          name="reenter"
                          color="primary"
                          onChange={handleChange}
                        />
                      }
                      label="Ativar reentrada"
                    />
                  </Box>
                  <span className={classes.muted}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    Se ativado, o visitante do link de compartilhamento será marcado. Dessa forma,
                    mesmo que entre no link novamente diversas vezes, continuará sempre sendo
                    redirecionado para a URL Destino que foi redirecionado na primeira visita.
                  </span>
                </Box>
              </Paper>
            </Box>
            <Box mt={3} width="100%">
              <Urls visits_per_link={visits_per_link}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // flexDirection="column"
                  // marginTop="3rem"
                  width="100%"
                  mb={3}
                >
                  <Box flex="1">
                    <span className={`${classes.muted} np`}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                      Na média, para encher um grupo de 256 pessoas, são usados 350 cliques por url
                      destino listada abaixo. Porém, teste e adapte o seu número. Você pode usar o
                      campo ao lado para aplicar o mesmo limite a todos os links.
                    </span>
                  </Box>
                  <Box flex="0.2">
                    <TextField
                      label="Limite Padrão"
                      type="number"
                      variant="outlined"
                      name="visits_per_link"
                      value={visits_per_link}
                      onChange={handleChangeContextValue}
                    />
                  </Box>
                </Box>
              </Urls>
            </Box>
            <Box width="100%">
              <Box pt={3}>
                <Typography className={classes.title} variant="h4" align="left">
                  Personalização
                </Typography>
              </Box>
              <Paper className={classes.block}>
                <Box
                  display="flex"
                  // justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                  // marginTop="3rem"
                  width="100%"
                >
                  <Box width="100%">
                    <TextField
                      label="Mensagem de redirecionamento:"
                      size="medium"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      value={text}
                      name="text"
                    />
                  </Box>
                  <Box mt={4} textAlign="left" width="100%">
                    <Typography align="left">
                      Definir uma imagem customizada {'(Formatos: png, jpeg)'}:
                    </Typography>
                    <Box mt={3}>
                      <InputFile />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box width="100%">
              <Box pt={3}>
                <Typography className={classes.title} variant="h4" align="left">
                  Tracking
                </Typography>
              </Box>
              <Paper className={classes.block}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                  // marginTop="3rem"
                  width="100%"
                >
                  <Grid
                    container
                    spacing={2}
                    my={1}
                    width="100%"
                    className={tracking.fb.value ? classes.group : classes.facebook}
                  >
                    <Grid item xs={12}>
                      <TextField
                        label="Facebook Pixel ID"
                        name="fb"
                        value={tracking.fb.value}
                        size="medium"
                        onChange={handleChangeTracking}
                        fullWidth
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} style={{ display: tracking.fb.value ? 'block' : 'none' }}>
                      <TextField
                        label="Token de Acesso"
                        name="token"
                        value={tracking.fb.token}
                        size="medium"
                        onChange={handleChangeFacebookTracking}
                        fullWidth
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6} style={{ display: tracking.fb.value ? 'block' : 'none' }}>
                      <TextField
                        label="URL a ser enviada"
                        name="source_url"
                        value={tracking.fb.source_url}
                        size="medium"
                        onChange={handleChangeFacebookTracking}
                        fullWidth
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: tracking.fb.value ? 'block' : 'none' }}>
                      <span className={`${classes.muted} np`}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                        Disponibilizamos um vídeo explicativo de como obter seu Token de Acesso:
                        aperte aqui para assistir.
                      </span>
                    </Grid>
                  </Grid>

                  <Box my={1} width="100%">
                    <TextField
                      label=" ID do Google Analytics (UA- ou G-)"
                      name="ga"
                      value={tracking.ga.value}
                      size="medium"
                      onChange={handleChangeTracking}
                      fullWidth
                      variant="outlined"
                      // InputProps={{
                      //   inputComponent: TextMaskCustom,
                      // }}
                    />
                  </Box>

                  <Box my={1} width="100%">
                    <TextField
                      label=" Google Tag Manager (GTM-)"
                      name="gtm"
                      value={tracking.gtm.value}
                      size="medium"
                      onChange={handleChangeTracking}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box mt={5} pb={5} className={classes.button}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addRedirect}
                // onClick={handleSubmit}
                type="submit"
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </form>
  )
}

View.propTypes = {
  id: PropTypes.any,
  redirectionForm: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    text: PropTypes.string,
    reenter: PropTypes.number,
  }),
  visits_per_link: PropTypes.number,
  showDatePicker: PropTypes.bool,
  expires_at: PropTypes.any,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      visits_limit: PropTypes.number,
      order: PropTypes.number,
    }),
  ),
  renderLinks: PropTypes.func,
  setShowDatePicker: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSetExpires_at: PropTypes.func,
  handleRemoveLink: PropTypes.func,
  handleAddLink: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeTracking: PropTypes.func,
  handleChangeContextValue: PropTypes.func,
  handleChangeFacebookTracking: PropTypes.func,
  tracking: PropTypes.shape({
    fb: PropTypes.shape({
      type: PropTypes.string,
      enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      value: PropTypes.string,
      token: PropTypes.string,
      source_url: PropTypes.string,
    }),
    ga: PropTypes.shape({
      type: PropTypes.string,
      enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      value: PropTypes.string,
    }),
    gtm: PropTypes.shape({
      type: PropTypes.string,
      enabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      value: PropTypes.string,
    }),
  }),
}

export default View
