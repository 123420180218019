import PropTypes from 'prop-types'
import React from 'react'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import { useCollapseState } from '~/pages/home/hooks/useCollapseState'
import * as domainsService from '~/services/domainsService'

import DeleteDomain from './DeleteDomain'

function DeleteDomainContainer({ state: { id, open, validator }, action: { handleClose } }) {
  const dispatchApp = useAppDispatch()
  const { remove } = useCollapseState()

  async function handleSubmit() {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: true },
    })
    try {
      await domainsService.deleteDomain(id)
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Domínio deletado!',
          variant: 'success',
        },
      })
      remove(id)
      return await domainsService.FetchDomains(dispatchApp)
    } catch (error) {
      try {
        const errorResponse = JSON.parse(error.message)
        return dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: errorResponse.error,
            variant: 'error',
          },
        })
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError.message)
      }
    } finally {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: true },
      })
    }
  }

  return (
    <DeleteDomain
      state={{ open: open, validator: validator }}
      actions={{ handleClose, handleSubmit }}
    />
  )
}

DeleteDomainContainer.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    id: PropTypes.number,
    validator: PropTypes.string,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

export default DeleteDomainContainer
