import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as InfoIcon } from '~/assets/images/info_icon.svg'
import colors from '~/utils/colors'

const Wrapper = styled.div`
  padding: 3rem;
  background: ${colors.white60};
  display: flex;
  justify-content: space-around;

  & h6,
  p,
  a {
    font-family: Nunito Sans;
    color: ${colors.info};
  }
  & h6 {
    font-size: 16px;
    font-weight: 800;
  }
  & p {
    font-size: 14px;
  }

  & svg {
    margin-right: 25;
  }
`

interface DisclamerProps {
  title: string
  content: string
}

export const Disclamer = ({ title, content }: DisclamerProps) => {
  return (
    <Wrapper>
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            alignSelf: 'center',
          }}
        >
          <InfoIcon />
        </Grid>
        <Grid item xs={10}>
          <Box>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{content}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
