import React from 'react'
import { createRoot } from 'react-dom/client'

// import "./index.css"
import App from './App'
import { AppProvider } from './context'

if (process.env.NODE_ENV === 'development' && process.env.USE_MOCKS) {
  const { worker } = await import('./mocks/browser')
  worker.start({ onUnhandledRequest: 'bypass' })
}

const root = createRoot(document.getElementById('root'))
root.render(
  <AppProvider>
    <App />
  </AppProvider>,
)
