import axios from './config/axios'

export const getAll = () => axios.get(`/funnelcanvas`).then((res) => res.data.data)

export const get = (id) => axios.get(`/funnelcanvas/${id}`).then((res) => res.data)

export const save = (name) => axios.post(`/funnelcanvas`, { name })

export const update = (id, data) => axios.put(`/funnelcanvas/${id}`, data)

export const remove = (id) => axios.delete(`/funnelcanvas/${id}`)
