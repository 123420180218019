import { useState } from 'react'

import * as localStorage from '~/utils/localStorage'
const MY_FUNNELS_COLLAPSE_STATE = 'my_funnels_collapse_state'

type TCollapseState = {
  [x: string]: boolean
}

const getLocalValue = () => {
  const collapseState = localStorage.get(MY_FUNNELS_COLLAPSE_STATE)
  const collapseStateObject: TCollapseState = collapseState && JSON.parse(collapseState)

  return collapseStateObject
}

export const useCollapseState = (domainId: number) => {
  const localValue = getLocalValue()
  const [open, setOpen] = useState<boolean>(localValue?.[domainId] ?? true)

  const toggle = () => {
    const localValue = getLocalValue()

    setOpen(!open)
    localStorage.save(
      MY_FUNNELS_COLLAPSE_STATE,
      JSON.stringify({
        ...localValue,
        [domainId]: !open,
      }),
    )
  }

  const remove = (domainId: string) => {
    const localValue = getLocalValue()
    const copyObject = { ...localValue }
    delete copyObject?.[domainId]

    localStorage.save(
      MY_FUNNELS_COLLAPSE_STATE,
      JSON.stringify({
        ...copyObject,
      }),
    )
  }

  return {
    open,
    toggle,
    remove,
  }
}
