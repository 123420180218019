import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'

function View({ state: { open, options }, actions: { handleClose, handleSubmit, handleChange } }) {
  const inputs = [
    {
      component: 'autoComplete',
      fullWidth: true,
      label: <>Para onde deseja duplicar esta página?</>,
      placeholder: 'Digite o nome do funil',
      options,
      onChange: handleChange,
    },
  ]

  const buttons = [
    {
      variant: 'contained',
      name: 'Duplicar',
      handleSubmit,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{ open, title: 'Duplicar página', inputs, buttons }}
    ></ModalComponent>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    options: PropTypes.array,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
  }),
}

export default View
