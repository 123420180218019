import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getFunnel } from '~/actions'
import { openUpgradeModal, useUpgradeModal } from '~/components/UpgradeModal/context'
import { HTTP_ERROS, SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import * as funnelService from '~/services/funnelService'
import fetch from '~/utils/fetch'
import { destructuringResponseArray } from '~/utils/help'

import View from './View'

function DuplicatePage({ state: { open, pageId }, action: { handleClose } }) {
  const [_, dispatch] = useUpgradeModal()
  const dispatchApp = useAppDispatch()
  const [funnels, setFunnels] = useState([])
  const [funnelSelected, setFunnelSelected] = useState({})
  const { id } = useParams()

  useEffect(() => {
    fetch(dispatchApp, async () => {
      const response = await funnelService.getAllFunnels()
      const funnels = destructuringResponseArray(response)
      setFunnels(funnels)
    })
  }, [dispatchApp])

  const handleChange = (e, newValue) => {
    const { id, name } = newValue
    return setFunnelSelected({ funnel_id: id, name })
  }

  const handleSubmit = () => {
    const funnel = funnelSelected.name

    delete funnelSelected.name

    const tryFunction = async () => {
      try {
        await funnelService.duplicatePage(pageId, funnelSelected)
        await getFunnel(dispatchApp, id)
      } catch (res) {
        const {
          response: {
            data: { code, error },
          },
        } = res
        if (code === HTTP_ERROS.UNAUTHORIZED) openUpgradeModal(dispatch)

        return dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: error,
            variant: 'error',
          },
        })
      }
      handleClose()
      return dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Página duplicada no funil ' + funnel,
          variant: 'success',
        },
      })
    }

    fetch(dispatchApp, tryFunction)
  }

  return (
    <View
      state={{ open, options: funnels }}
      actions={{ handleClose, handleChange, handleSubmit }}
    />
  )
}

DuplicatePage.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    pageId: PropTypes.number,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}

export default DuplicatePage
