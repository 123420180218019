import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
)

interface SharedLinkPathProps {
  onClick: () => void
  url: string
}

export default function SharedLinkPath({ onClick, url }: SharedLinkPathProps) {
  const classes = useStyles()

  return (
    <Box component="form" className={classes.root}>
      <IconButton aria-label="copy" className={classes.iconButton} onClick={onClick}>
        <FileCopyIcon />
      </IconButton>
      <InputBase className={classes.input} value={url} disabled />
    </Box>
  )
}
