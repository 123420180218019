import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'

import colors from '~/utils/colors'

import IntegrationList from './List'

const useStyles = makeStyles({
  header: {
    color: colors.dark80,
    height: 60,
    '& p, h6': {
      fontFamily: 'Nunito Sans',
    },
    '& h6': {
      color: colors.dark100,
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      margin: 0,
    },
  },
})

export default function IntegrationContainer() {
  const classes = useStyles()
  return (
    <Box mx={4} my={5}>
      <Box mb={4} className={classes.header}>
        <Typography variant="h6">Integrações</Typography>
        <Typography variant="body2" gutterBottom style={{ marginTop: 10 }}>
          Encontre provedores externos e conecte-os a MakeFunnels
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <IntegrationList />
      </Box>
    </Box>
  )
}
