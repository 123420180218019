import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CategoryIcon from '@material-ui/icons/Category'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import React, { useState } from 'react'

import types from '../../constants/funnelsType'
import CardFunnel from './CardFunnel'

export default function ChooseFunnel({ onChildClick }) {
  const funnels = [
    {
      name: types.LEADS,
      title: 'Leads',
      subtitle: 'Aumentar Lista de Propectos',
      icon: MonetizationOnIcon,
    },
    {
      name: types.SALES,
      title: 'Vendas',
      subtitle: 'Vender um produto ou serviço',
      icon: CategoryIcon,
    },
    {
      name: types.WEBINAR,
      title: 'Webinario',
      subtitle: 'Promover um Webnario',
      icon: CloudQueueIcon,
    },
    {
      name: types.LAUNCH,
      title: 'Lançamento',
      subtitle: 'Organizar um lançamento',
      icon: ChromeReaderModeIcon,
    },
  ]
  const [selected, setSelected] = useState(null)

  function handleClick(type, index) {
    onChildClick(type)
    setSelected(index)
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1" component="div">
        Escolha o objevito do seu funil
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {funnels.map((type, index) => (
            <Grid key={type.title} item xs={3}>
              <Button onClick={() => handleClick(type.name, index)}>
                <CardFunnel
                  title={type.title}
                  subtitle={type.subtitle}
                  Icon={type.icon}
                  selected={selected === index}
                />
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  )
}
