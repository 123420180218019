import { Box } from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import React from 'react'
import styled from 'styled-components'

import makefunnelsLogo from '~/assets/images/logo-makefunnels-short-2.svg'

const StyledImg = styled.img`
  width: 190px;
`

const StyledTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #5b5b5b;
  font-family: 'Montserrat';
`

const StyledLink = styled.a`
  color: #5b5b5bcf;
  font-size: 13px;
  text-decoration: none;
  background-color: transparent;
  font-weight: normal;
  font-family: 'Montserrat';

  &:hover {
    text-decoration: underline;
  }
`

const Wrapper = styled(Box)`
  @media (max-width: 430px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export function Footer() {
  return (
    <>
      <Wrapper
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <StyledImg src={makefunnelsLogo} loading="lazy" />
        </div>
        <div>
          <div>
            <StyledTitle>Redes Sociais</StyledTitle>
          </div>
          <Box display="flex" justifyContent="flex-start" gridGap={10} alignItems="center" mt={2}>
            <div style={{ color: 'rgb(91, 91, 91)' }}>
              <a
                className="anchor"
                href="https://www.youtube.com/channel/UC_uinlJGfHu-k2uyd2e7MjQ"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noopener noreferrer"
              >
                <YouTubeIcon fontSize="large" />
              </a>
            </div>
            <div style={{ color: 'rgb(91, 91, 91)' }}>
              <a
                className="anchor"
                href="https://www.instagram.com/makefunnels/"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noopener noreferrer"
              >
                <InstagramIcon fontSize="large" />
              </a>
            </div>
          </Box>
        </div>
        <div>
          <div>
            <StyledTitle>Produto</StyledTitle>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            gridGap={10}
            alignItems="flex-start"
            mt={2}
          >
            <div>
              <StyledLink
                href="https://makefunnels.com.br/planos"
                data-cke-saved-href="https://makefunnels.com.br/planos"
              >
                Nossos planos
              </StyledLink>
            </div>
            <div>
              <StyledLink
                href="https://makefunnels.com.br/afiliacao"
                data-cke-saved-href="https://makefunnels.com.br/afiliacao"
              >
                Programa de afiliados
              </StyledLink>
            </div>
          </Box>
        </div>
        <div>
          <div>
            <StyledTitle>Contato</StyledTitle>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            gridGap={10}
            alignItems="flex-start"
            mt={2}
          >
            <div>
              <StyledLink href="mailto:suporte@makefunnels.com.br">
                suporte@makefunnels.com.br
              </StyledLink>
            </div>
          </Box>
        </div>
      </Wrapper>
      <div>
        <div>
          <hr style={{ marginTop: '15px', borderTop: '1px solid rgb(237, 237, 237)' }} />
        </div>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <div>
            <div className="text-container z-50 sm-mt-10" style={{ marginTop: '10px' }}>
              <StyledLink
                className="sm-fs-14"
                href="https://makefunnels.com.br/politicas-de-privacidade"
                data-cke-saved-href="https://makefunnels.com.br/politicas-de-privacidade"
              >
                Políticas de privacidade
              </StyledLink>
              <span style={{ color: '#5b5b5bcf' }}> | </span>
              <StyledLink
                className="sm-fs-14"
                href="https://makefunnels.com.br/termos-de-uso"
                data-cke-saved-href="https://makefunnels.com.br/termos-de-uso"
              >
                Termos de uso
              </StyledLink>
            </div>
          </div>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={5}>
          <h6 style={{ fontFamily: 'Montserrat', color: '#5b5b5bcf', fontSize: '13px' }}>
            MakeFunnels&nbsp;©2024. Todos os direitos reservados.
          </h6>
        </Box>
      </div>
    </>
  )
}
