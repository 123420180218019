export const PENDING = {
  text: 'pending_payment',
  label: 'Pendente',
  color: '#ffc107',
}
export const TRIAL = {
  text: 'trialing',
  label: 'Testando',
  color: '#03a9f4',
}
export const PAID = {
  text: 'paid',
  label: 'Pago',
  color: '#4caf50',
}
export const CANCELED = {
  text: 'canceled',
  label: 'Cancelado',
  color: '#f44336',
}
