/* eslint-disable react/prop-types */
import { Box, ButtonGroup, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import styled from 'styled-components'

import { categories } from '~/pages/Templates/shared/categories'

const TemplateCard = ({ template, choseTemplate, previewTemplate }) => {
  const categoryName = categories?.find((el) => el?.id === template?.page_category_id)?.name
  const [showOptions, setShowOptions] = useState(false)

  return (
    <GridStyle item>
      <CardStyle
        onMouseOver={() => setShowOptions(true)}
        onMouseLeave={() => setShowOptions(false)}
      >
        <CardMediaStyled image={template.image} title="Image title" />
        <CardContentStyled>
          <Typography gutterBottom variant="h5" component="h2">
            {categoryName}
          </Typography>
        </CardContentStyled>
        {showOptions && (
          <CardActionsStyled>
            <Box display="flex" flexDirection="column" gridGap={'2rem'}>
              <ButtonSelectStyled
                // href={template.path}
                onClick={previewTemplate}
                // target="_blank"
                // referrerPolicy="no-referrer"
                variant={'outlined'}
                color="primary"
                size="small"
              >
                Pré-visualizar
              </ButtonSelectStyled>
              <Button
                size="small"
                variant={'contained'}
                color="primary"
                onClick={() => choseTemplate(template.id)}
              >
                Escolher
              </Button>
            </Box>
          </CardActionsStyled>
        )}
      </CardStyle>
    </GridStyle>
  )
}

const CardMediaStyled = styled(CardMedia)`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-position: left top; */
  /* background-size: contain; */
`

const GridStyle = styled(Grid)`
  height: 399px;
  width: 324px;
`

const CardStyle = styled(Card)`
  height: 100%;
  position: relative;
`
const CardContentStyled = styled(CardContent)`
  color: white;
  height: 80px;
  padding: 34px 16px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
`
const CardActionsStyled = styled(CardActions)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
`
const ButtonSelectStyled = styled(Button)`
  background: white;

  }
  &:hover {
    background: #dbdbdb;
  }
`

export default TemplateCard
