import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as domainService from '~/services/domainsService'
import * as funnelService from '~/services/funnelService'
import colors from '~/utils/colors'
import { filterByStatus } from '~/utils/domainFilter'
import fetch from '~/utils/fetch'

const useRowStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: '1px solid #E5E9F5',
    '& p': {
      textAlign: 'left',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 100px',
    height: '100%',
  },
  selectType: {
    // border: "2px solid #E5E9F5",
    borderRadius: '20px',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,

    '&.disabled': {
      '& button, & button:hover': {
        background: '#E5E9F5',
        color: '#fff',
        boxShadow: 'none',
        pointerEvents: 'none',
      },
    },

    '& button, & button:hover': {
      color: colors.button.main,
      border: colors.button.border,
      borderRadius: '8px !important',
      fontFamily: colors.button.font,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0.20000000298023224px',
      textAlign: 'center',
    },

    '& button, & h4': {
      marginTop: '20px',
    },
  },
  list: {
    width: '100%',
    maxHeight: 200,
    overflowY: 'scroll',
    margin: '20px auto 40px',
    background: '#c5c5c52f',
    borderRadius: '8px',
    '& .Mui-selected': {
      border: colors.button.border,
    },
  },
  search: {
    marginTop: '20px',
    width: '100%',
    height: 40,
    background: '#FFFFFF',
    borderRadius: 50,
    '& fieldset': {
      height: 45,
      borderRadius: 50,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& div': {
      height: 40,
    },
    '& input': {
      padding: '0 40px !important',
    },
    '& svg': {
      color: '#A9ABAE',
    },
  },
}))

function MoveModal({ open, handleCloseModal, funnelSelected }) {
  const classes = useRowStyles()
  const dispatchApp = useAppDispatch()
  const { domains } = useAppState()
  const [domainSelected, setDomainSelected] = useState('')
  const domainsData = filterByStatus(domains.data, 'active')
  const [arrDomains, setArrDomains] = useState(domainsData)
  const [searchValue, setSearchValue] = useState('')
  const [loadingMoveFunnel, setLoadingMoveFunnel] = useState(false)

  React.useEffect(() => {
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: loadingMoveFunnel, background: true },
    })
  }, [dispatchApp, loadingMoveFunnel])

  const handleChange = (element) => {
    if (element === domainSelected) return setDomainSelected(null)
    return setDomainSelected(element)
  }

  const handleDone = () => {
    setLoadingMoveFunnel(true)
    fetch(dispatchApp, async () => {
      const data = {
        domain_id: domainSelected,
      }
      funnelService
        .updateFunnelAttr(funnelSelected, data)
        .then(() => {
          handleCloseModal()
          dispatchApp({
            type: SET_ALERT,
            payload: {
              open: true,
              message: 'Funil movido com sucesso',
              variant: 'success',
            },
          })
          setLoadingMoveFunnel(false)
          return domainService.FetchDomains(dispatchApp)
        })
        .catch((error) => {
          console.error(error)
          dispatchApp({
            type: SET_ALERT,
            payload: {
              open: true,
              message: 'Houve um problema nesta operação. Por favor tente mais tarde.',
              variant: 'error',
            },
          })
          setLoadingMoveFunnel(false)
        })
    })
  }

  function handleSearch(arr, value) {
    if (!arrDomains) return
    if (value.length) {
      const aux = arr.filter((el) => {
        return el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      })

      let foundDomains = aux.reduce((accumulator, currentValue) => {
        const value = currentValue.id || null
        return accumulator + value
      }, 0)

      return setArrDomains(foundDomains ? aux : [])
    }

    return setArrDomains(arr)
  }

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>
        <Typography component="p">Para onde deseja mover o funil?</Typography>
        <IconButton onClick={handleCloseModal} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={classes.selectType}>
          <Typography variant="h4">Escolha o domínio para onde deseja mover este funil</Typography>
          <Box className={classes.search}>
            <TextField
              variant="outlined"
              fullWidth
              id="search"
              name="search"
              placeholder="Pesquisar"
              onChange={(e) => {
                const { value } = e.target

                setSearchValue(value)
                handleSearch(domainsData, value)
              }}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <List className={arrDomains.length ? classes.list : ''}>
            {arrDomains &&
              arrDomains.map((element, i) => (
                <ListItem
                  button
                  width={'100%'}
                  key={`item-${element.id}-${i}`}
                  onClick={() => handleChange(element.id)}
                  selected={domainSelected === element.id}
                >
                  <ListItemText primary={element.name} />
                </ListItem>
              ))}
          </List>

          <Button
            fullWidth
            variant={'outlined'}
            color="primary"
            onClick={handleDone}
            disabled={loadingMoveFunnel}
          >
            Mover Funil
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

MoveModal.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleCloseModal: PropTypes.func,
  funnelSelected: PropTypes.number,
  loading: PropTypes.bool,
  domainList: PropTypes.arrayOf(PropTypes.shape()),
}

MoveModal.defaultProps = {
  handleOpen: () => {},
  domainSelected: 1,
  domainList: [
    {
      name: 'doamin1',
      id: 1,
    },
    {
      name: 'doamin2',
      id: 2,
    },
  ],
}

export default MoveModal
