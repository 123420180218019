import { makeStyles } from '@material-ui/core'
import CheckBase from '@material-ui/icons/Check'
import styled from 'styled-components'

import colors from '~/utils/colors'

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    /* color: #f6c209; */
    margin-right: 8px;
  }
`

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    paddingLeft: '50px',
    borderBottom: '1px solid #E5E9F5',
    display: 'flex',
    '& p': {
      textAlign: 'left',
      fontSize: '28px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '33px 50px 29px',
    height: '100%',
  },
  textTitle: {
    textAlign: 'center',
    fontSize: '30px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: '21px 0px 20px',
    color: colors.bluePrimary,
  },
  text: {
    fontSize: '18px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    margin: '0px 0px 27px',
    color: colors.dark80,
    textAlign: 'left',
    width: '100%',
  },
  dialog: {
    '& .MuiDialog-paperFullWidth': {
      width: '800px !important',
    },
  },
  button: {
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    background: colors.bluePrimary,
    color: colors.white80,
    boxShadow: 'none',
    marginTop: '27px',

    borderRadius: '8px',
    height: 42,
    '&:hover': {
      background: colors.bluePrimary,
      color: colors.white80,
    },
  },
}))
