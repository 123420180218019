import { makeStyles } from '@material-ui/core'

import colors from '~/utils/colors'

export const useStyles = makeStyles({
  header: {
    color: colors.dark80,
    height: 60,
    '& p, h6': {
      fontFamily: 'Nunito Sans',
    },
    '& h6': {
      color: colors.dark100,
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      margin: 0,
    },
  },
})
