/* eslint-disable @typescript-eslint/camelcase */
import { Box } from '@material-ui/core'
import React from 'react'

import { PLAN_CODE, PLAN_STATE } from '~/constants'
import { useAppState } from '~/context'
import { CreditCardDrawer } from '~/modules/credit-card'
import { DefaultBox, Subtitle, TextBase } from '~/pages/Layout/DefaultLayout'
import { Subscription } from '~/types'

import { Info } from './components/Info'
import Tag from './components/Tag'
import SkeletonDetails from './SkeletonDetails'
import { Link, Sumary, TextBold } from './styles'

const Details: React.FC<{
  subscription: Subscription
  loading: boolean
  mustSelectResources: boolean
}> = ({ subscription, loading, mustSelectResources }) => {
  const {
    plan: currentSubscription,
    status: planStatus,
    trial_days_left,
    card,
    expires_at,
  } = subscription
  const isSubscriptionFree = currentSubscription?.code === PLAN_CODE.FREE
  const cancelLink = mustSelectResources
    ? `/resources/${PLAN_CODE.FREE}`
    : `/plan_cancel/${PLAN_CODE.FREE}`

  if (loading) return <SkeletonDetails />

  return (
    <>
      <TextBold variant="h6">
        Makefunnels {currentSubscription?.name}{' '}
        {!isSubscriptionFree && <Tag status={subscription.status} />}
      </TextBold>
      <Subtitle>Agradecemos por apoiar a Makefunnels utilizando nossos serviços.</Subtitle>
      {!isSubscriptionFree && (
        <Info
          expiresAt={expires_at}
          offer={currentSubscription?.offer}
          status={planStatus}
          trialDaysLeft={trial_days_left}
        />
      )}
      {!isSubscriptionFree && card && planStatus !== PLAN_STATE.CANCELED && (
        <Box mt={2}>
          <DefaultBox>
            <TextBold variant="body1">Forma de pagamento</TextBold>
            <TextBase variant="body2">{card?.holder_name}</TextBase>
            <TextBase variant="body2">#### #### #### {card?.last_four_digits}</TextBase>
            <Box mt={1}>
              <CreditCardDrawer />
            </Box>
          </DefaultBox>
        </Box>
      )}
      <Box mt={2}>
        <TextBase variant="body1">
          {!isSubscriptionFree && planStatus !== PLAN_STATE.CANCELED && (
            <>
              {/* <Link to={`/downgrade/${currentSubscription?.code}`}>Rebaixar</Link> para outro plano
              ou  */}
              <Link to={cancelLink}>Cancele sua assinatura</Link>
            </>
          )}
        </TextBase>
      </Box>
    </>
  )
}

export default Details
