/* eslint-disable react/prop-types */
import React from 'react'
import { Image } from 'react-konva'

import CopyIcon from '~/assets/images/copy_icon.svg'

import { COPY_NODE } from '../../constants/actions'
import { useBoard } from '../../context/BoardProvider'
import useLoadImage from '../../hooks/useLoadImage'

function Copy({ x, y, id }) {
  const image = useLoadImage({ src: CopyIcon })
  const { dispatch, getStageCenter } = useBoard()
  const width = 18

  const handleOnClick = () => {
    dispatch({ type: COPY_NODE, payload: { id, position: getStageCenter() } })
  }

  return (
    <>
      <Image
        x={x + 24}
        y={y - width}
        width={width}
        height={width}
        onClick={handleOnClick}
        cornerRadius={2}
        image={image}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'pointer'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
      />
    </>
  )
}

export default Copy
