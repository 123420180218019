import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import WebIcon from '@material-ui/icons/Web'
import WebAssetIcon from '@material-ui/icons/WebAsset'
import React, { useEffect, useState } from 'react'

import types from '../../constants/funnelsType'
import CardFunnel from './CardFunnel'

export default function ChooseFunnelType(funnelType, handleTypeSelected) {
  const funnelTypes = {
    [types.LEADS]: [
      {
        title: 'Funil de Captura',
        subtitle: 'Capture Leads com uma pagina de captura simples que desperte curiosidade',
        icon: WebIcon,
        steps: [
          {
            name: 'Pagina de Isca Digital',
            icon: WebIcon,
          },
          {
            name: 'Pagina de Obrigado',
            icon: WebIcon,
          },
        ],
      },
      {
        title: 'Funil Magnetico',
        subtitle: 'Capture Leads oferecendo uma "Isca Digital" gratuira, como um eBook, PDF, etc',
        icon: WebAssetIcon,
        steps: [
          {
            name: 'Pagina de Isca Digital',
            icon: WebIcon,
          },
          {
            name: 'Pagina de rmkt',
            icon: WebIcon,
          },
          {
            name: 'Pagina de Obrigado',
            icon: WebIcon,
          },
        ],
      },
    ],
    [types.SALES]: [
      {
        title: 'Funil de Video',
        subtitle: 'Capture leads com uma pagina de captura em video para agregar mais valor',
        icon: WebIcon,
        steps: [
          {
            name: 'Pagina de Isca Digital',
            icon: WebIcon,
          },
          {
            name: 'Pagina de rmkt',
            icon: WebIcon,
          },
          {
            name: 'Pagina de rmkt 2',
            icon: WebIcon,
          },
          {
            name: 'Pagina de UpSel',
            icon: WebIcon,
          },
          {
            name: 'Pagina de Obrigado',
            icon: WebIcon,
          },
        ],
      },
      {
        title: 'Funil Trampolim',
        subtitle:
          'Capture leads para uma estrutura externa de terceiros (muito usado por afiliados)',
        icon: WebAssetIcon,
        steps: [
          {
            name: 'Pagina de Isca Digital',
            icon: WebIcon,
          },
          {
            name: 'Pagina de rmkt',
            icon: WebIcon,
          },
        ],
      },
    ],
    [types.WEBINAR]: [],
    [types.LAUNCH]: [],
  }
  const [typeSelected, setTypeSelected] = useState(null)
  const [showSteps, setShowSteps] = useState(false)

  useEffect(() => {
    setShowSteps(false)
    setTypeSelected(null)
    handleTypeSelected(false)
  }, [handleTypeSelected])

  function handleClick(index) {
    setTypeSelected(index)
    setShowSteps(true)
    handleTypeSelected(true)
  }

  function funnelSteps() {
    if (!funnelTypes[funnelType][typeSelected].steps) return null
    return (
      <>
        <hr />
        <Typography variant="subtitle1" component="div">
          Steps desse funil
        </Typography>
        <Box mt={5}>
          <Grid container spacing={2} justifyContent="center">
            {funnelTypes[funnelType][typeSelected].steps.map((step, index) => {
              return (
                <React.Fragment key={`${funnelType}-${typeSelected}-${index}`}>
                  {index > 0 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ArrowForwardIcon />
                    </Box>
                  )}
                  <Grid item xs={2}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography color="textSecondary">{`Step ${index + 1}`}</Typography>
                      {<step.icon style={{ fontSize: 80 }} />}
                      <Typography color="textSecondary">{step.name}</Typography>
                    </Box>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Box>
      </>
    )
  }

  if (!funnelType) return null
  return (
    <>
      <hr />
      <Typography variant="subtitle1" component="div">
        Escolha o tipo do seu funil
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {funnelTypes[funnelType].map((type, index) => (
            <Grid key={type.title} item xs={3}>
              <Button onClick={() => handleClick(index)}>
                <CardFunnel
                  title={type.title}
                  subtitle={type.subtitle}
                  Icon={type.icon}
                  selected={typeSelected === index}
                />
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>{showSteps && funnelSteps()}</Box>
      </Box>
    </>
  )
}
