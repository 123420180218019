import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import colors from '~/utils/colors'

import FunnelZeroController from '../funnelZero/funnelZeroController'

const useRowStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '8px',
    minWidth: '800px !important',
    minHeight: '500px !important',
    boxShadow: theme.shadows[5],
  },
  title: {
    flex: '100%',
    marginLeft: '1rem',
  },
  extendedIcon: {
    borderRadius: colors.button.radius,
    color: colors.button.main,
    border: colors.button.border,
    fontFamily: colors.button.font,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: 180,
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&:hover': {
      border: colors.button.border,
    },

    '&.btn-header': {
      background: colors.button.main,
      fontWeight: 400,
      color: '#FFF',
    },
  },
}))

function CreateFunnelModalView({ domainId, isHeader }) {
  const classes = useRowStyles()
  // eslint-disable-next-line no-unused-vars
  const [open, setOpenModal] = useState(false)

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Button
        variant={!isHeader ? 'outlined' : 'contained'}
        color="primary"
        className={`${classes.extendedIcon} ${!isHeader ? '' : 'btn-header'}`}
        onClick={handleClickOpen}
        size="small"
        startIcon={!isHeader ? <AddIcon /> : ''}
      >
        Novo Funil
      </Button>
      <FunnelZeroController domainId={domainId} open={open} handleClose={handleClose} />
    </>
  )
}

CreateFunnelModalView.propTypes = {
  domainId: PropTypes.number.isRequired,
  isHeader: PropTypes.bool,
}
export default CreateFunnelModalView
