import Button from '@material-ui/core/Button'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  &&& {
    border-radius: 8px !important;
    border-width: 2px;
    color: #fff;
    border-color: #4c6fff;
    background-color: #4c6fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: ${(props) => (props.$fit ? 'inherit' : '180px')};
    height: 36.5px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;

    & span {
      text-transform: capitalize !important;
    }

    &:hover {
      background-color: #4c6fff;
      border-color: #4c6fff;
      border-width: 2;
    }
  }
`
