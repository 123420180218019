/* eslint-disable @typescript-eslint/camelcase */
import { Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import React, { ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'

import { PLAN_CODE, PLAN_STATE } from '~/constants'
import { useAppState } from '~/context'
import { ButtonBase, ButtonContained, TextBase } from '~/pages/Layout/DefaultLayout'
import { Subscription } from '~/types'
import { InputBase, InputCard, InputWithMask } from '~/ui-box/forms'

const DrawerContent = styled.div`
  width: 450px;
  height: 450px;
  margin: 0 auto;
  padding-top: 1rem;
  justify-content: center;
  align-items: flex-start;
`

type CreditCardProps = {
  card_holder_name: string
  card_expiration_date: string
  card_number: string
  card_cvv: string
}

type DrawerProps = {
  resolved: boolean
  loading: boolean
  handleClick: (card: CreditCardProps) => void
}

const DisabledButton = () => (
  <Tooltip title="Só é possível alterar o cartão depois da primeira cobrança.">
    <Box>
      <ButtonBase variant="outlined" disabled>
        Alterar Cartão
      </ButtonBase>
    </Box>
  </Tooltip>
)

const Drawer = ({ resolved, loading, handleClick }: DrawerProps) => {
  const {
    currentSubscription: { plan: subscription },
  }: { currentSubscription: { plan: Subscription } } = useAppState()
  const [open, setOpen] = React.useState<boolean>(false)
  const [state, setState] = React.useState<CreditCardProps>({
    card_number: '',
    card_holder_name: '',
    card_expiration_date: '',
    card_cvv: '',
  })

  const isTrial = subscription.status === PLAN_STATE.TRIALING

  const toggleDrawer = (value: boolean) => {
    setOpen(value)
  }

  const handleChange = (label: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [label]: event.target.value,
    })
  }

  useEffect(() => {
    if (resolved) return toggleDrawer(false)
  }, [resolved])

  return (
    <React.Fragment>
      {isTrial ? (
        <DisabledButton />
      ) : (
        <ButtonBase variant="outlined" onClick={() => toggleDrawer(true)}>
          Alterar Cartão
        </ButtonBase>
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => {}}
        onOpen={() => toggleDrawer(true)}
      >
        <DrawerContent>
          <TextBase>Alterar Forma de Pagamento</TextBase>
          <InputCard
            name={'card_number'}
            placeholder={'Número do cartão'}
            type={'tel'}
            handleChange={handleChange('card_number')}
            mask={'9999999999999999'}
            value={state.card_number}
          />
          <InputBase
            name={'card_holder_name'}
            placeholder={'Nome do titular'}
            handleChange={handleChange('card_holder_name')}
            value={state.card_holder_name}
            fullWidth
          />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <InputWithMask
              name={'card_expiration_date'}
              placeholder={'MM / AA'}
              type={'tel'}
              handleChange={handleChange('card_expiration_date')}
              mask={'99/99'}
              value={state.card_expiration_date}
            />
            <InputWithMask
              name={'card_cvv'}
              placeholder={'CVC'}
              type={'tel'}
              handleChange={handleChange('card_cvv')}
              mask={'9999'}
              value={state.card_cvv}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
            <ButtonBase
              variant="outlined"
              onClick={() => toggleDrawer(false)}
              style={{ marginRight: '15px' }}
            >
              Cancelar
            </ButtonBase>
            <ButtonContained
              onClick={(e) => handleClick(state)}
              variant="contained"
              disabled={loading}
            >
              {loading ? 'Processando...' : 'Continuar'}
            </ButtonContained>
          </Box>
        </DrawerContent>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default Drawer
