import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CLEAR_STATE } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as loginService from '~/services/loginService'
import * as localStorage from '~/utils/localStorage'
import * as sharedData from '~/utils/sharedData'

import MenuProfileView from './menuProfileView'

function MenuProfileController() {
  // const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useAppDispatch()
  const { user } = useAppState()
  let history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    dispatch({ type: CLEAR_STATE })
    sharedData.clearCredentials()
    loginService.logout()
    localStorage.remove('@makeFunnels/token')
    localStorage.remove('lastPath')
    history.push('/login')
  }

  return (
    <MenuProfileView
      handleClick={handleClick}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleLogOut={handleLogOut}
      userName={user ? user.name : ''}
    />
  )
}

export default MenuProfileController
