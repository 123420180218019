import { Box, FormControl, FormHelperText, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import styled from 'styled-components'

import { Domain } from '~/types'
import { Button } from '~/ui-box'
import { InputField, InputLabel, InputSelect } from '~/ui-box/forms'

const defaultPadding = `padding: 16px 40px;`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 8px;
  /* border: 2px solid #edf0f7; */
`
const Headline = styled.div`
  border-bottom: 2px solid #e5e9f5;
  ${defaultPadding}
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Subtitle = styled(Typography)`
  font-weight: 600;
`

const Content = styled.div`
  ${defaultPadding}
`
const Footer = styled.div`
  border-top: 2px solid rgb(229, 233, 245);
  ${defaultPadding}
  /* display="flex" justifyContent="flex-end" gridGap={'1.5rem'} alignItems="center" */
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 431px) {
    justify-content: flex-end;
  }
`

interface ImportSetupContentProps {
  handleClose: () => void
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  formState: {
    name: {
      value: string
      error: boolean
    }
    domain: {
      value: number
      error: boolean
    }
  }
  handleSubmit: ({ name, domain }: { name: string; domain: number }) => void
  domainList: Domain[]
}

export const ImportSetupContent = ({
  handleClose,
  handleChange,
  handleSubmit,
  formState,
  domainList,
}: ImportSetupContentProps) => (
  <React.Fragment>
    <Headline>
      <Typography gutterBottom variant="h5">
        Importar Funil
      </Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Headline>
    <Content>
      <Subtitle>
        Defina um nome para o seu funil e escolha o domínio onde ele deve ser inserido.
      </Subtitle>
      <Box>
        <Box my={5}>
          <InputLabel htmlFor="funnel-name">Nome *</InputLabel>
          <InputField
            variant="outlined"
            autoComplete="off"
            placeholder="Digite o nome do seu funil"
            name="name"
            helperText={formState.name.error && 'Campo obrigatório'}
            value={formState.name.value}
            type="text"
            onChange={handleChange}
            error={formState.name.error}
            fullWidth
          />
        </Box>
        <Box mb={15}>
          <InputLabel htmlFor="funnel-name">Domain *</InputLabel>
          <StyledFormControl variant="outlined" error={formState.domain.error}>
            <InputSelect
              native
              inputProps={{
                name: 'domain',
              }}
              fullWidth
              value={formState.domain.value}
              onChange={(event) => handleChange(event)}
            >
              <option value={0} disabled>
                Selecione o domínio
              </option>
              {domainList?.map((option, index) => (
                <option key={`${option.name}_${index}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </InputSelect>
            {formState.domain.error && <FormHelperText>Campo obrigatório</FormHelperText>}
          </StyledFormControl>
        </Box>
      </Box>
    </Content>
    <Footer>
      <Button variant="outlined" layout="outlineDark" onClick={handleClose}>
        Cancelar
      </Button>
      <Button
        layout="containedDark"
        onClick={() => handleSubmit({ name: formState.name.value, domain: formState.domain.value })}
      >
        Confirmar
      </Button>
    </Footer>
  </React.Fragment>
)
