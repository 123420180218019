import {
  Dialog as DialogBase,
  DialogContent as DialogContentBase,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'

import colors from '~/utils/colors'

export const Dialog = styled(DialogBase)`
  &&& {
    & .muidialog-paperfullwidth {
      width: 800px !important;
    }
  }
`

export const DialogContent = styled(DialogContentBase)`
  &&& {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    height: 100%;
  }
`

export const DialogHeaderText = styled(Typography)`
  &&& {
    text-align: center;
    font-size: 30px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 700;
    margin: 21px 0px 20px;
    color: ${colors.bluePrimary};
  }
`

export const DialogText = styled(Typography)`
  &&& {
    font-size: 18px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 0px 27px;
    color: ${colors.dark80};
    text-align: left;
    width: 100%;
  }
`
