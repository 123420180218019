import { Typography } from '@material-ui/core'
import React, { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

styled.div((imgsrc) => ({
  backgroundImage: `url${imgsrc}`,
}))

const StyledPanel = styled.div(
  ({ img }: { img: string }) => `
  align-items: flex-end;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  height: 200px;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 30px 20px;
  position: relative;
  width: 300px;
  height: 375px;
  color: #fff !important;
  font-size: 3rem !important;
  background-color: #d3d3d3 !important;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  
  :hover {
    cursor: pointer;
  }
`,
)
const CardFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1.4rem;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

interface PanelProps {
  children?: ReactNode
  img: string
  onClick: (img: string) => void
}
export type Ref = HTMLDivElement

export const Panel = forwardRef<Ref, PanelProps>(function Panel({ img, children, onClick }, ref) {
  return (
    <StyledPanel ref={ref} img={img} onClick={() => onClick(img)}>
      <CardFooter>
        <Typography>{children}</Typography>
      </CardFooter>
    </StyledPanel>
  )
})
