import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  block: {
    backgroundColor: 'white',
    // color: "white",
    width: '100%',
    padding: '22px',
    marginTop: '1rem',
  },
  title: {
    fontSize: '1.2rem',
    width: '500px',
  },
  muted: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    paddingTop: '7px',
    paddingLeft: '14px',
    color: '#999',
    textAlign: 'left',
    '& i': {
      marginRight: '7px',
    },
  },
}))
