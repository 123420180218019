const { makeStyles } = require('@material-ui/core')
const { default: colors } = require('~/utils/colors')

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 557,
      width: 557,
    },
  },

  dialogLarge: {
    '& .MuiDialog-paper': {
      maxWidth: 626,
      width: 626,
    },
  },

  title: {
    margin: 0,
    padding: theme.spacing(2, 5),
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',

    borderBottom: '2px solid ' + colors.white60,
    '& h2': {},
    '& p': {
      textAlign: 'left',
      fontSize: 24,
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
    },
  },

  content: {
    padding: theme.spacing(2, 5),
    '& h6': {
      fontFamily: 'Nunito Sans',
      textAlign: 'left',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 700,
      margin: theme.spacing(4, 0),
    },

    '& .disclaimer': {
      fontFamily: 'Nunito Sans',
      color: colors.erro,
      fontSize: 14,
      fontWeight: 600,
    },
  },

  input: {
    margin: '25px 0px 45px',

    '& .autocomplete .MuiInputBase-root': {
      padding: '0px !important',
    },

    '& fieldset, input': {
      height: 45,
      borderRadius: 8,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& input': {
      height: 40,
      borderRadius: 8,
    },
    '& select, select:focus': {
      background: 'rgba(0,0,0,0)',
      height: '100%',
      padding: '0px 10px',
    },
    '& div': {
      height: 40,
    },
  },

  label: {
    textAlign: 'left',
    fontSize: 14,
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#222',

    marginBottom: 8,
  },

  actions: {
    padding: theme.spacing(3, 5),
    background: colors.white80,
  },

  action: {
    borderRadius: '8px !important',
    borderWidth: 2,
    margin: '0px 15px',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.20000000298023224px',
    textAlign: 'center',

    '& span': {
      textTransform: 'capitalize !important',
    },

    '&.contained': {
      borderColor: colors.bluePrimary,
      backgroundColor: colors.bluePrimary,
      minWidth: 130,

      '&:hover': {
        backgroundColor: colors.bluePrimary,
        borderColor: colors.bluePrimary,
        borderWidth: 2,
      },

      '&:disabled': {
        backgroundColor: '#ccc',
        borderColor: '#ccc',
      },
    },

    '&.outlined': {
      color: colors.bluePrimary,
      borderColor: colors.bluePrimary,
      borderWidth: 2,
      minWidth: 130,

      '&.error': {
        color: colors.erro,
        borderColor: colors.erro,

        '&:hover': {
          borderColor: colors.erro,
          borderWidth: 2,
        },
      },

      '&:hover': {
        borderColor: colors.bluePrimary,
        borderWidth: 2,
      },

      '&:disabled': {
        color: '#ccc',
        borderColor: '#ccc',
      },
    },
  },

  helper: {
    padding: theme.spacing(2, 5),
    background: colors.white60,
    display: 'flex',
    justifyContent: 'space-around',

    '& h6, p, a': {
      fontFamily: 'Nunito Sans',
      color: colors.info,
    },
    '& h6': {
      fontSize: 16,
      fontWeight: 800,
    },
    '& p': {
      fontSize: 14,
    },

    '& svg': {
      marginRight: 25,
    },
  },
}))
