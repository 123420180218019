/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'
import styled from 'styled-components'

import { ADD_NODE } from '../../constants/actions'
import { useBoard } from '../../context/BoardProvider'
import { eventTypes } from '../../utils'
import { pageTypes } from '../../utils/pageTypes'

const PaperStyled = styled(Paper)`
  height: 100%;
  width: 100%;
  box-shadow: none;
  background-image: url(${(props) => (props.src ? props.src : 'none')});
  background-repeat: no-repeat;
  background-size: ${(props) => (props.$isEvent ? 'contain' : 'cover')};
  background-position: ${(props) => (props.$isEvent ? 'center' : 'top')};
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;

  ${(props) =>
    props.$isEvent &&
    `
    background-color: #f1f1f1;
    border-radius: 0;
  `};
`

const GridItemStyled = styled(Grid)`
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  :hover {
    border-radius: 2px;
    box-shadow: 0 0 0 2px #4c6fff;
    transform: rotate(2deg);
  }

  ${(props) =>
    props.$isEvent &&
    `
    border-radius: 4px;
    border: solid 2px #e5e8f5;
    &&& {
      padding: 0;
    }
  `};
`

const Title = styled.div`
  color: #50525e;
  text-align: center;

  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 10px;
  white-space: nowrap;
  background-color: #e5e8f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-weight: 500;
  margin: ${(props) => (props.$isEvent ? '0' : '0 7px')};
`
const TabsStyled = styled(Tabs)`
  border-top: 1px #e5e8f5 solid;
  border-bottom: 1px #e5e8f5 solid;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  )
}

export default function SidebarLeft() {
  const [category, setCategory] = React.useState(0)
  const { dispatch, getStageCenter } = useBoard()

  const handleChange = (event, newValue) => {
    setCategory(newValue)
  }

  const handleOnClick = (nodeSelected) => {
    dispatch({
      type: ADD_NODE,
      payload: {
        ...nodeSelected,
        position: getStageCenter(),
      },
    })
  }

  return (
    <>
      <TabsStyled
        value={category}
        onChange={handleChange}
        aria-label="Category tabs"
        variant="fullWidth"
        scrollButtons="off"
        indicatorColor="primary"
      >
        <Tab label="Páginas" id="simple-tab-page" aria-controls="simple-tabpanel-page" />
        <Tab label="Eventos" id="simple-tab-event" aria-controls="simple-tabpanel-event" />
      </TabsStyled>
      <TabPanel value={category} index={0}>
        <Grid
          container
          justifyContent="space-evenly"
          alignContent="flex-start"
          style={{ rowGap: '1rem' }}
        >
          {pageTypes.map((page) => (
            <GridItemStyled key={JSON.stringify(page)} item>
              <PaperStyled onClick={() => handleOnClick(page)} src={page.src} />
              <Title>{page.name}</Title>
            </GridItemStyled>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={category} index={1}>
        <Grid container justifyContent="space-evenly" spacing={1} style={{ rowGap: '1rem' }}>
          {eventTypes.map((event) => (
            <GridItemStyled key={JSON.stringify(event)} item $isEvent>
              <PaperStyled onClick={() => handleOnClick(event)} src={event.src} $isEvent />
              <Title $isEvent>{event.name}</Title>
            </GridItemStyled>
          ))}
        </Grid>
      </TabPanel>
    </>
  )
}
