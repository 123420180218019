import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import React from 'react'
import styled from 'styled-components'

import { Domain } from '~/types'

import { ImportSetupContent } from './ImportSetupContent'

const DrawerContent = styled.div`
  margin: 0 auto;
  /* padding: 16px 24px; */
  justify-content: center;
  align-items: flex-start;
`

interface ImportSetupDrawerProps {
  isOpen: boolean
  handleClose: () => void
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  formState: {
    name: {
      value: string
      error: boolean
    }
    domain: {
      value: number
      error: boolean
    }
  }
  handleSubmit: ({ name, domain }: { name: string; domain: number }) => void
  domainList: Domain[]
}

export const ImportSetupDrawer = (props: ImportSetupDrawerProps) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const { isOpen, handleClose } = props

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <DrawerContent>
        <ImportSetupContent {...props} />
      </DrawerContent>
    </SwipeableDrawer>
  )
}
