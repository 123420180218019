import { Box, Divider, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

const useStyles = makeStyles({
  table: {
    marginTop: 40,
    borderRadius: '10px !important',
    minWidth: '100%',
    border: `4px solid ${colors.white60}`,
    color: colors.dark80,
    fontFamily: 'Nunito Sans',
  },
  header: {
    display: 'flex',
    borderBottom: `2px solid ${colors.white60}`,
    background: colors.white60,
    fontWeight: 600,

    '& .item': {
      fontSize: 14,
      padding: '10px 0px',
      textAlign: 'center',
    },
  },
  info: {
    '& .item': {
      fontSize: 14,
      padding: '10px 0px',
      textAlign: 'center',
    },
  },
})
export default function BasicTable({ path }) {
  const classes = useStyles()
  const rows = [
    createData('@', 'CNAME', 'target.makefunnels.com.br'),
    createData('www', 'CNAME', path),
  ]

  function createData(name, type, value) {
    return { name, type, value }
  }

  return (
    <Box className={classes.table}>
      <Grid container className={classes.header}>
        <Grid item xs={4} className="item">
          Tipo
        </Grid>
        <Grid item xs={4} className="item">
          Nome
        </Grid>
        <Grid item xs={4} className="item">
          Valor (destino)
        </Grid>
      </Grid>

      {rows.map((row, index) => (
        <Box key={row.name}>
          <Grid container className={classes.info}>
            <Grid item xs={4} className="item">
              {row.type}
            </Grid>
            <Grid item xs={4} className="item">
              {row.name}
            </Grid>
            <Grid item xs={4} className="item">
              {row.value}
            </Grid>
          </Grid>

          {rows.length - 1 !== index && (
            <Divider
              style={{
                margin: 'auto 40px',
                color: colors.white60,
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
BasicTable.propTypes = {
  path: PropTypes.string,
}
