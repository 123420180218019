import PropTypes from 'prop-types'
import React from 'react'

// import { Container } from './styles';

function Content({ children }) {
  return <div>{children}</div>
}

Content.propTypes = {
  children: PropTypes.node,
}

export default Content
