import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { shake } from 'react-animations'
import styled, { keyframes } from 'styled-components'

import ChooseFunnel from './ChooseFunnel'
import ChooseFunnelType from './ChooseFunnelType'
import DoneButton from './doneButton'

const bounceAnimation = keyframes`${shake}`

const Bounce = styled.div`
  animation: 1.5s ${bounceAnimation};
`

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default function CreateNewFunnel() {
  const classes = useStyles()
  const [funnelSelected, setFunnelSelected] = useState(null)
  const [typeIsSelected, setTypeIsSelected] = useState(false)

  const handleClick = (type) => {
    setFunnelSelected(type)
  }

  const handleTypeSelected = (value) => {
    setTypeIsSelected(value)
  }

  return (
    <Box m={4}>
      <Container fixed>
        {/* <hr /> */}
        <div className={classes.header}>
          <Typography variant="h4" gutterBottom>
            Novo funil
          </Typography>
          <Bounce>
            <DoneButton isDisabled={!typeIsSelected} />
          </Bounce>
        </div>

        <Box mt={5}>
          <ChooseFunnel onChildClick={handleClick} />
        </Box>
        <Box mt={5}>{ChooseFunnelType(funnelSelected, handleTypeSelected)}</Box>
      </Container>
    </Box>
  )
}
