import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { lazy, Suspense } from 'react'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import { useStatusMachine } from '~/hooks'
import * as canvasServices from '~/services/canvasServices'
import { useAsync } from '~/utils/useAsync'

import { useBoards } from '../../context/BoardsProvider'
import { ModalContext } from './ModalContext'

const ModalComponent = lazy(() => import('~/components/Modal'))

const EditBoard = ({ boardId, validator }) => {
  const dispatch = useAppDispatch()
  const { handleSuccess } = useBoards()
  const { status, error: hasError, run } = useAsync()
  useStatusMachine(status, handleSuccess)
  const [isOpen, setIsOpen] = React.useContext(ModalContext)
  const [validatorValue, setValidatorValue] = React.useState('')
  const [name, setName] = React.useState(validator)
  const [deleteView, setDeleteView] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
    setDeleteView(false)
  }

  const detailContent = {
    open: isOpen,
    title: 'Editar Detalhes',
    inputs: [
      {
        component: 'textField',
        fullWidth: true,
        name: 'board-name',
        type: 'text',
        value: name,
        label: <>Nome do Quadro</>,
        onChange: (event) => {
          setName(event.target.value)
        },
      },
    ],
    buttons: [
      {
        variant: 'contained',
        name: 'Salvar',
        // disabled: validator !== value,
        handleSubmit: () => {
          dispatch({
            type: TOGGLE_LOADING,
            payload: { show: true, background: true },
          })
          canvasServices
            .update(boardId, { name })
            .then(() => {
              dispatch({
                type: SET_ALERT,
                payload: {
                  open: true,
                  message: 'Board salvo com sucesso',
                  variant: 'success',
                },
              })
              handleSuccess()
            })
            .catch(() => {
              dispatch({
                type: SET_ALERT,
                payload: {
                  open: true,
                  message: 'Houve um problema. Tenta por favor mais tarde!',
                  variant: 'error',
                },
              })
            })
            .finally(() => {
              dispatch({
                type: TOGGLE_LOADING,
                payload: { show: false, background: true },
              })
              handleClose()
            })
        },
      },
      {
        variant: 'outlined',
        name: 'Deletar',
        customClass: 'error',
        // disabled: validator !== value,
        handleSubmit: (e) => {
          setDeleteView(true)
        },
      },
    ],
  }
  // const deleteContent = { open: isOpen, title: 'Tem certeza?', inputs, buttons }
  const deleteContent = {
    open: isOpen,
    title: 'Tem certeza?',
    inputs: [
      {
        component: 'textField',
        fullWidth: true,
        name: 'validator-name',
        type: 'text',
        value: validatorValue,
        label: (
          <>
            Para confirmar, por favor digite <b>&quot;{validator}&quot;</b>
          </>
        ),
        onChange: (event) => {
          setValidatorValue(event.target.value)
        },
      },
    ],
    buttons: [
      {
        variant: 'outlined',
        customClass: 'error',
        name: 'Sim, exclua este quadro',
        disabled: validator !== validatorValue,
        handleSubmit: () => {
          run(canvasServices.remove(boardId))
        },
      },
    ],
  }

  return (
    <>
      {isOpen && (
        <Suspense>
          <ModalComponent
            actions={{ handleClose }}
            state={deleteView ? deleteContent : detailContent}
          >
            {deleteView && (
              <>
                <Typography variant="h6" gutterBottom>
                  Tem certeza que deseja excluir esse Quadro?
                </Typography>
                <Typography variant="body2" className="disclaimer" my={3}>
                  Esta é uma operação irreversível e todos os dados referentes à este Quadro serão
                  apagados.
                </Typography>
              </>
            )}
          </ModalComponent>
        </Suspense>
      )}
    </>
  )
}

EditBoard.propTypes = {
  boardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validator: PropTypes.string,
}

export { EditBoard }
