import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React from 'react'

import colors from '~/utils/colors'

const useStyles = makeStyles({
  button: (props) => ({
    borderRadius: '8px !important',
    color: props.variant === 'contained' ? '#fff !important' : `${colors.bluePrimary} !important`,
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: props.fullWidth ? '100%' : props.width || 180,
    padding: props.fullWidth && '26px 14px',
    textAlign: 'center',
    textTransform: 'capitalize',

    backgroundColor: props.variant === 'contained' ? colors.bluePrimary : 'transparent',

    '&:hover': {
      backgroundColor: props.variant === 'contained' && colors.bluePrimary,
    },
  }),
})

function BaseButton({
  variant,
  color,
  handleClick,
  text,
  size,
  fullWidth,
  width,
  children,
  ...rest
}) {
  const classes = useStyles({
    variant,
    fullWidth,
    width,
  })

  return (
    <Button
      variant={variant}
      color={color}
      className={classes.button}
      onClick={handleClick}
      size={size}
      {...rest}
    >
      {text}
      {children}
    </Button>
  )
}

BaseButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.any,
}

BaseButton.defaultProps = {
  color: 'primary',
  size: 'small',
  handleClick: () => {},
}

export default BaseButton
