import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import { DIVERSE_PAGE, LEAD_PAGE, OTO, SALES_PAGE } from '~/constants'
import { categories } from '~/pages/Templates/shared/categories'

import TabsGroup from '../Tabs'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 90,
    width: 140,
    '&:hover': {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
    '&.selected': {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
    },
  },
  notBorderLeft: {
    borderLeftColor: 'transparent',
    borderRadius: 0,
  },
  first: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  last: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  control: {
    padding: theme.spacing(2),
  },
}))
const setupCategories = [SALES_PAGE, LEAD_PAGE, OTO, DIVERSE_PAGE]
const initialCategories = categories.filter((category) => setupCategories.includes(category.id))

export default function ChooseCategory({ selectCategory }) {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={0}>
          <TabsGroup items={initialCategories} onClick={selectCategory} />
        </Grid>
      </Grid>
    </Grid>
  )
}

ChooseCategory.propTypes = {
  selectCategory: PropTypes.func,
}
