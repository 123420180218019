import { Box, Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { getTemplates } from '~/actions'
import { useModal } from '~/components/Modal'
import { SALES_PAGE, SET_ALERT } from '~/constants'
import { useAppDispatch, useAppState } from '~/context'
import * as funnelService from '~/services/funnelService'

import ChooseCategory from './components/ChooseCategory'
import IFrame from './components/IFrame'
import Pagination from './components/Pagination'
import { GridCardsSkeleton } from './components/Skeletons'
import TemplateCard from './components/TemplateCard'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .isShow': {
      visibility: 'hidden',
    },
    '&:hover .isShow': {
      visibility: 'visible',
    },
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  textSecondary: {
    fontStyle: 'italic',
  },
  pagination: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}))

const PAGE_LIMIT = 12

export default function TemplateList() {
  const classes = useStyles()
  const [fetching, setFetching] = useState(false)
  const [previewTemplate, setPreviewTemplate] = useState(null)
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [categoryFilter, setCategoryFilter] = useState(SALES_PAGE)
  const [currentPage, setCurrentPage] = useState(1)
  const dispatchApp = useAppDispatch()
  const history = useHistory()
  const { page_id } = useParams()
  const { open, toggle } = useModal()

  const {
    funnel: { data: funnel },
    templates: { data: templates, isFetching },
  } = useAppState()
  const totalPages = templates && templates.meta ? templates.meta.last_page : 0

  useEffect(() => {
    getTemplates({
      dispatchApp,
      page: 1,
      pageCategory: SALES_PAGE,
      limit: PAGE_LIMIT,
    })
  }, [dispatchApp])

  useEffect(() => {
    if (templates && templates.data) {
      setFilteredTemplates(templates.data)
    }
  }, [isFetching, templates, totalPages])

  const handleOnChangePage = (page) => {
    if (page === currentPage) return
    setCurrentPage(page)
    getTemplates({
      dispatchApp,
      page,
      pageCategory: categoryFilter,
      limit: PAGE_LIMIT,
    })
  }

  const choseTemplate = async (id) => {
    setFetching(true)
    funnelService
      .setTemplateForOnePage(id, page_id)
      .then(() => history.push(`/funnel-detail/${funnel.id}`))
      .catch(() => {
        dispatchApp({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema. Tenta por favor mais tarde!',
            variant: 'error',
          },
        })
      })
      .finally(() => setFetching(false))
  }

  const selectCategory = (category) => {
    if (category === categoryFilter) return
    setCategoryFilter(category)
    setCurrentPage(1)
    if (category === 0)
      return getTemplates({
        dispatchApp,
        limit: PAGE_LIMIT,
      })
    return getTemplates({
      dispatchApp,
      pageCategory: category,
      limit: PAGE_LIMIT,
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Que tipo de página você procura?
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.textSecondary}
              paragraph
            >
              Selecione uma categoria abaixo para filtrar as páginas
            </Typography>
            <div className={classes.heroButtons}>
              <ChooseCategory selectCategory={selectCategory} />
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          {isFetching || fetching ? (
            <GridCardsSkeleton />
          ) : (
            // </Box>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              {filteredTemplates.map((template, index) => (
                <TemplateCard
                  key={template.name + index}
                  template={template}
                  choseTemplate={choseTemplate}
                  previewTemplate={() => {
                    setPreviewTemplate(template)
                    toggle()
                  }}
                />
              ))}
            </Grid>
          )}
          <Box mt={3}>
            <Pagination onChangeFn={handleOnChangePage} total={totalPages} />
          </Box>
        </Container>
      </main>
      {previewTemplate && (
        <IFrame
          open={open}
          src={previewTemplate.path}
          handleClose={toggle}
          handleChose={() => choseTemplate(previewTemplate.id)}
        />
      )}
    </React.Fragment>
  )
}
