import { Typography } from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Feature } from '../../components/Feature'

function Account() {
  const history = useHistory()
  const redirect = () => {
    history.push('/account')
  }

  return (
    <>
      <Feature image={AccountBoxIcon} onClick={redirect}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Minha Conta
        </Typography>
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mauris mauris.
        </Typography>
      </Feature>
    </>
  )
}

export default Account
