import React from 'react'

const UpgradeModalContext = React.createContext()
UpgradeModalContext.displayName = 'UpgradeModalContext'

function UpgradeModalReducer(state, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        open: true,
      }
    }
    case 'close': {
      return {
        ...state,
        open: false,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UpgradeModalProvider({ children }) {
  const [state, dispatch] = React.useReducer(UpgradeModalReducer, {
    open: false,
  })
  const value = [state, dispatch]
  return <UpgradeModalContext.Provider value={value}>{children}</UpgradeModalContext.Provider>
}

function useUpgradeModal() {
  const context = React.useContext(UpgradeModalContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

function openUpgradeModal(dispatch) {
  dispatch({ type: 'open' })
}

function closeUpgradeModal(dispatch) {
  dispatch({ type: 'close' })
}

export { closeUpgradeModal, openUpgradeModal, UpgradeModalProvider, useUpgradeModal }
