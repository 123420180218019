import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useState } from 'react'

const useStyles = makeStyles({
  menu: {
    background: '#222',
    color: '#fff',

    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    '& li': {
      padding: '10px auto',
    },
  },
})

const Menu = ({ options, index }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        ref={anchorEl}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        onClose={() => setAnchorEl(null)}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper id={index} open={open} anchorEl={anchorEl} placement={'bottom-end'} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuList>
                  {options.map((el, index) => {
                    return <MenuItem key={index}>{el}</MenuItem>
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default Menu
