export const SET_PAGE_CATEGORIES = 'SET_PAGE_CATEGORIES'
export const SET_FUNNEL_CATEGORIES = 'SET_FUNNEL_CATEGORIES'
export const SET_HEADER_TITLE = 'SET_HEADER_TITLE'
export const SET_USER = 'SET_USER'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const SET_ALERT = 'SET_ALERT'
export const SET_DOMAINS = 'SET_DOMAINS'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_FUNNEL = 'SET_FUNNEL'
export const SET_PAGES = 'SET_PAGES'
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION'
export const CLEAR_STATE = 'CLEAR_STATE'

export const DEFAULT_MAKEFUNNELS_DOMAIN = 'u.makefunnels.com.br'
export const DEFAULT_MAKEFUNNELS_FAVICON = 'https://makefunnels.com.br/assets/mf/favicon.ico'
export const YOUTUBE_CHANNEL = 'https://www.youtube.com/channel/UC_uinlJGfHu-k2uyd2e7MjQ'
export const YOUTUBE_VIDEO = 'https://www.youtube.com/'
export const AFFILIATE_EXTERNAL_LINK = 'https://makefunnels.com.br/afiliacao'

export const ALL_PAGES = 0
export const SALES_PAGE = 1
export const LEAD_PAGE = 2
export const THANKS_PAGE = 3
export const UP_SELL_PAGE = 4
export const DOWN_SELL_PAGE = 5
export const LAUNCH_PAGE = 6
export const DIVERSE_PAGE = 7
export const OTO = 8
export const ERROR = 'error'

export const ORDER_BY = {
  ASC: '',
  DESC: '-',
}

export const PAGES = {
  HOME: 'home',
  MY_DOMAINS: 'domains',
  REDIRECTION: 'redirection',
}

export const ONBOARDING_VIDEOS = {
  CREATE_PAGE: {
    id: '8bC-Lo-Ay7Y',
    title: 'Como criar páginas incríveis no construtor da MakeFunnels',
  },
  CONFIG_DOMAIN: {
    id: '55W6w37L84c',
    title: 'Como configurar um domínio na MakeFunnels',
  },
  CREATE_REDIRECTION: {
    id: 'RA5lFavvOW8',
    title: 'MakeFunnels | Como criar redirecionamentos para grupo de whatsapp',
  },
}
export const ROLES = {
  FREE: 'free',
  ADMIN: 'admin',
}

export const ASYNC_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
}

export const PLAN_STATE = {
  TRIALING: 'trialing',
  CANCELED: 'canceled',
}

export const PLAN_CODE = {
  FREE: 'FREE',
  STARTER: 'STARTER',
  PLATINUM: 'PLATINUM',
  DIAMOND: 'DIAMOND',
}

export const FEATURES = {
  DOMAINS: 'domains',
  FUNNELS: 'funnels',
  PAGES: 'pages',
}

export const HTTP_ERROS = {
  UNPROCESSABLE_ENTITY: 422,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
}

export const YEAR = 'ano'
export const YEARS = 'anos'
export const MONTH = 'mês'
export const MONTHS = 'meses'

export const DEFAULT_DOMAIN_NAME = '1.dev-u.makefunnels.com.br'

export const STATUS = {
  DOMAIN: {
    ACTIVE: 'active',
    PENDING: 'peding',
    BLOCKED: 'blocked',
  },
  FUNNEL: {
    ACTIVE: 'active',
    BLOCKED: 'blocked',
  },
  PAGE: {
    PUBLISHED: 'published',
    EMPTY: 'empty',
    BLOCKED: 'blocked',
  },
}

export const REGISTERED_PAGES = {
  MY_FUNNELS: {
    index: 0,
    url: '/',
  },
  MY_DOMAINS: {
    index: 1,
    url: '/domains',
  },
  WPP_GROUPS: {
    index: 2,
    url: '/redirection',
  },
  BOARDS: {
    index: 8,
    url: '/boards',
  },
  INTEGRATION: {
    index: 3,
    url: '/integration',
  },
  SETTINGS: {
    index: 7,
    url: '/settings',
  },
}
