import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { SET_ALERT, TOGGLE_LOADING } from '~/constants'
import { useAppDispatch } from '~/context'
import { useAlert } from '~/hooks/useAlert'
import { create, edit, readOne } from '~/services/redirectionService'

import { RedirectionProvider, useRedirectionContext } from './context'
import View from './view'

function destructureError(error) {
  return Object.keys(error)?.map((key) =>
    typeof error[key] === 'string' ? error?.[key] : error[key]?.join('. '),
  )
}

function Create() {
  const { id } = useParams()
  const { setAlert } = useAlert()
  const { state: contextState, setState: contextSetState } = useRedirectionContext()
  const dispatchApp = useAppDispatch()
  const history = useHistory()
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [redirectionForm, setRedirectionForm] = useState({
    name: '', //'required|string|max:70',
    path: '', //'required|string',
    text: '', //'string|max:255',
    reenter: 0, //'boolean',
  })
  const [visits_per_link, setVisits_per_link] = useState(350)
  const [expires_at, handleSetExpires_at] = useState(null)
  const [tracking, setTracking] = useState({
    fb: {
      type: 'fb',
      enabled: 1,
      value: '',
      token: '',
      source_url: '',
    },
    ga: {
      type: 'ga',
      enabled: 1,
      value: '',
    },
    gtm: {
      type: 'gtm',
      enabled: 1,
      value: '',
    },
  })

  const initCustomCodeTracking = (codes) => {
    let trackingAux = tracking

    codes.forEach((code) => {
      trackingAux = {
        ...trackingAux,
        [code.type]: {
          ...code,
        },
      }
    })

    setTracking(trackingAux)
  }

  useEffect(() => {
    const initState = (formValues) => {
      const formData = {
        ...redirectionForm,
        expires_at,
        tracking,
        ...formValues,
      }

      setRedirectionForm({
        name: formData.name,
        path: formData.path,
        text: formData.text,
        reenter: Number(!!formData.reenter),
      })
      if (formData.links.length > 0) {
        contextSetState({
          urls: formData.links,
        })
      }
      if (formData.image_url) {
        const imgName = formData.image_url.split('u1/')[1]
        contextSetState({
          file: {
            name: imgName,
            url: formData.image_url,
          },
        })
      }
      if (formData.metrics) {
        contextSetState({
          metrics: { ...formData.metrics },
        })
      }
      if (formData.expires_at) {
        setShowDatePicker(true)
        handleSetExpires_at(new Date(formData.expires_at))
      }
      formData.tracking.length && initCustomCodeTracking(formData.tracking)
    }
    async function get() {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: true, background: true },
      })

      try {
        const response = await readOne(dispatchApp, id)
        initState(response)
      } catch (error) {
        typeof error === 'string' && setAlert(error, 'error')
      }
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: true },
      })
    }
    if (id) {
      get()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  function handleChangeTracking(event) {
    const { name, value } = event.target
    let val = value
    if (name === 'ga' && val !== '') {
      const [tagStart, rest] = value.split('-')
      if (!['UA', 'G'].includes(tagStart)) {
        val = 'UA-' + value
      } else if (!rest && val.length > 2) val = ''
    }

    setTracking({
      ...tracking,
      [name]: {
        ...tracking[name],
        value: val,
      },
    })
  }
  function handleChangeFacebookTracking(event) {
    setTracking({
      ...tracking,
      fb: {
        ...tracking['fb'],
        [event.target.name]: event.target.value,
      },
    })
  }

  function handleChangeContextValue(event) {
    if (event.target.name === 'visits_per_link') {
      const value = Number(event.target.value) || 1
      const { urls } = contextState
      const copyUrls = [...urls]
      const urlsUpdated = copyUrls.map((url) => ({ ...url, visits_limit: value }))

      contextSetState({
        urls: urlsUpdated,
      })
      setVisits_per_link(value)
    }
    contextSetState({
      [event.target.name]: event.target.value,
    })
  }

  function handleChange(event) {
    setRedirectionForm({
      ...redirectionForm,
      [event.target.name]:
        event.target.name !== 'reenter' ? event.target.value : Number(event.target.checked),
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: true, background: true },
    })
    const requestData = {
      ...redirectionForm,
      links: contextState.urls,
    }
    const findUrlError = requestData.links.find((el) => el.visits_limit < el.visits)
    if (findUrlError) {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: true },
      })
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message:
            'Número de visitas por link deve ser maior ou igual ao de visitas contabilizadas',
          variant: 'error',
        },
      })
      return
    }

    if (requestData.text === '') {
      delete requestData.text
    }

    if (contextState.file === null) requestData.image = contextState.file
    else {
      requestData.image = contextState.file.type ? contextState.file : contextState.file.url
    }

    const parseTracking = Object.keys(tracking).map((code) => ({
      ...tracking[code],
    }))

    const hasValue = parseTracking.filter((track) => track.value !== '')
    if (hasValue.length > 0) {
      requestData.tracking = hasValue
    }

    if (expires_at) {
      const tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
      const localISOTime = new Date(expires_at.getTime() - tzoffset).toISOString()
      requestData.expires_at = localISOTime
    }
    const request = id
      ? () => {
          requestData._method = 'PUT'
          return edit(dispatchApp, id, requestData)
        }
      : () => create(dispatchApp, requestData)

    try {
      await request()
      history.push(`/redirection`)
    } catch (error) {
      const message = typeof error === 'string' ? error : destructureError(error)
      setAlert(message, 'error')
    } finally {
      dispatchApp({
        type: TOGGLE_LOADING,
        payload: { show: false, background: true },
      })
    }
  }

  const props = {
    id,
    redirectionForm,
    visits_per_link,
    showDatePicker,
    expires_at,
    tracking,
    setShowDatePicker,
    handleSubmit,
    handleSetExpires_at,
    handleChange,
    handleChangeTracking,
    handleChangeContextValue,
    handleChangeFacebookTracking,
  }
  return <View {...props} />
}

Create.displayName = 'Redirection_Create'

export default function RedirectionCreate() {
  return (
    <RedirectionProvider>
      <Create />
    </RedirectionProvider>
  )
}
