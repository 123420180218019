/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import uuid from 'react-uuid'

import { useLoadData } from '~/hooks'
import * as canvasServices from '~/services/canvasServices'

import {
  ADD_CONNECTION,
  ADD_NODE,
  COPY_NODE,
  REMOVE_CONNECTION,
  REMOVE_NODE,
  SET,
  UPDATE_POSITIONS,
} from '../constants/actions'
import { BoardContext, boardContextDefault } from './BoardInitialState'

const getElementIndex = (id, nodes) => nodes.findIndex((el) => el.id === id)

function boardReducer(state, action) {
  switch (action.type) {
    case ADD_NODE: {
      const nodesUpdated = [
        ...state.nodes,
        {
          ...action.payload,
          id: uuid(),
          match: [],
        },
      ]

      return { ...state, nodes: nodesUpdated }
    }
    case REMOVE_NODE: {
      const { id } = action.payload

      let nodesCopy = [...state.nodes]
      nodesCopy = nodesCopy.map((el) => {
        const index = el.match.indexOf(id)
        if (index !== -1) {
          el.match.splice(index, 1)
        }
        return el
      })
      const nodesUpdated = nodesCopy.filter((el) => el.id !== id)

      return { ...state, nodes: nodesUpdated }
    }
    case COPY_NODE: {
      const { id, position } = action.payload
      const index = getElementIndex(id, state.nodes)
      let nodesUpdated = [...state.nodes]

      nodesUpdated = [
        ...state.nodes,
        {
          ...nodesUpdated[index],
          id: uuid(),
          position: position,
          match: [],
        },
      ]

      return { ...state, nodes: nodesUpdated }
    }
    case ADD_CONNECTION: {
      const { id, to } = action.payload
      const nodesUpdated = [...state.nodes]
      const index = getElementIndex(id, state.nodes)

      nodesUpdated[index] = {
        ...nodesUpdated[index],
        match: [...nodesUpdated[index].match, to],
      }

      return { ...state, nodes: nodesUpdated }
    }
    case REMOVE_CONNECTION: {
      const { id, matchId } = action.payload
      const nodesUpdated = [...state.nodes]
      const elementIndex = nodesUpdated.findIndex((el) => el.id === id)

      const index = nodesUpdated[elementIndex].match.indexOf(matchId)
      if (index !== -1) {
        nodesUpdated[elementIndex].match.splice(index, 1)
      }
      return { ...state, nodes: nodesUpdated }
    }
    case UPDATE_POSITIONS: {
      return { ...state, nodes: action.payload }
    }
    case SET: {
      return { ...state, ...action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function BoardProvider({ boardId, children }) {
  const { data, isFetching, runRequest } = useLoadData(() => canvasServices.get(boardId))
  const [board, dispatch] = React.useReducer(boardReducer, boardContextDefault)
  const stageRef = React.useRef(null)

  useEffect(() => {
    if (!board?.id) {
      dispatch({ type: SET, payload: data })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  const getStageCenter = () => {
    const stage = stageRef.current
    const scale = stage.scaleX()

    const pointer = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    }

    const mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale,
    }

    return {
      x: mousePointTo.x,
      y: mousePointTo.y,
    }
  }

  const getPointWithScale = (pointer) => {
    const stage = stageRef.current
    const scale = stage.scaleX()

    const mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale,
    }

    return {
      x: mousePointTo.x,
      y: mousePointTo.y,
    }
  }

  const value = {
    board,
    isFetching,
    dispatch,
    runRequest,
    stageRef,
    getStageCenter,
    getPointWithScale,
  }

  return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
}

function useBoard() {
  const context = React.useContext(BoardContext)
  if (context === undefined) {
    throw new Error('useBoard must be used within a BoardProvider')
  }
  return context
}

export { BoardProvider, getElementIndex, useBoard }
