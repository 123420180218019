import { InputLabel as InputLabelBase } from '@material-ui/core'
import styled from 'styled-components'

export const InputLabel = styled(InputLabelBase)`
  text-align: left;
  font-size: 14px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  color: #222;
  margin-bottom: 0.5rem;
`
