import React from 'react'

import { getSettings, updateSetting as _updateSetting } from '~/services/settings'

const SettingsContext = React.createContext()

const settingsReducer = (state, newState) => ({
  ...state,
  ...newState,
})

// eslint-disable-next-line react/prop-types
function SettingsProvider({ children }) {
  const [state, dispatch] = React.useReducer(settingsReducer, {
    error: null,
    loading: true,
  })

  const value = [state, dispatch]
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

function useSettings() {
  const context = React.useContext(SettingsContext)
  if (context === undefined) {
    throw new Error('useSettingsState must be used within a settingsProvider')
  }
  return context
}

async function loadSettings(dispatch) {
  dispatch({ loading: true })
  try {
    const response = await getSettings()

    const {
      data: { data },
    } = response
    dispatch({ loading: false, ...data })
  } catch (error) {
    dispatch({ loading: false, error: true })
  }
}

async function updateSetting(setting, value) {
  try {
    await _updateSetting({ [setting]: value })
  } catch (error) {
    console.log(error)
  }
}

export { loadSettings, SettingsProvider, updateSetting, useSettings }
