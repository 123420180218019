import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Onboarding from '~/components/Onboarding'
import { FixedContent } from '~/components/UpgradeModal'
import {
  ASYNC_STATE,
  HTTP_ERROS,
  ONBOARDING_VIDEOS,
  PAGES,
  SET_ALERT,
  TOGGLE_LOADING,
} from '~/constants'
import { useAppDispatch } from '~/context'
import {
  duplicateRedirect,
  readAll as getRedirections,
  softDelete,
} from '~/services/redirectionService'
import { useAsync } from '~/utils/useAsync'

import View from './View'

function RedirectionListController() {
  const dispatchApp = useAppDispatch()
  const [redirection, setRedirection] = useState([])
  const [initialState, setInitialState] = useState([])
  const [hasPermission, setHasPermission] = useState(true)
  const { data, status, error, run } = useAsync()
  const isFetching = status === ASYNC_STATE.IDLE || status === ASYNC_STATE.PENDING ? true : false

  useEffect(() => {
    run(getRedirections())
  }, [])

  useEffect(() => {
    if (error?.code === HTTP_ERROS.UNAUTHORIZED) setHasPermission(false)
    if (data) {
      setRedirection(data)
      setInitialState(data)
    }

    dispatchApp({
      type: TOGGLE_LOADING,
      payload: { show: isFetching, background: false },
    })
  }, [status])

  function handleClickCopyButton(domainName, domainPath) {
    const link = `${domainName}/${domainPath}`
    navigator.clipboard.writeText(link)
    dispatchApp({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Link Copiado!',
        variant: 'info',
      },
    })
  }

  async function handleDuplicate(domainId) {
    try {
      await duplicateRedirect(domainId)
      run(getRedirections())
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Redirecionamento duplicado!',
          variant: 'success',
        },
      })
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve algum erro ao duplicar! tente novamente mais tarde.',
          variant: 'error',
        },
      })
    }
  }

  async function handleDelete(domainId) {
    try {
      await softDelete(domainId)
      run(getRedirections())
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Redirecionamento deletado com sucesso.',
          variant: 'success',
        },
      })
    } catch (error) {
      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Houve algum erro ao deletar! tente novamente mais tarde.',
          variant: 'error',
        },
      })
    }
  }

  function handleSearch(value) {
    const newArr = initialState

    const aux = newArr.filter((el) => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    return setRedirection(aux)
  }

  if (!hasPermission) {
    return (
      <Box justifyContent="center" alignItems="center" display="flex">
        <Box width={800}>
          <FixedContent />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Onboarding page={PAGES.REDIRECTION} video={ONBOARDING_VIDEOS.CREATE_REDIRECTION} />
      <View
        domains={redirection}
        isFetching={isFetching}
        handleClickCopyButton={handleClickCopyButton}
        handleDuplicate={handleDuplicate}
        handleDelete={handleDelete}
        handleSearch={handleSearch}
      />
    </>
  )
  // return <View domains={domains.data} isFetching={domains.isFetching} />
}

export default RedirectionListController
