// dispatch({ type: SET_HEADER_TITLE, payload: pageTitle })
import HeaderTitleHOC from '~/utils/components/HeaderTitleHOC'

import Home from './components/home/homeController'

// export default {
//     routeProps: {
//         path: '/',
//         component: Home,
//     },
//     name: 'Home',
// };

export default HeaderTitleHOC(Home, 'Page Home')
