import { IconButton } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { RANGE, SCALE_BY } from '../../constants/scale'

const WrapperStyled = styled.div`
  position: absolute;
  bottom: 20px;
  right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e8f5;
  z-index: 99999;
  height: 40px;
  width: 150px;
`

const IconButtonStyled = styled(IconButton)`
  &&& {
    padding: 7px;
  }
`

const newPosition = (stage, oldScale, scale) => {
  //   debugger
  const pointer = {
    x: stage.width() / 2,
    y: stage.height() / 2,
  }

  const mousePointTo = {
    x: (pointer.x - stage.x()) / oldScale,
    y: (pointer.y - stage.y()) / oldScale,
  }

  const newPos = {
    x: pointer.x - mousePointTo.x * scale,
    y: pointer.y - mousePointTo.y * scale,
  }

  stage.position(newPos)
}

const ZoomControl = ({ stageRef, percentil, setPercentil }) => {
  const increaseZoom = (e) => {
    e.preventDefault()

    const stage = stageRef.current
    const oldScale = stage.scaleX()

    const newScale = oldScale + SCALE_BY

    if (newScale <= RANGE.MAX) {
      stage.scale({ x: newScale, y: newScale })
      setPercentil(newScale * 100)
      newPosition(stage, oldScale, newScale)
    }
  }

  const decreaseZoom = (e) => {
    e.preventDefault()

    const stage = stageRef.current
    const oldScale = stage.scaleX()

    const newScale = oldScale - SCALE_BY

    if (newScale >= RANGE.MIN) {
      stage.scale({ x: newScale, y: newScale })
      setPercentil(newScale * 100)
      newPosition(stage, oldScale, newScale)
    }
  }

  return (
    <WrapperStyled>
      <IconButtonStyled color="inherit" aria-label="increase zoom" onClick={decreaseZoom}>
        <RemoveIcon />
      </IconButtonStyled>
      <div>{percentil}%</div>
      <IconButtonStyled color="inherit" aria-label="increase zoom" onClick={increaseZoom}>
        <AddIcon />
      </IconButtonStyled>
    </WrapperStyled>
  )
}

ZoomControl.propTypes = {
  stageRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  percentil: PropTypes.func,
  setPercentil: PropTypes.number,
}

export default ZoomControl
