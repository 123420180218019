import { Typography } from '@material-ui/core'
import React from 'react'

import ModalComponent from '~/components/Modal'
import colors from '~/utils/colors'

interface ShareAsTemplateConfirmation {
  isOpen: boolean
  handleClose: () => void
  onClickConfirmation: () => void
  isSharing: boolean
}

function ShareAsTemplateConfirmation({
  isOpen,
  isSharing,
  handleClose,
  onClickConfirmation,
}: ShareAsTemplateConfirmation) {
  const buttons = [
    {
      variant: 'contained',
      //   customClass: 'error',
      name: 'Confirmar',
      //   disabled: validator !== pageName,
      handleSubmit: onClickConfirmation,
    },
  ]

  return (
    <ModalComponent
      actions={{ handleClose }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      state={{ open: isOpen, buttons, title: 'Compartilhar Funil' }}
    >
      {isSharing ? (
        <Typography variant="h6" gutterBottom>
          Tem certeza que deseja <span style={{ color: colors.erro }}>parar de compartilhar</span>{' '}
          este funil?
        </Typography>
      ) : (
        <Typography variant="h6" gutterBottom>
          Por favor, confirme que deseja gerar um link público desse funil. Você poderá parar de
          compartilhar quando quiser.
        </Typography>
      )}
      {/* className="disclaimer" */}
      {/* <Typography variant="body2">Esta é uma operação irreversível.</Typography> */}
    </ModalComponent>
  )
}

export default ShareAsTemplateConfirmation
