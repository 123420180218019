import { Button as ButtonBase, ButtonProps } from '@material-ui/core'
import styled from 'styled-components'

import colors from '~/utils/colors'

const VARIANT_COLORS = {
  BLUE: colors.bluePrimary,
  PINK: colors.pink,
}

type CustomButtonProps = {
  layout: 'containedDark' | 'outlineDark' | 'textDark' | 'contained' | 'outlined' // Customize variants as needed
} & ButtonProps // Omitting the variant prop from ButtonProps

const containedVariation = (color: string) => `
    border-color: ${color};
    background-color: ${color};
    min-width: 130px;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    :hover {
      background-color: ${color};
      border-color: ${color};
      border-width: 2px;
    }

    :disabled {
      background-color: #ccc;
      border-color: #ccc;
    }
`

const outlineVariation = (color: string) => `
    {
    color: ${color};
    border-color: ${color};
    border-width: 2px;
    min-width: 130px;

    .error {
      color: ${colors.erro};
      border-color: ${colors.erro};

      :hover {
        border-color: ${colors.erro};
        border-width: 2px;
      }
    }

    :hover {
      border-color: ${color};
      border-width: 2px;
    }

    :disabled {
      color: #ccc;
      border-color: #ccc;
    }
  }
`

const textVariation = (color: string) => `
    {
    color: ${color};

    :hover {
      border-color: ${color};
      border-width: 2px;
    }
  }
`

export const Button = styled(ButtonBase)<CustomButtonProps>`
  border-radius: 8px !important;
  border-width: 2px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;

  span {
    text-transform: capitalize !important;
  }

  ${(props) => {
    switch (props.layout) {
      case 'contained':
        return containedVariation(VARIANT_COLORS.BLUE)

      case 'outlined':
        return outlineVariation(VARIANT_COLORS.BLUE)

      case 'containedDark':
        return containedVariation(VARIANT_COLORS.PINK)

      case 'outlineDark':
        return outlineVariation(VARIANT_COLORS.PINK)
      case 'textDark':
        return textVariation(VARIANT_COLORS.PINK)
    }
  }};
`
