import { Select } from '@material-ui/core'
import styled from 'styled-components'

export const InputSelect = styled(Select)`
  height: 40px;
  select,
  select:focus {
    background: transparent;
    border-radius: 8px;
    border: 2px solid #edf0f7;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  &:div {
    height: 40px;
  }
`

// height: 40px;
//   border-radius: 8px;
//   border: 2px solid #edf0f7;
//   select {
//     height: 40px;
//     background: transparent;
//   }
