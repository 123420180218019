/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import CheckBase from '@material-ui/icons/Check'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ASYNC_STATE, PLAN_CODE } from '~/constants'
import { useAppState } from '~/context'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'
import { ButtonBase, DefaultBox, SkeletonBox, TextBase } from '~/pages/Layout/DefaultLayout'
import colors from '~/utils/colors'
import * as localStorage from '~/utils/localStorage'

export const TextBold = styled(TextBase)`
  &&& {
    font-weight: bold;
  }
`

export const DarkGray = styled.span`
  &&& {
    color: #52575c;
  }
`

export const CheckIcon = styled(CheckBase)`
  &&& {
    font-weight: 800;
    color: #1bc5bd;
    /* color: #f6c209; */
    margin-right: 8px;
  }
`

export const Button = styled(ButtonBase)`
  &&& {
    background-color: ${colors.bluePrimary};
    text-transform: initial !important;
    color: white;
    & span {
      text-transform: 'initial !important';
    }
  }
`

function SkeletonContent() {
  return <SkeletonBox style={{ minHeight: '19rem' }} />
}

function Content({ planCode }) {
  const {
    currentSubscription: {
      plan: { plan },
    },
  } = useAppState()
  const history = useHistory()
  const { data, error, status } = useLoadPlanByCode(planCode)

  if (status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE) return <SkeletonContent />

  if (status === ASYNC_STATE.REJECTED) return <h3>Error..{error}</h3>

  const { name, features, code, default_offer_index, offers } = data || {}
  const { offer_code } = default_offer_index !== undefined && offers?.[default_offer_index]

  const handleClick = () => {
    const token = localStorage.get('@makeFunnels/token')

    if (plan.code === PLAN_CODE.FREE) {
      return (window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}${offer_code}?token=${token}`)
    }

    return history.push(`/upgrade/${code}`)
  }

  return (
    <DefaultBox>
      <TextBold variant="h4" gutterBottom>
        Atualize Agora
      </TextBold>
      <TextBase variant="body2" gutterBottom>
        <DarkGray>
          Faça o upgrade agora mesmo e aproveite todos estes recursos exclusivos do Makefunnels{' '}
          {name}
        </DarkGray>
      </TextBase>
      {features.map((feature, index) => (
        <Box key={`${feature?.name}_${index}`} display="flex" alignItems="center">
          <CheckIcon />
          <TextBase variant="body2">{feature?.name}</TextBase>
        </Box>
      ))}
      <Box mt={2}>
        <Button variant="contained" onClick={handleClick}>
          Atualizar para o plano {name}
        </Button>
      </Box>
    </DefaultBox>
  )
}

function UpgradeCTA({ planCode }) {
  return <Content planCode={planCode} />
}

UpgradeCTA.displayName = 'UpgradeCTA'

export default UpgradeCTA
