import { SET_ALERT, SET_DOMAINS } from '~/constants'

import axios from './config/axios'

export const getDomains = () => axios.get('/domains?_embed=funnels')

export const getDomain = (id) => axios.get(`/domains/${id}`)

export const setDomainNickname = (id, data) => axios.put(`/domains/${id}`, data)

export const deleteDomain = (id) => axios.delete(`/domains/${id}`)

export const verifyDomain = (id) => axios.put(`/domains/${id}/verify`)

export const getPages = (id) => axios.get(`/domains/${id}/pages?filter[status]=published`)

export const setDefaultPage = (id, data) => axios.put(`/domains/${id}`, data)

export const setDefaultErrorPage = (id, { pageId, ...data }) =>
  axios.put(`/domains/${id}`, { default_invalid_page_id: pageId, ...data })

export const createDomain = async (data) => {
  try {
    const response = await axios.post(`/domains`, data)
    return Promise.resolve(response.data.data || 'Cartão de crédito atualizado com sucesso!')
  } catch (error) {
    return Promise.reject(error.response?.data)
  }
}

export async function FetchDomains(dispatchApp) {
  dispatchApp({
    type: SET_DOMAINS,
    payload: {
      isFetching: true,
    },
  })
  try {
    const response = await axios.get('/domains?_embed=funnels&created_at=desc')
    const {
      data: {
        data: [...rest],
      },
    } = response
    dispatchApp({
      type: SET_DOMAINS,
      payload: {
        data: rest,
        isFetching: false,
      },
    })
  } catch (err) {
    dispatchApp({
      type: SET_DOMAINS,
      payload: {
        isFetching: false,
      },
    })
    dispatchApp({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Houve um problema nesta operação. Por favor tente mais tarde.',
        variant: 'error',
      },
    })
  }
}
