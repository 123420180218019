import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'
import { HTTP_ERROS } from '~/constants'

export default function DomainsCreateView({
  state: { domainUrl, hasError, isInputEmpty, open },
  actions: { handleChange, handleSubmit, handleClose },
}) {
  const buttons = [
    {
      name: 'Cancelar',
      variant: 'outlined',
      handleSubmit: handleClose,
    },
    {
      name: 'Adicionar',
      variant: 'contained',
      handleSubmit,
      disabled: hasError || isInputEmpty || domainUrl.length < 4,
    },
  ]

  const inputs = [
    {
      component: 'textField',
      fullWidth: true,
      name: 'domain',
      type: 'text',
      label: 'Domínio',
      value: domainUrl,
      placeholder: 'meudominio.com.br',
      onChange: (e) => handleChange(e, 'domain'),
      error: hasError,
      helper: hasError?.error || '',
    },
  ]

  const helper = {
    title: 'Atenção',
    content: `Se você incluir um domínio que já tem um site rodando, ele sairá do ar. Se for o seu caso e queira mantê-lo no ar, considere usar um subdomínio ou contate nosso suporte.`,
  }

  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{ open, title: 'Novo Domínio', buttons, inputs, helper }}
    >
      <Typography component="h6" gutterBottom>
        Qual domínio você gostaria de usar com a MakeFunnels?
      </Typography>

      {hasError?.code === HTTP_ERROS.UNPROCESSABLE_ENTITY && (
        <Typography variant="body2" className="disclaimer" my={3}>
          Caso este domínio esteja correto e não encontra-se cadastrado em sua conta, por favor,
          entre em contato com nosso suporte.
        </Typography>
      )}
    </ModalComponent>
  )
}

DomainsCreateView.propTypes = {
  state: PropTypes.shape({
    domainUrl: PropTypes.string,
    hasError: PropTypes.any,
    isInputEmpty: PropTypes.bool,
    open: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
  }),
}
