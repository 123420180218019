import * as constants from '~/constants'
import { getIcon } from '~/utils/categoryIcons'

export const categories = [
  { id: constants.ALL_PAGES, name: 'Todas' },
  { id: constants.SALES_PAGE, name: 'Vendas', icon: getIcon(constants.SALES_PAGE) },
  { id: constants.LEAD_PAGE, name: 'Captura', icon: getIcon(constants.LEAD_PAGE) },
  { id: constants.THANKS_PAGE, name: 'Obrigado' },
  { id: constants.UP_SELL_PAGE, name: 'Upsell', icon: getIcon(constants.UP_SELL_PAGE) },
  { id: constants.DOWN_SELL_PAGE, name: 'Downsell', icon: getIcon(constants.DOWN_SELL_PAGE) },
  { id: constants.LAUNCH_PAGE, name: 'Lançamento (CPL)' },
  { id: constants.OTO, name: 'Oto', icon: getIcon(constants.OTO) },
  { id: constants.DIVERSE_PAGE, name: 'Diversa', icon: getIcon(constants.DIVERSE_PAGE) },
]
