import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'

function View({
  state: {
    open,
    data,
    domainList,
    funnelType,
    errorName,
    domainSelected,
    funnelTypeSelected,
    errorSelect,
  },
  actions: { handleClose, handleChange, handleSubmit },
}) {
  const inputs = [
    {
      component: 'select',
      label: 'Tipo *',
      name: 'funnel_category_id',
      optionDisabled: 'Selecione o tipo',
      fullWidth: true,
      value: funnelTypeSelected,
      onChange: handleChange,
      options: funnelType,
      error: errorSelect && errorSelect.indexOf('tipo de funil') !== -1,
      helper: errorSelect && errorSelect.indexOf('tipo de funil') !== -1 ? errorSelect : '',
    },
    {
      label: 'Nome *',
      type: 'text',
      component: 'textField',
      name: 'name',
      fullWidth: true,
      error: !!errorName,
      helper: errorName,
      placeholder: 'Digite o nome do seu funil',
      onChange: handleChange,
    },
    {
      component: 'select',
      label: 'Domínio *',
      fullWidth: true,
      name: 'domain',
      value: domainSelected,
      onChange: handleChange,
      optionDisabled: 'Selecione o domínio',
      options: domainList,
      error: errorSelect && errorSelect.indexOf('domínio') !== -1,
      helper: errorSelect && errorSelect.indexOf('domínio') !== -1 ? errorSelect : '',
    },
  ]

  const buttons = [
    {
      variant: 'contained',
      name: 'Finalizar',
      type: 'submit',
      // disabled: data.name === '' || data.domain === 0 || data.funnel_category_id === 0,
      handleSubmit,
    },
  ]

  return (
    <ModalComponent
      state={{
        open,
        title: 'Criar Funil do Zero',
        inputs,
        buttons,
        handleSubmit,
        // isLarge: true
      }}
      actions={{ handleClose }}
    >
      <Typography component="h6" gutterBottom>
        Defina um tipo e nome para o seu funil e escolha o domínio onde ele deve ser inserido.
      </Typography>
    </ModalComponent>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    errorName: PropTypes.string,
    errorSelect: PropTypes.string,
    domainSelected: PropTypes.any,
    funnelTypeSelected: PropTypes.any,
    domainList: PropTypes.array,
    funnelType: PropTypes.array,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
    handleBack: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}

export default View
