/* eslint-disable react/prop-types */
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'

import colors from '~/utils/colors'

const TabStyled = withStyles({
  textColorInherit: {
    color: 'inherit',
    opacity: 0.7,
    '&.Mui-selected': {
      color: colors.bluePrimary,
      opacity: 1,
    },
    '&$disabled': {
      opacity: 0.5,
    },
  },
  wrapper: {
    flexDirection: 'row',
    gap: '0.2rem',
  },
  root: {
    minHeight: '48px',
  },
})(Tab)
const TabsStyled = withStyles({
  indicator: {
    background: colors.bluePrimary,
  },
})(Tabs)
const PaperStyled = withStyles({
  root: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
  },
})(Paper)

export default function TabsGroup({ items, onClick }) {
  //   const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  if (!items) return

  return (
    <PaperStyled square>
      <TabsStyled
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="icon label tabs example"
        centered
      >
        {items.map((item, index) => (
          <TabStyled
            key={`tab_${index}_${item.name}`}
            icon={item.icon}
            label={item.name}
            onClick={() => onClick(item.id)}
          />
        ))}
      </TabsStyled>
    </PaperStyled>
  )
}
