import React from 'react'

import HomeView from './homeView'

function HomeController() {
  function handleSearch(arr, value) {
    if (value.length) {
      const aux = arr
        .map((el) => {
          const findFunnel = el.funnels.filter(
            (el) => el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
          )

          if (findFunnel.length) {
            return {
              ...el,
              funnels: findFunnel,
            }
          }

          return null
        })
        .filter((el) => el !== null)

      let foundFunnels = aux.reduce((accumulator, currentValue) => {
        const value = (currentValue.funnels && currentValue.funnels.length) || 0
        return accumulator + value
      }, 0)

      return foundFunnels ? aux : []
    }

    return arr
  }
  return <HomeView handleSearch={handleSearch} />
}

export default HomeController
