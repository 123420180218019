// import _pagarme from 'pagarme'

function validate(card) {
  return pagarme.validate({ card: card })
}

function cardHash(card) {
  return pagarme.client
    .connect({ encryption_key: process.env.REACT_APP_ENCRYPTION_KEY })
    .then((client) => client.security.encrypt(card))
}

export const pagarme = {
  validate,
  cardHash,
}
