import { useHistory } from 'react-router-dom'

import * as localStorage from '~/utils/localStorage'

const useRegisterLastPath = () => {
  const history = useHistory()

  const registerLastPath = (index, url) => {
    localStorage.save('lastPath', JSON.stringify({ index, url }))
    history.push(url)
  }

  return { registerLastPath }
}

export default useRegisterLastPath
