import { Box } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { PLAN_CODE } from '~/constants'
import { TextBase } from '~/pages/Layout/DefaultLayout'
import { Plan } from '~/types'

import { MONTH, YEAR } from '../../utils/constants'
import { Current, DowngradeNow, UpdateNow } from './CardFooter'
import { Card, CheckIcon, ContentTitle, Price, SubTitle, Title } from './styles'

const featureValueTypes = {
  YES: 'Y',
  UNLIMITED: 'U',
}

type PlanItemProps = {
  plan: Plan
  currentCodePlan: string
  canUpgradeToPlanList: string[]
}

function normalizePrice(price: number): string {
  return Number.parseFloat(String(price / 100))
    .toFixed(2)
    .replace('.', ',')
}

function intervalText(interval: string | undefined): string {
  if (!interval) return ''
  switch (interval) {
    case YEAR:
      return 'Cobrado anualmente'
    case MONTH:
      return 'Cobrado mensalmente'
    default:
      return ''
  }
}

function featureText(value: string, name: string): string {
  switch (value) {
    case featureValueTypes.YES:
      return `${name}`
    case featureValueTypes.UNLIMITED:
      return `${name} ilimitado`
    default:
      return `${value} ${name}`
  }
}

const PlanItem: React.FC<PlanItemProps> = ({ plan, currentCodePlan, canUpgradeToPlanList }) => {
  const history = useHistory()
  const offer = plan.default_offer_index !== undefined && plan.offers?.[plan.default_offer_index]
  const isFreePlan = plan.code === PLAN_CODE.FREE
  const price =
    typeof offer === 'object' && offer.offer_price > 0 && `R$ ${normalizePrice(offer?.offer_price)}`
  const interval = offer && intervalText(offer?.offer_interval)

  function downgradeRedirect(): void {
    const link = `/${plan.must_select_resources ? 'resources' : 'plan_cancel'}/${plan.code}`
    return history.push(link)
  }

  function upgradeRedirect(): void {
    return history.push(`/upgrade/${plan.code}`)
  }

  function footer(): React.ReactElement {
    if (String(plan.code).toLowerCase() === String(currentCodePlan).toLowerCase())
      return <Current />
    if (canUpgradeToPlanList?.length && canUpgradeToPlanList.includes(plan.code))
      return <UpdateNow redirectTo={upgradeRedirect} />
    else return <DowngradeNow downgradeRedirect={downgradeRedirect} />
  }

  return (
    <>
      <Card display="flex" justifyContent="space-between" flexDirection="column">
        <Box>
          <ContentTitle>
            <Title>{plan.name}</Title>
            <Price>{price}</Price>
            {!isFreePlan && <SubTitle variant="body2">{interval} </SubTitle>}
          </ContentTitle>
        </Box>
        <Box mt={3} display="flex" justifyContent="center" alignItems="center">
          {footer()}
        </Box>
      </Card>
      <Box mt={3}>
        {plan?.features.map((feature, index) => (
          <Box key={`${feature?.name}_${index}`} display="flex" alignItems="center">
            <CheckIcon />
            <TextBase variant="body2">{featureText(feature.value, feature?.name)}</TextBase>
          </Box>
        ))}
      </Box>
    </>
  )
}

export default PlanItem
