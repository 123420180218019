import { AppBar, Box, InputLabel, Paper, TextField, Toolbar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

import makefunnelsLogo from '~/assets/images/makefunnels_dark.png'
import colors from '~/utils/colors'
import { MenuProfile } from '~/utils/components'

const useStyles = makeStyles((theme) => ({
  navbar: {
    flexGrow: 1,
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    background: 'white',
    color: '#909090',
    boxShadow: 'none',
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    width: 192,
    height: 30,
  },
  content: {
    background: colors.white60,
    flexGrow: 2,
    height: '100%',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    background: colors.blueDark,
    width: 557,
    height: 510,
    boxShadow: 'none',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  title: {
    padding: '25px 45px',
    '& h4': {
      fontFamily: 'Nunito Sans',
      color: colors.dark100,
      fontWeight: 700,
    },
    borderBottom: '2px solid ' + colors.white60,
  },
  disclaimer: {
    padding: '30px 45px 0px',
    '& p': {
      fontFamily: 'Nunito Sans',
      color: colors.dark100,
      fontSize: 20,
    },
  },
  form: {
    // padding: theme.spacing(4),
    padding: '0px 45px 45px',
    display: 'flex',
    alignItems: 'flex-end',

    textAlign: 'left',
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  label: {
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    color: colors.dark100,
    paddingBottom: 5,
  },
  subdomainInput: {
    height: 40,
    background: '#FFFFFF',
    borderRadius: 8,
    '& fieldset': {
      height: 45,
      borderRadius: 8,
      border: '2px solid #EDF0F7',
      boxSizing: 'border-box',
    },
    '& div': {
      height: 40,
    },
    '& input': {
      padding: '0 20px !important',
    },
    '& svg': {
      color: '#A9ABAE',
    },
    '& p': {
      marginTop: 5,
      fontFamily: 'Nunito Sans',
      fontSize: 10,
      color: colors.dark20,
    },
  },
  actions: {
    background: colors.white80,
    height: 85,
    padding: '25px 45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    '& button, & button:hover': {
      textTransform: 'capitalize',
      background: colors.button.main,
      borderRadius: colors.button.radius,
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
    },
  },
}))

export default function View({ action: { handleSubmit } }) {
  const classes = useStyles()
  const [name, setName] = React.useState('')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div className={classes.navbar}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarContent}>
            <img className={classes.avatar} src={makefunnelsLogo} alt="makefunnels logo" />
            <MenuProfile />
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.content}>
        <Paper className={classes.card}>
          <Box className={classes.title}>
            <Typography variant="h4">Defina seu subdomínio</Typography>
          </Box>
          <Box className={classes.disclaimer}>
            <Typography paragraph>
              Boas notícias! <br />
              <br /> Você tem direito a um subdomínio exclusivo da MakeFunnels! Defina-o no campo
              abaixo.
            </Typography>
          </Box>
          <Box className={classes.form}>
            <Box>
              <InputLabel htmlFor="subdomain-name" className={classes.label}>
                Subdomínio
              </InputLabel>
              <Box className={classes.subdomainInput}>
                <TextField
                  name="name"
                  id="subdomain-name"
                  variant="outlined"
                  placeholder="meu negócio"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required={true}
                />
                <Typography paragraph>
                  É possível digitar letras sem acento, números e traço.
                </Typography>
              </Box>
            </Box>
            <Box style={{ paddingBottom: 10, paddingLeft: 10 }}>.u.makefunnels.com.br</Box>
          </Box>
          <Box className={classes.actions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit(name)}
              disabled={!name.length}
            >
              Adicionar Subdomínio
            </Button>
          </Box>
        </Paper>
      </div>
    </div>
  )
}

View.propTypes = {
  action: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }),
}
