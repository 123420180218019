import { Box, Grid, Typography } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import makefunnelsLogo from '~/assets/images/makefunnels-logo.svg'
import { ASYNC_STATE, DEFAULT_DOMAIN_NAME, FEATURES } from '~/constants'
import { useAppState } from '~/context'
import { useLoadPlanByCode } from '~/hooks/useLoadPlanByCode'
import { ButtonBase, ButtonContained } from '~/pages/Layout/DefaultLayout'
import { Domain, Features, Funnel, Page, Plan, Subscription } from '~/types'

import Disclaimer from './components/Disclaimer'
import MultipleSelect from './components/MultipleSelect'
import { useLoadCurrentPlanWithResources } from './hooks/useLoadCurrentPlanWithResources'
import { AppBar, Brand, Content, Image, Root } from './styles'

interface useAppStateResponse {
  currentSubscription: {
    plan: Subscription
    error: string | null
    status: string
  }
}

function getFunnels(domains: Domain[], selected: number[]) {
  const filteredDomains = domains.filter((domain) => selected.includes(domain.id))
  let joinFunnels: Funnel[] = []
  filteredDomains.map((domain) => {
    const funnels = domain?.funnels || []
    joinFunnels = [...joinFunnels, ...funnels]
  })
  return joinFunnels
}

function getPages(funnels: Funnel[], selected: number[]) {
  const filteredFunnels = funnels.filter((funnel) => selected.includes(funnel.id))
  let joinPages: Page[] = []
  filteredFunnels.map((funnel) => {
    joinPages = [...joinPages, ...funnel.pages]
  })
  return joinPages
}

type ItemProps = {
  id: number
  name: string
}

const Resources: React.FC = () => {
  const history = useHistory()
  const { planCode } = useParams<{ planCode: string }>()
  const {
    currentSubscription: { plan, error: planError, status: planStatus },
  }: useAppStateResponse = useAppState()
  const { data, status, error } = React.useCallback(useLoadCurrentPlanWithResources, [])()
  const currentPlanLoading = status === ASYNC_STATE.PENDING || status === ASYNC_STATE.IDLE
  const targetPlanData = useLoadPlanByCode(planCode)

  const [domainSelected, setDomainSelected] = React.useState<number[]>([])
  const [funnelSelected, setFunnelSelected] = React.useState<number[]>([])
  const [pageSelected, setPageSelected] = React.useState<number[]>([])

  const planLoading = planStatus === ASYNC_STATE.PENDING || planStatus === ASYNC_STATE.IDLE
  const loading = Boolean(currentPlanLoading || planLoading || !data || error)
  const hasError =
    status === ASYNC_STATE.REJECTED || planStatus === ASYNC_STATE.REJECTED || planError || error
  const isAnyValueSelected = Boolean(
    domainSelected.length || funnelSelected.length || pageSelected.length,
  )

  const funnels = useMemo(() => {
    if (!data || !data.domains) return
    return getFunnels(data.domains, domainSelected)
  }, [data, domainSelected])

  const pages = useMemo(() => {
    if (!funnels || !funnels?.length) return
    return getPages(funnels, funnelSelected)
  }, [funnelSelected, funnels])

  const { domains } = data || {}

  useEffect(() => {
    const defaultDomainId = domains?.filter((domain) => domain.is_mf_domain)?.[0].id
    defaultDomainId && setDomainSelected([defaultDomainId])
  }, [domains])

  useEffect(() => {
    setFunnelSelected([])
  }, [domainSelected])

  useEffect(() => {
    setPageSelected([])
  }, [funnelSelected])

  const [domainsLimit, funnelsLimit, pagesLimit] = useMemo(() => {
    const features = targetPlanData.data?.features.reduce(
      (prev: { [k: string]: Features }, current) => {
        prev[current.slug] = current
        return prev
      },
      {},
    )

    return [
      features?.[FEATURES.DOMAINS].value,
      features?.[FEATURES.FUNNELS].value,
      features?.[FEATURES.PAGES].value,
    ]
  }, [targetPlanData])

  const selectDomain = (item: ItemProps) => {
    const list = [...domainSelected]
    const index = list.indexOf(item.id)
    if (index !== -1) {
      list.splice(index, 1)
      return setDomainSelected([...list])
    }
    return setDomainSelected([...list, item.id])
  }

  const selectFunnel = (item: ItemProps) => {
    const list = [...funnelSelected]
    const index = list.indexOf(item.id)
    if (index !== -1) {
      list.splice(index, 1)
      return setFunnelSelected([...list])
    }

    return setFunnelSelected([...list, item.id])
  }

  const selectPage = (item: ItemProps) => {
    const list = [...pageSelected]
    const index = list.indexOf(item.id)
    if (index !== -1) {
      list.splice(index, 1)
      return setPageSelected([...list])
    }

    return setPageSelected([...list, item.id])
  }

  function cancelClick() {
    return history.replace('/settings')
  }

  function continueClick() {
    return history.push(`/plan_cancel/${planCode}`, {
      domains: domainSelected,
      funnels: funnelSelected,
      pages: pageSelected,
    })
  }

  if (hasError) return null

  return (
    <>
      <Root>
        <AppBar position="fixed">
          <Toolbar>
            <Brand href="/">
              <Image src={makefunnelsLogo} alt="makefunnels logo" />
            </Brand>
          </Toolbar>
        </AppBar>
        <Content>
          <>
            <Disclaimer targetPlan={planCode} />
            <Box mt={3}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography>
                    Domínios - {domainSelected.length}/{domainsLimit}
                  </Typography>
                  <MultipleSelect
                    items={domains}
                    itemsSelected={domainSelected}
                    itemsLimit={domainsLimit ? parseInt(domainsLimit) : 0}
                    callback={selectDomain}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>
                    Funnels - {funnelSelected.length}/{funnelsLimit}
                  </Typography>
                  {funnels && Boolean(domainSelected.length) && (
                    <MultipleSelect
                      items={funnels}
                      itemsSelected={funnelSelected}
                      itemsLimit={funnelsLimit ? parseInt(funnelsLimit) : 0}
                      callback={selectFunnel}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>
                    Pages - {pageSelected.length}/{pagesLimit}
                  </Typography>
                  {pages && Boolean(pages.length) && (
                    <MultipleSelect
                      items={pages}
                      itemsSelected={pageSelected}
                      itemsLimit={pagesLimit ? parseInt(pagesLimit) : 0}
                      callback={selectPage}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-start" alignItems={'center'}>
              <ButtonContained variant="contained" onClick={cancelClick}>
                Cancelar
              </ButtonContained>
              <Box ml={2}>
                <ButtonBase
                  variant="outlined"
                  onClick={continueClick}
                  disabled={!isAnyValueSelected}
                >
                  Continuar
                </ButtonBase>
              </Box>
            </Box>
          </>
        </Content>
      </Root>
    </>
  )
}

export default Resources
