import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { useSettings } from '../../context/settings-context'

const ErrorBoundary = ({ children }) => {
  const [{ error }] = useSettings()
  return (
    <>
      {error ? (
        <Box textAlign="center" mt={3}>
          <Typography variant="h6">
            Desculpe. Algo não correu bem{' '}
            <span role="img" aria-label="smile emoji">
              ☹️
            </span>
          </Typography>
        </Box>
      ) : (
        children
      )}
    </>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
