import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import TimelineIcon from '@material-ui/icons/Timeline'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import useRegisterLastPath from '~/hooks/useRegisterLastPath'

import { EditBoard } from '../Modal/EditBoard'
import { Modal, ModalOpenButton } from '../Modal/ModalContext'
import { useStyles } from './styles'

const BoardName = styled(TableCell)`
  :hover {
    cursor: pointer;
    /* text-decoration: underline; */
  }
`

function BoardItem({ item }) {
  const classes = useStyles()
  const { registerLastPath } = useRegisterLastPath()
  const [isOnOver, setIsOnOver] = useState(false)

  const redirectToCanvasDraw = () => {
    registerLastPath(12, `/funnelDraw/${item.id}`)
  }

  return (
    <Box
    //key={`${row.name}_${index}`}
    // my={2}
    >
      <TableContainer
        className={classes.tableContainer}
        //key={row.name}
        component={Paper}
        onMouseOver={() => setIsOnOver(true)}
        onMouseLeave={() => setIsOnOver(false)}
        elevation={isOnOver ? 4 : 1}
        // elevation={23}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow my={2}>
              <TableCell>
                <Box className={classes.folder}>
                  <TimelineIcon size="large" />
                </Box>
              </TableCell>

              <BoardName
                component="th"
                scope="row"
                width="70%"
                className={classes.boardName}
                onClick={redirectToCanvasDraw}
              >
                {item.name}
              </BoardName>

              <TableCell component="th" scope="row" width="30%">
                <Box display="flex" justifyContent="flex-end">
                  <Modal>
                    <ModalOpenButton>
                      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true">
                        <MoreVertIcon />
                      </IconButton>
                    </ModalOpenButton>
                    <EditBoard boardId={item.id} validator={item.name} />
                  </Modal>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

BoardItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default BoardItem
