import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
})
