import React from 'react'

import { useAppState } from '~/context'

import LoadingView from './loadingView'

function LoadingController(props) {
  const { loading } = useAppState()

  return <LoadingView loading={loading.show} withBackground={loading.background} {...props} />
}

export default LoadingController
