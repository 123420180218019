import React from 'react'
import { useHistory } from 'react-router'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import * as domainService from '~/services/domainsService'
import * as userService from '~/services/loginService'

import View from './View'

export default function DefaultDomain() {
  const dispatchApp = useAppDispatch()
  const { push } = useHistory()
  async function handleSubmit(name) {
    try {
      await domainService.createDomain({
        name,
        is_mf_domain: true,
      })

      await userService.FetchProfile(dispatchApp)

      dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Sub-dominio definido!',
          variant: 'success',
        },
      })

      return push('/')
    } catch (err) {
      const {
        data: { error },
      } = err.response

      return dispatchApp({
        type: SET_ALERT,
        payload: {
          open: true,
          message: error,
          variant: 'error',
        },
      })
    }
  }

  return <View action={{ handleSubmit }} />
}
