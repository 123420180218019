import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import ModalComponent from '~/components/Modal'

const CreateIntegration = ({
  state: { open, integration },
  actions: { handleOpen, handleCreate },
}) => {
  const [body, setBody] = React.useState({})
  const handleClose = () => {
    setBody({})
    handleOpen('create')
  }

  const buttons = [
    {
      variant: 'contained',
      name: 'Salvar',
      handleSubmit: () => {
        handleCreate(integration.service, { credentials: body }, handleClose)
      },
    },
  ]

  const inputs = integration.credentials
    ? integration.credentials.map((integration) => ({
        ...integration,
        component: 'textField',
        fullWidth: true,
        onChange: (event) => {
          const obj = body
          obj[event.target.name] = event.target.value

          setBody(obj)
        },
      }))
    : []

  const helper = {
    title: 'Precisa de ajuda?',
    content: (
      <>
        Veja nosso <b>tutorial</b> de como integrar o {integration.name} à MakeFunnels{' '}
        <a href={integration.help_link} target="_blank" rel="noopener noreferrer">
          LINK AQUI
        </a>
      </>
    ),
  }

  return (
    <ModalComponent
      actions={{ handleClose }}
      state={{ open, buttons, inputs, helper, title: 'Finalize a Integração' }}
    >
      <Typography variant="h6" gutterBottom>
        Integração do {integration.name}
      </Typography>
    </ModalComponent>
  )
}

CreateIntegration.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    integration: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleOpen: PropTypes.func,
    handleCreate: PropTypes.func,
  }),
}

export default CreateIntegration
