/* eslint-disable react/prop-types */
// import { Box } from '@material-ui/core'
import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

import { useAppState } from '~/context'

import { DefaultBox, DefaultLayout, DefaultTitle, TextBase } from '../Layout/DefaultLayout'
import { TextField } from './styles'

function AccountDetail() {
  const { user } = useAppState()
  const { email, name } = user

  return (
    <DefaultLayout>
      <DefaultTitle>Meu Perfil</DefaultTitle>
      <Grid container justifyContent="flex-start">
        <Grid item md={4}>
          <TextBase variant="subtitle1">Dados gerais</TextBase>
          <TextBase variant="body2">Informações sobre sua conta</TextBase>
        </Grid>
        <Grid item md={8}>
          <DefaultBox>
            <Box>
              <Typography variant="body2" gutterBottom>
                Nome
              </Typography>
              <TextField defaultValue={name} variant="outlined" size="small" disabled />
            </Box>
            <Box mt={3}>
              <Typography variant="body2" gutterBottom>
                Email
              </Typography>
              <Typography variant="body1">{email}</Typography>
            </Box>
          </DefaultBox>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}

export default AccountDetail
