// eslint-disable-next-line react/prop-types
import React from 'react'

import { Button, CurrentButton, DowngradeButton } from './styles'

type DowngradeNowProps = {
  downgradeRedirect: () => void
}
type BuyNowProps = {
  redirectTo: () => void
}

export const Current: React.FC = () => (
  <CurrentButton variant="text" disabled>
    Plano Atual
  </CurrentButton>
)

export const UpdateNow: React.FC<BuyNowProps> = ({ redirectTo }) => (
  <Button variant="contained" onClick={redirectTo}>
    Atualizar Agora
  </Button>
)

export const DowngradeNow: React.FC<DowngradeNowProps> = ({ downgradeRedirect }) => (
  <DowngradeButton variant="text" onClick={downgradeRedirect}>
    Rebaixar agora
  </DowngradeButton>
)
