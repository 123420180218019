import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    // position: 'fixed',
    // right: 10,
    // bottom: 15,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function DoneButton({ isDisabled }) {
  const classes = useStyles()

  return (
    <Fab
      variant="extended"
      color="secondary"
      aria-label="forward"
      className={classes.button}
      disabled={isDisabled}
    >
      <DoneIcon className={classes.extendedIcon} />
      Confirmar
    </Fab>
  )
}
