import { Box, Typography } from '@material-ui/core'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import { Funnel, Page } from '~/types'
import * as sharedData from '~/utils/sharedData'

import * as funnelService from '../../../../services/funnelService'
import DeletePage from '../DeletePage'
import DuplicatePage from '../DuplicatePage'
import TabsPanel from '../TabsPanel'
import { Card, EditModal, NewPage } from './components'
import CreatePageModal from './components/CreatePageModal'

interface PagesListProps {
  funnel: Funnel
}

function PagesList({ funnel }: PagesListProps) {
  const history = useHistory()
  const [pages, setPages] = useState(funnel.pages)
  const [pageIDSelected, setPageIDSelected] = useState(null)
  const [movePage, setMovePage] = useState(false)
  const appDispatch = useAppDispatch()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [deletePageModal, setDeletePageModal] = useState({
    open: false,
    validator: '',
    pageId: 0,
    funnelId: 0,
  })
  const [duplicatePageModal, setDuplicatePageModal] = useState({
    open: false,
    pageId: 0,
  })
  const [editPageModal, setEditPageModal] = useState({
    open: false,
    pageId: 0,
  })
  const { id: funnelId, domain } = funnel
  const numCards = pages.length - 1

  const toggleOpenEditModal = () => setOpenEditModal(!openEditModal)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (movePage) handleUpdateSteps()
    }, 1000)
    function handleUpdateSteps() {
      const newSteps = pages.map((page, index) => ({
        page_id: page.id,
        step: index + 1,
      }))
      const steps = JSON.stringify(newSteps)
      const data = {
        pages: steps,
      }
      try {
        funnelService.updateFunnel(funnelId, data)
      } catch (error) {
        appDispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: 'Houve um problema. Tenta por favor mais tarde!',
            variant: 'error',
          },
        })
      }
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, movePage])

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = pages[dragIndex]
      setPages(
        update(pages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
      setMovePage(true)
    },
    [pages],
  )

  const openTemplate = () => {
    history.push(`/templates/${pageIDSelected}`)
  }

  function openEditor(pageId: number) {
    sharedData.setPageId(typeof pageId === 'number' ? pageId : pageIDSelected)
    sharedData.setFunnelId(funnelId)
    window.location.href = `${process.env.REACT_APP_PAGE_EDITOR_URL}`
  }

  //   ----------------------------

  const renderCard = (page: Page, index: number) => {
    const classes = []
    if (index === numCards - 1) classes.push('arrow')
    if (index === numCards) classes.push('last')
    return (
      <Card
        key={page.id}
        index={index}
        id={page.id}
        page={page}
        domainUrl={domain.name}
        moveCard={moveCard}
        handleEvent={toggleOpenEditModal}
        openEditor={openEditor}
        classesNames={classes}
        setPageIDSelected={setPageIDSelected}
        setDeletePageModal={setDeletePageModal}
        setDuplicatePageModal={setDuplicatePageModal}
        setEditPageModal={setEditPageModal}
      />
    )
  }

  return (
    <>
      <Box mb={3}>
        <Typography variant="h4" gutterBottom>
          Páginas
        </Typography>
      </Box>
      {Boolean(pages.length) && <TabsPanel />}
      <NewPage pages={pages} />
      <div>{pages.map((card, i) => renderCard(card, i))}</div>
      <EditModal
        open={openEditModal}
        handleClose={toggleOpenEditModal}
        openEditor={openEditor}
        openTemplate={openTemplate}
      />
      <DeletePage
        state={{ ...deletePageModal }}
        action={{ handleClose: () => setDeletePageModal({ ...deletePageModal, open: false }) }}
      />
      <DuplicatePage
        state={{ ...duplicatePageModal }}
        action={{
          handleClose: () => setDuplicatePageModal({ pageId: -1, open: false }),
        }}
      />
      <CreatePageModal
        open={editPageModal.open}
        pageId={editPageModal.pageId}
        nextStep={pages.length + 1}
        handleClose={() => setEditPageModal({ pageId: 0, open: false })}
      />
    </>
  )
}

export default PagesList
