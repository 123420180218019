import React from 'react'

const BackgroundAnimation = () => (
  <div className="area">
    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
)

export default BackgroundAnimation
