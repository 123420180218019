import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { getFunnel } from '~/actions'
import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import * as funnelService from '~/services/funnelService'
import fetch from '~/utils/fetch'

import PagePath from './PagePath'

export default function PagePathContainer({
  funnelId,
  domainUrl,
  page: { id, path },
  setDragMode,
}) {
  const [pathEdition, setPath] = useState(path)
  const [editMode, setEditMode] = useState(false)
  const [changePath, setChangePath] = useState(false)
  const appDispatch = useAppDispatch()

  const handleChange = (event) => {
    setChangePath(true)
    return setPath(event.target.value)
  }

  const handleEdit = () => {
    setEditMode(true)
    setChangePath(true)
    setDragMode(true)
  }

  const handleSave = async () => {
    const data = {
      path: pathEdition,
    }
    fetch(appDispatch, async () => {
      try {
        await funnelService.updatePages(id, data)
        await getFunnel(appDispatch, funnelId)
      } catch (error) {
        appDispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message:
              'Outra página já possui este caminho. O caminho para a página precisa ser unico.',
            variant: 'error',
          },
        })
      }
    })
    setChangePath(false)
    setDragMode(false)
  }

  const handleCancel = () => {
    setChangePath(false)
    setPath(path)
    setEditMode(false)
    setDragMode(false)
  }

  function handleClickCopyButton() {
    const link = `${domainUrl}/${path}`
    navigator.clipboard.writeText(link)
    appDispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Link Copiado!',
        variant: 'info',
      },
    })
  }

  return (
    <PagePath
      {...{
        domainUrl,
        path: pathEdition,
        editMode,
        changePath,
        handleClickCopyButton,
        handleCancel,
        handleSave,
        handleEdit,
        handleChange,
      }}
    />
  )
}

PagePathContainer.propTypes = {
  funnelId: PropTypes.number,
  domainUrl: PropTypes.string,
  page: PropTypes.shape({
    id: PropTypes.number,
    path: PropTypes.string,
  }),
}
