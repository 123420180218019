// import { Box } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import styled from 'styled-components'

import { TextBase } from '~/pages/Layout/DefaultLayout'

export const Wrapper = styled(Card)`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.8rem;
  padding: 0.5rem 0;
  ${({ borderBottom }) =>
    borderBottom &&
    `
    border-bottom: 1px solid lightgray;
  `}
`

export const ContentHeader = styled.div`
  &&& {
    padding: 1rem 0.8rem;
    background-color: rgb(229, 233, 245);
    color: rgba(0, 0, 0, 0.6);
  }
`

export const TextBold = styled(TextBase)`
  &&& {
    font-weight: bold;
  }
`

export const Credit = styled(TextBold)`
  &&& {
    color: yellowgreen;
  }
`
