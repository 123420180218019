import PropTypes from 'prop-types'
import React from 'react'

import { Content, Image, Toggle } from './components'
import { Left, Wrapper } from './feature.style'

function Feature({ image, toggleAction, children, toggleValue, onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <Left>
        <Image content={image} alt="Feature vector image" />
        <Content>{children}</Content>
      </Left>
      {!onClick && <Toggle initValue={toggleValue} onClick={toggleAction} />}
    </Wrapper>
  )
}

Feature.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.func]) || undefined,
  toggleAction: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  toggleValue: PropTypes.bool,
}

export default Feature
