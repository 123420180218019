import React from 'react'

import { PLAN_STATE } from '~/constants'
import { DefaultBox, TextBase, timezone } from '~/pages/Layout/DefaultLayout'
import { Offer } from '~/types'
import { normalizePeriod } from '~/utils/normalizePeriod'

import { Sumary } from './styles'

type InfoProps = {
  expiresAt: string
  offer: Offer | undefined
  status: string
  trialDaysLeft: number
}

const Info: React.FC<InfoProps> = ({ expiresAt, offer, status, trialDaysLeft }) => {
  const nextPayment = timezone(expiresAt).split(' ')[0]

  const Trial = React.memo(
    (): React.ReactElement => (
      <>
        <div>
          <TextBase variant="body2">
            <Sumary>Período de teste restante:</Sumary> {trialDaysLeft}
          </TextBase>
        </div>
        <div>
          <TextBase variant="body2">
            <Sumary>Próxima cobrança:</Sumary> {nextPayment}
          </TextBase>
        </div>
      </>
    ),
  )

  const Default = React.memo(
    ({ status }: { status: string }): React.ReactElement => (
      <>
        <TextBase variant="body2">
          <Sumary>Intervalo de pagamento:</Sumary> {offer?.offer_period}{' '}
          {normalizePeriod(offer?.offer_period, offer?.offer_interval)}
        </TextBase>
        <TextBase variant="body2">
          <Sumary>
            {status !== PLAN_STATE.CANCELED ? 'Próximo pagamento:' : 'Fim do período atual'}
          </Sumary>{' '}
          {nextPayment}
        </TextBase>
      </>
    ),
  )

  return (
    <DefaultBox>
      {status === PLAN_STATE.TRIALING ? <Trial /> : <Default status={status} />}
    </DefaultBox>
  )
}

export default Info
