import PropTypes from 'prop-types'
import React from 'react'

import {
  CLEAR_STATE,
  SET_ACTIVE_PAGE,
  SET_ALERT,
  SET_CURRENT_SUBSCRIPTION,
  SET_DOMAINS,
  SET_FUNNEL,
  SET_FUNNEL_CATEGORIES,
  SET_HEADER_TITLE,
  SET_PAGE_CATEGORIES,
  SET_PAGES,
  SET_TEMPLATES,
  SET_USER,
  TOGGLE_LOADING,
  TOGGLE_MODAL,
} from './constants'

const AppStateContext = React.createContext()
const AppDispatchContext = React.createContext()

const initialState = {
  funnelCategories: [],
  pageCategories: [],
  headerTitle: '',
  user: null,
  showCreatePageModal: false,
  funnel: {
    data: null,
    isFetching: true,
  },
  pages: null,
  activePage: 1,
  loading: {
    show: false,
    background: false,
  },
  alert: {
    open: false,
    message: '',
    variant: '',
    noHide: false,
  },
  domains: {
    data: null,
    isFetching: true,
  },
  templates: {
    data: [],
    isFetching: true,
  },
  currentSubscription: {
    plan: {},
    error: null,
    status: 'idle',
  },
}

function AppReducer(state, action) {
  switch (action.type) {
    case SET_PAGE_CATEGORIES: {
      return { ...state, pageCategories: action.payload }
    }
    case SET_FUNNEL_CATEGORIES: {
      return { ...state, funnelCategories: action.payload }
    }
    case SET_HEADER_TITLE: {
      return { ...state, headerTitle: action.payload }
    }
    case SET_USER: {
      return { ...state, user: action.payload }
    }
    case TOGGLE_LOADING: {
      return { ...state, loading: { ...state.loading, ...action.payload } }
    }
    case SET_ALERT: {
      return { ...state, alert: { ...state.alert, ...action.payload } }
    }
    case SET_DOMAINS: {
      return { ...state, domains: { ...state.domains, ...action.payload } }
    }
    case SET_TEMPLATES: {
      return { ...state, templates: { ...state.templates, ...action.payload } }
    }
    case TOGGLE_MODAL: {
      return { ...state, showCreatePageModal: !state.showCreatePageModal }
    }
    case SET_FUNNEL: {
      return { ...state, funnel: { ...state.funnel, ...action.payload } }
    }
    case SET_PAGES: {
      return { ...state, pages: action.payload }
    }
    case SET_ACTIVE_PAGE: {
      return { ...state, activePage: action.payload }
    }
    case SET_CURRENT_SUBSCRIPTION: {
      return { ...state, currentSubscription: { ...state.currentSubscription, ...action.payload } }
    }
    case CLEAR_STATE: {
      return initialState
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(AppReducer, initialState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export { AppProvider, useAppDispatch, useAppState }
