import { Typography } from '@material-ui/core'
import WebIcon from '@material-ui/icons/Web'
import React from 'react'

import { openUpgradeModal, useUpgradeModal } from '~/components/UpgradeModal/context'
import { ROLES } from '~/constants'
import { useAppState } from '~/context'

import { Feature } from '../../components/Feature'
import { updateSetting, useSettings } from '../../context/settings-context'

function FooterBrandShare() {
  const [{ show_powered_by_element }] = useSettings()
  const [_, dispatch] = useUpgradeModal()
  const { user } = useAppState()

  const ToggleAction = (e) => {
    if (user?.role === ROLES.FREE) {
      openUpgradeModal(dispatch)
      return false
    }

    const on = e.target.checked
    updateSetting('show_powered_by_element', on)
    return true
  }

  return (
    <>
      <Feature image={WebIcon} toggleAction={ToggleAction} toggleValue={show_powered_by_element}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Feito com MakeFunnels
        </Typography>
        <Typography variant="body2">
          Ative ou desative o mini-banner da makefunnels fixo no canto inferior direito de suas
          páginas. Seu link de afiliado é incluso.
        </Typography>
      </Feature>
    </>
  )
}

export default FooterBrandShare
