import React from 'react'

import * as STATUS from './constants'
import { Chip } from './styles'

function getValues(status) {
  switch (status) {
    case STATUS.TRIAL.text:
      return [STATUS.TRIAL.label, STATUS.TRIAL.color]
    case STATUS.PAID.text:
      return [STATUS.PAID.label, STATUS.PAID.color]
    case STATUS.PENDING.text:
      return [STATUS.PENDING.label, STATUS.PENDING.color, true]
    case STATUS.CANCELED.text:
      return [STATUS.CANCELED.label, STATUS.CANCELED.color]
    default:
      return ['', '']
  }
}

// eslint-disable-next-line react/prop-types
function Tag({ status }) {
  const [label, color, isDark] = getValues(status)

  return <Chip label={label} $customColor={color} $isDark={isDark} />
}

export default Tag
